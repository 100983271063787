import React from "react";
import { Grid } from "@material-ui/core";
import Search from "@material-ui/icons/Search";
import Edit from "@material-ui/icons/Edit";
import CustomTable from "../CustomTable";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import TabIcon from "@material-ui/icons/Tab";

const SuperTable = ({ part, content, isLot, small }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);

  const history = useHistory();
  let actions;
  let columns = [];
  part.fields.forEach((column) => {
    columns.push({
      title: column.name,
      field: column.id, // field is the "key" in the data
      width: "8%",
      filtering: false,
    });
  });

  let data;

  if (isLot) {
    data = content.lots.map((lot) => {
      let output = { ...lot };

      output.type =
        lot.type === "a" ? `Appartement F${lot.nbPieces}` : "Maison";
      output.surfaceUtile = lot.surfaceUtile
        ? `${lot.surfaceUtile} m²`
        : "Non défini";
      output.loyerCc = lot.loyerCc ? `${lot.loyerCc}.00€` : "Non défini";
      output.topLoue = lot.topLoue ? "Oui" : "Non";
      output.topOption = lot.topOption ? "Oui" : "Non";

      actions = [
        {
          icon: Edit,
          tooltip: "Modifier le lot",
          onClick: (event, rowData) =>
            history.push(
              `/details/lots/${rowData.codeImmeuble}-${rowData.codeLot}/true`
            ),
        },
        {
          icon: Search,
          tooltip: "Voir les détails du lot",
          onClick: (event, rowData) =>
            history.push(
              `/details/lots/${rowData.codeImmeuble}-${rowData.codeLot}/false`
            ),
        },
        {
          icon: TabIcon,
          tooltip: "Ouvrir le lot dans un nouvel onglet",
          onClick: (_event, rowData) =>
            window.open(
              `/details/lots/${rowData.codeImmeuble}-${rowData.codeLot}/true`,
              "_blank"
            ),
        },
      ];

      return output;
    });
  } else {
    data = content.residences.map((residence) => {
      let output = { ...residence };

      output.libelle = `${residence.codeImmeuble} - ${residence.libelle}`;
      actions = [
        {
          icon: Search,
          tooltip: "Voir les détails de la résidence",
          onClick: (_event, rowData) =>
            history.push(`/details/residences/${rowData.codeImmeuble}/false`),
        },
      ];

      return output;
    });
  }

  return (
    <Grid key={part.title} item xs={small ? 6 : 12} style={{ padding: 10 }}>
      <CustomTable
        title={part.title}
        columns={columns}
        data={data}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        page={page}
        setPage={setPage}
        actions={actions}
        filter={false}
        noSearch={true}
        search={false}
        showSearch={true}
        autoFocus={searchTerm.length ? true : false}
        size={data.length < 11 ? 10 : 20}
      />
    </Grid>
  );
};

export default React.memo(SuperTable);
