import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme.js";
import { BrowserRouter as Router } from "react-router-dom";
import { AppConfigProvider } from "./context";

ReactDOM.render(
  // <React.StrictMode>
  /* </React.StrictMode>, */
  <ThemeProvider theme={theme}>
    <SnackbarProvider>
      <AppConfigProvider>
        <Router>
          <App />
        </Router>
      </AppConfigProvider>
    </SnackbarProvider>
  </ThemeProvider>,
  document.getElementById("root")
);
// {
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
