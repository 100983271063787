import React, { useEffect, useState } from "react";
import { InputLabel, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { elementDetails } from "./utils";
import CustomSelect from "./CustomSelect";

import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";

import { FormControl, MenuItem, Select, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import { AppConfigContext } from "../../context";

const AddElementDialog = ({ open, setOpen, category }) => {
  const appConfig = React.useContext(AppConfigContext);
  const { authState } = useOktaAuth();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [content, setContent] = useState({});
  const [selectContent, setSelectContent] = useState({});
  const [error, setError] = useState({});

  const translateTypeBien = (type) => {
    let translatedValue;
    switch (type) {
      case "Maison":
        translatedValue = "MAI";
        break;
      case "Appartement":
        translatedValue = "APP";
        break;
      case "Parking":
        translatedValue = "PKG";
        break;
      case "Duplex":
        translatedValue = "DPX";
        break;
      case "Divers":
        translatedValue = "DIV";
        break;
      case "Local commercial":
        translatedValue = "COM";
        break;

      default:
        translatedValue = null;
        break;
    }
    return translatedValue;
  };

  const handleChange = (e, id, variant) => {
    let nextState = { ...content };
    nextState[id] = e.target.value;
    setContent(nextState);
  };

  const handleAutoCompleteChange = (value, id) => {
    let nextState = { ...content };
    nextState[id] = value;
    setContent(nextState);
  };

  useEffect(() => {
    const doFetch = async (url, id) => {
      await fetch(`${url}`, {
        method: "GET",
        headers: {
          Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("Something went wrong :", res.json());
          }
        })
        .then((res) => {
          let nextSelectContent = { ...selectContent };
          nextSelectContent[id] = res;
          setSelectContent(nextSelectContent);
          return res;
        })
        .catch((err) => console.log("error fetching", err));
    };

    elementDetails[category].fields.forEach((el) => {
      if (el.uri && el.type === "select") {
        doFetch(appConfig.BACKEND_API_URL + el.uri, el.id);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let formattedData = {};
    elementDetails[category].fields.forEach((el) => {
      formattedData[el.id] = "";
    });
    setContent(formattedData);

    let errorShape = {};
    elementDetails[category].fields.forEach((el) => {
      errorShape[el.id] = false;
    });
    setError(errorShape);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleValidate = () => {
    // Preparing data specifically for lots
    if (content.type) content.type = translateTypeBien(content.type);
    if (category === "lots") {
      content.groupeLot = 0;
      content.travaux = false;
    }

    fetch(`${appConfig.BACKEND_API_URL}/v1/secured/api/${category}`, {
      method: "POST",
      headers: {
        Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(content),
    }).then(async (res) => {
      if (res.ok) {
        enqueueSnackbar(`${category} : Créé avec succès`, {
          variant: "success",
        });
        setOpen(false);
        let path = null;
        if (category === "residences") {
          path = content.codeImmeuble;
        } else if (category === "lots") {
          path = `${content.codeImmeuble}-${content.codeLot}`;
        }
        if (path) history.push(`/details/${category}/${path}`);
        else if (category === "negociateurs") window.location.reload();
      } else {
        let err = await res.json();
        if (err) {
          let fieldErrors = { ...error };
          let textErrors = "";
          if (err.msg) {
            enqueueSnackbar(`Erreur serveur : ${err.msg}`, {
              variant: "error",
            });
          } else {
            for (const [key] of Object.entries(err)) {
              fieldErrors[key] = true;
              textErrors += ` - ${key}`;
            }
            setError(fieldErrors);

            enqueueSnackbar(
              `Tous les champs doivent être rempli: ${textErrors}`,
              {
                variant: "warning",
              }
            );
          }
        } else {
          enqueueSnackbar("Erreur inconnue", {
            variant: "warning",
          });
        }
      }
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" disableTypography>
        <Typography variant="h5">{elementDetails[category].title}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText component={"div"} id="alert-dialog-description">
          {elementDetails[category].fields.map((field, index) => {
            return (
              <div key={field.id}>
                {field.type === undefined && (
                  <>
                    <Typography variant="h6">{field.name}*</Typography>
                    <TextField
                      fullWidth
                      type="text"
                      style={{ paddingBottom: 15 }}
                      error={error[field.id]}
                      helperText={error[field.id] ? "Champs requis" : ""}
                      onChange={(e) => handleChange(e, field.id)}
                      value={content[field.id]}
                      variant="outlined"
                    />
                  </>
                )}
                {field.type === "autocomplete" && (
                  <CustomSelect
                    url={`${appConfig.BACKEND_API_URL}${field.uri}`}
                    id={field.id}
                    handleAutoCompleteChange={handleAutoCompleteChange}
                    label={field.name}
                    error={error[field.id]}
                    loadingText="Chargement en cours..."
                  />
                )}
                {field.type === "select" && (
                  <FormControl fullWidth>
                    <InputLabel id={`select-${field.id}`}>
                      {field.name}
                    </InputLabel>
                    <Select
                      labelId={`select-${field.id}`}
                      value={content[field.id]}
                      onChange={(e) => handleChange(e, field.id)}
                    >
                      <MenuItem value="">Aucun(e)</MenuItem>
                      {field.localSelectContent
                        ? field.localSelectContent.map((el) => {
                            return (
                              <MenuItem value={el} key={el}>
                                {el}
                              </MenuItem>
                            );
                          })
                        : selectContent?.[field.id]?.map((el) => {
                            return (
                              <MenuItem value={el.id} key={el.id}>
                                {el.libelle || el.titre + " " + el.nom}
                              </MenuItem>
                            );
                          })}
                    </Select>
                  </FormControl>
                )}
              </div>
            );
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleValidate} color="primary">
          Créer
        </Button>
        <Button onClick={() => setOpen(false)} color="primary" autoFocus>
          Annuler
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddElementDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
};

export default AddElementDialog;
