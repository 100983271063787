import React, { useContext, useEffect, useState } from "react";
import { Chip, Grid, Typography } from "@material-ui/core";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import CustomTable from "../../Components/CustomTable";
import { useOktaAuth } from "@okta/okta-react";
import { AppConfigContext } from "../../context";

const columns = [
  {
    title: "Email",
    field: "email",
    width: "8%",
    filtering: false,
  },
  {
    title: "Date dernier envoi",
    field: "dateDernierEnvoi",
    filtering: false,
    type: "datetime",
  },
  {
    title: "Actif",
    field: "actif",
    filtering: false,
  },
  {
    title: "Bien",
    field: "bien",
    width: "300px",
    filtering: false,
    sorting: false,
  },
  {
    title: "Lieu",
    field: "lieu",
    width: "300px",
    filtering: false,
    sorting: false,
  },
  {
    title: "Pièces",
    field: "pieces",
    width: "300px",
    filtering: false,
    sorting: false,
  },
  {
    title: "Budget",
    field: "budget",
    width: "300px",
    filtering: false,
    sorting: false,
  },
];

const actions = [];

const getJsonPieces = (search) => {
  let pieces = "";
  if (search.studio) pieces += "studio";
  if (search.f2) pieces += pieces.length ? "|f2" : "f2";
  if (search.f3) pieces += pieces.length ? "|f3" : "f3";
  if (search.f4) pieces += pieces.length ? "|f4" : "f4";
  if (search.f5) pieces += pieces.length ? "|f5" : "f5";
  if (!pieces.length) pieces = "tout";

  return pieces;
};

const getJsonBien = (search) => {
  let bien = "";
  if (search.appartement) bien += "appartement";
  if (search.maison) bien += bien.length ? "|maison" : "maison";
  if (search.parking) bien += bien.length ? "|parking" : "parking";
  if (!bien.length) bien = "tout";

  return bien;
};

const AlerteEmail = () => {
  const appConfig = useContext(AppConfigContext);
  const { authState } = useOktaAuth();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState(null);
  const [isDesc, setIsDesc] = useState(false);

  const breadcrumbPath = {
    path: [{ name: "Accueil", href: "/" }],
    currentElement: "Alerte Email",
  };

  const formatData = (result) => {
    if (!result.content) return [];

    const content = structureData(result.content);

    return content.map((alerte) => {
      let output = { ...alerte };
      output.recherche = alerte.recherche.replace(/,/g, ", ");
      output.email = <a href={`mailto: ${output.email}`}>{output.email}</a>;
      output.actif = alerte.actif ? (
        <Chip label="Oui" variant="outlined" color="primary" />
      ) : (
        <Chip
          label="Non"
          variant="outlined"
          style={{ color: "red", borderColor: "red" }}
        />
      );
      output.dateUpdate = new Date(alerte.dateUpdate).toLocaleString("fr-FR");
      return output;
    });
  };

  const structureData = (data) => {
    let lineOutput = [...data];
    data.forEach((line, i) => {
      const search = line.recherche;
      const firstChar = search.charAt(0);
      if (firstChar === "{") {
        const result = JSON.parse(search);
        const jsonReturn = {
          lieu: result.lieu || "Partout",
          budgetMin: result.budgetMin,
          budgetMax: result.budgetMax,
          budget: `${result.budgetMin}-${result.budgetMax}`,
          maison: result.maison,
          parking: result.parking,
          studio: result.studio,
          pieces: getJsonPieces(result),
          bien: getJsonBien(result),
        };

        lineOutput[i] = { ...lineOutput[i], ...jsonReturn };
      } else if (firstChar === "/") {
        let input = search.split("/recherche/")[1].split("/");
        let result = {};
        input.forEach((el, index, arr) => {
          if (el.charAt(0) === "(" && el.slice(-1) === ")") {
            result[el.slice(1, -1)] = arr[index + 1];
          }
        });

        lineOutput[i] = { ...lineOutput[i], ...result };
      }
      return lineOutput;
    });

    return lineOutput;
  };

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        `${appConfig.BACKEND_API_URL}/v1/secured/api/alerteEmail`,
        {
          headers: {
            Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => res);
      setTotalElements(response.totalElements);
      let formattedData = formatData(response);
      setData(formattedData);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.length) {
      fetchWithParams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, searchTerm, sortBy, isDesc]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchWithParams = async () => {
    // debounce(async () => {
    const response = await fetch(
      `${
        appConfig.BACKEND_API_URL
      }/v1/secured/api/alerteEmail?page=${page}&size=${size}${
        searchTerm.length ? "&t=" + searchTerm : ""
      }${isDesc === true ? "&isDesc=true" : "&isDesc=false"}    ${
        sortBy ? "&sortBy=" + sortBy : ""
      }`,
      {
        headers: {
          Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => res);
    setTotalElements(response.totalElements);
    let formattedData = formatData(response);
    setData(formattedData ? formattedData : []);
  };
  // }, 500),
  // [searchTerm]

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1">Alerte Email</Typography>
        <CustomBreadcrumb breadcrumbPath={breadcrumbPath} />
        <CustomTable
          title="Liste des contacts"
          columns={columns}
          data={data}
          actions={actions}
          size={size}
          setSize={setSize}
          page={page}
          setPage={setPage}
          totalElements={totalElements}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setSortBy={setSortBy}
          setIsDesc={setIsDesc}
          filter={false}
          autoFocus={true}
        />
      </Grid>
    </Grid>
  );
};

export default AlerteEmail;
