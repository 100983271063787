import MaterialTable from "material-table";
import React, { useContext } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { forwardRef } from "react";
import { useTheme } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import { AppConfigContext } from "../../context";

const CallCenterTable = ({ listAnnonces, handleNumberSearch }) => {
  const theme = useTheme();
  const appConfig = useContext(AppConfigContext);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => (
      <FirstPage
        style={{ color: theme.palette.text.default }}
        {...props}
        ref={ref}
      />
    )),
    LastPage: forwardRef((props, ref) => (
      <LastPage
        style={{ color: theme.palette.text.default }}
        {...props}
        ref={ref}
      />
    )),
    NextPage: forwardRef((props, ref) => (
      <ChevronRight
        style={{ color: theme.palette.text.default }}
        {...props}
        ref={ref}
      />
    )),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft
        style={{ color: theme.palette.text.default }}
        {...props}
        ref={ref}
      />
    )),
    ResetSearch: forwardRef((props, ref) => (
      <Clear
        style={{ color: theme.palette.text.default }}
        {...props}
        ref={ref}
      />
    )),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward
        style={{ color: theme.palette.text.default }}
        {...props}
        ref={ref}
      />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  listAnnonces = listAnnonces.map((annonce) => {
    let output = { ...annonce };

    output.customAdress = `${annonce.ville} - ${annonce.adresse1}`;
    output.chauffageCollectif = annonce.chauffageCollectif ? (
      <Chip label="Oui" variant="outlined" color="primary" />
    ) : (
      <Chip
        label="Non"
        variant="outlined"
        style={{ color: "red", borderColor: "red" }}
      />
    );

    return output;
  });

  return (
    <MaterialTable
      style={{ marginTop: 10 }}
      title="Basic Grouping Preview"
      icons={tableIcons}
      onRowClick={(_e, rowData) => handleNumberSearch(_e, rowData.codeAnnonce)}
      localization={{
        grouping: {
          groupedBy: "Annonces groupées par :",
        },
      }}
      columns={[
        {
          title: "Adresse",
          field: "customAdress",
          defaultGroupOrder: 1,
          defaultGroupSort: "asc",
        },
        {
          title: "Avatar",
          field: "avatar",
          render: (rowData) => (
            <img
              alt={`Miniature ${rowData.ville}`}
              style={{ height: 50, width: 50, borderRadius: "15%" }}
              src={`${appConfig.IMG_URL}${rowData.photoCouverture}?s=xs`}
            />
          ),
        },
        { title: "Immeuble", field: "codeAnnonce", grouping: false },
        { title: "Pièces", field: "nbPieces", grouping: false },
        { title: "Chambres", field: "nbChambres", grouping: false },
        { title: "Étage", field: "etage", grouping: false },
        {
          title: "Chauffage collectif",
          field: "chauffageCollectif",
          grouping: false,
        },
        { title: "Surface", field: "surface", grouping: false },
        { title: "Loyer CC", field: "loyerCc", grouping: false },
        { title: "Station", field: "stationMetro", grouping: false },
        { title: "Revenu", field: "revenu", grouping: false },
      ]}
      data={listAnnonces}
      components={{
        // Remove toolbar
        Toolbar: (props) => (
          <div
            style={{
              display: "none",
            }}
          ></div>
        ),
      }}
      options={{
        grouping: true,
        search: false,
        defaultExpanded: true,
        pageSize: 10,
      }}
    />
  );
};

export default CallCenterTable;
