import React, { useContext, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  makeStyles
} from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Link from "@material-ui/core/Link";
//import { makeStyles } from "@material-ui/core/styles";

import { detailsStructure, interpretContent } from "./utils";
import { Skeleton } from "@mui/material";
import AccessTable from "../AccessTable";

import ModifyField from "../ModifyField";
import SuperTable from "./SuperTable";
import { useSnackbar } from "notistack";
import ImageViewer from "../ImageViewer";
import { useOktaAuth } from "@okta/okta-react";
import { AppConfigContext } from "../../context";
import StyledTable from "./StyledTable";
// import { photos } from "./photos";

const DetailsContent = ({
  category,
  content,
  setContent,
  modify,
  triggerSave,
  setTriggerSave,
  getDetails,
  loading,
}) => {
  const appConfig = useContext(AppConfigContext);
  const { authState } = useOktaAuth();
  const { enqueueSnackbar } = useSnackbar();
  const structure = React.useMemo(() => detailsStructure[category], [category]);

  const useStyles = makeStyles({
    cardcontent: {
      paddingLeft: 0,
      marginLeft : 0,
      "&:last-child": {
        paddingBottom: 0
      }
    }
  });
  const classes = useStyles();

  const handleChange = (e, id, variant) => {
    let nextState = { ...content };
    switch (variant) {
      case "checkbox":
        nextState[id] = e.target.checked;
        break;
      case "date":
      case "html":
      case "autocomplete":
        nextState[id] = e;
        break;

      default:
        nextState[id] = e.target.value;
        break;
    }

    setContent(nextState);
  };

  const handleAccessChange = (e, id, escalierIndex, variant) => {
    let nextState = { ...content };
    switch (variant) {
      case "checkbox":
        nextState.escaliers[escalierIndex][id] = e.target.checked;
        break;
      case "date":
        nextState.escaliers[escalierIndex][id] = e;
        break;

      default:
        nextState.escaliers[escalierIndex][id] = e.target.value;
        break;
    }

    setContent(nextState);
  };

  useEffect(() => {
    if (triggerSave) {
      content.idAgence = content.idAgence ? content.idAgence : -1;
      // DB limit this field to 35 chars...
      content.nomMetro = content.nomMetro?.substring(0, 35) || "";
      setTriggerSave(false);

      fetch(`${appConfig.BACKEND_API_URL}/v1/secured/api/${category}`, {
        method: "POST",
        headers: {
          Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(content),
      })
        .then(async (res) => {
          if (res.ok) {
            enqueueSnackbar("Mise à jour effectuée avec succès", {
              variant: "success",
            });
            getDetails();
          } else {
            let nextRes = await res.json();
            throw nextRes;
          }
        })
        .catch((err) => {
          let errArray = [];
          for (const prop in err) {
            if (prop !== "timestamp") errArray.push(err[prop]);
          }
          enqueueSnackbar(
            `Les données n'ont pas pu être sauvegardées : ${errArray.map(
              (el) => el
            )}`,
            {
              variant: "error",
            }
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSave]);

  return (
    <Grid container>
      {structure.content.map((part) => {
        if (part.type === "superTable" && content && content.lots) {
          return (
            <Grid md={12}>
              <Card>
                <CardContent>
                  <CardHeader title={part.title} />
                  <CardContent>
                    <SuperTable
                      part={part}
                      isLot={true}
                      key={part.title}
                      content={content}
                    />
                  </CardContent>
                </CardContent>
              </Card>
            </Grid>
          );
        } else if (part.type === "superTable" && content.residences?.length) {
          return (
            <Grid md={12}>
              <Card>
                <CardContent>
                  <CardHeader title={part.title} />
                  <CardContent>
                    <SuperTable
                      part={part}
                      small={true}
                      key={part.title}
                      content={content}
                    />
                  </CardContent>
                </CardContent>
              </Card>
            </Grid>
          );
        }

        return (
          <Grid
            key={part.title}
            item
            xs={12}
            md={part.type === "table" ? 12 : 6}
          >
            <Card>
              <CardContent>
                <CardHeader title={part.title} />
                <CardContent>
                  {part.type === "table" ? (
                    <AccessTable
                      structure={part.fields}
                      category={category}
                      content={
                        category === "lots" && part.accessPoint === "escaliers"
                          ? content.residence || null
                          : content
                      }
                      accessPoint={part.accessPoint}
                      modify={modify}
                      handleAccessChange={handleAccessChange}
                    />
                  ) : (
                    <>
                      {part.type === "photos" && (
                        <ImageViewer
                          // altPhotos={photos}
                          conf={appConfig}
                          photos={content.photos || []}
                          modify={modify}
                          codeImmeuble={content.codeImmeuble}
                          codeLot={content.codeLot}
                          setTriggerSave={setTriggerSave}
                          setContent={setContent}
                          content={content}
                        />
                      )}
                      <Table
                        // sx={{ minWidth: 650 }}
                        aria-label={`Tableau de ${part.title}`}
                      >
                        <TableBody>
                          {part.fields.map((field) => {
                            // Do not show the lines that are conditioned by other field that are false
                            if (field.condition && !content[field.condition]) {
                              return <TableRow key={field.name}></TableRow>;
                            }
                            return (
                              <TableRow
                                key={field.name}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  style={{ width: "35%" }}
                                  component="th"
                                  scope="row"
                                >
                                  <Typography variant="subtitle1">
                                    {field.name}
                                  </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {content && !loading ? (
                                    modify && !field.disabled ? (
                                      <ModifyField
                                        type={field.type}
                                        value={content[field.id]}
                                        id={field.id}
                                        url={field.uri}
                                        localSelectContent={
                                          field.localSelectContent
                                        }
                                        handleChange={handleChange}
                                      />
                                    ) : (
                                      <>
                                        {field.html ? (
                                          <Typography
                                            variant="subtitle2"
                                            dangerouslySetInnerHTML={{
                                              __html: interpretContent(
                                                content?.[field.id],
                                                field.name,
                                                category,
                                                false,
                                                field.type,
                                                field.suffixe
                                              ),
                                            }}
                                          />
                                        ) : (
                                          <Typography variant="subtitle2">
                                            {/* isLink allows link to other screens */}
                                            {field.isLink ? (
                                              <Link
                                                href={`${field.isLink}${
                                                  content[field.linkEndpoint]
                                                }`}
                                              >
                                                {content &&
                                                part.type === "residence"
                                                  ? interpretContent(
                                                      content.residence?.[
                                                        field.id
                                                      ],
                                                      field.name,
                                                      category,
                                                      false,
                                                      field.type,
                                                      field.suffixe
                                                    )
                                                  : interpretContent(
                                                      field.sub
                                                        ? content[field.sub]?.[
                                                            field.id
                                                          ]
                                                        : content?.[
                                                            field.visualId
                                                          ] ||
                                                            content?.[field.id],
                                                      field.name,
                                                      category,
                                                      false,
                                                      field.type,
                                                      field.suffixe
                                                    )}
                                              </Link>
                                            ) : (
                                              <>
                                                {content &&
                                                part.type === "residence"
                                                  ? interpretContent(
                                                      content.residence?.[
                                                        field.id
                                                      ],
                                                      field.name,
                                                      category,
                                                      false,
                                                      field.type,
                                                      field.suffixe
                                                    )
                                                  : interpretContent(
                                                      field.sub
                                                        ? content[field.sub]?.[
                                                            field.id
                                                          ]
                                                        : content?.[
                                                            field.visualId
                                                          ] ||
                                                            content?.[field.id],
                                                      field.name,
                                                      category,
                                                      false,
                                                      field.type,
                                                      field.suffixe,
                                                      field.id
                                                    )}
                                              </>
                                            )}
                                          </Typography>
                                        )}
                                      </>
                                    )
                                  ) : (
                                    <Skeleton
                                      sx={{ bgcolor: "grey.500" }}
                                      width={200}
                                      variant="text"
                                    />
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )
                      }
                        </TableBody>
                      </Table>
                      {part.subSection && (
                          <Card style={{ border: "none", boxShadow: "none", paddingLeft: 0, marginLeft: 0}}>
                            <CardContent className={classes.cardcontent}>
                              <CardHeader title={part.subSection.title}/>
                              <CardContent className={classes.cardcontent}>
                                <StyledTable
                                  fields={part.subSection.fields}
                                  columns={part.subSection.columns}
                                  content={content}
                                  category={category}
                                  modify={modify}
                                  handleChange={handleChange}
                                />
                              </CardContent>
                            </CardContent>
                          </Card>
                        )}
                    </>
                  )}
                </CardContent>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default DetailsContent;
