const lotStructure = {
  pages: [
    [
      {
        title: "Description",
        content: [
          {
            name: "Résidence",
            sub: "residence",
            id: "libelle",
          },
          { id: "codeLot", name: "N° Lot principal" },
          { id: "porte", name: "N° de porte" },
          { id: "etage", name: "Étage" },
          { id: "surfaceUtile", name: "Surface utile", suffixe: "m²" },
          { id: "surfaceCarrez", name: "Surface Carrez", suffixe: "m²" },
          { id: "nbPieces", name: "Nb de pièces" },
          { id: "nbChambres", name: "Nb de chambres" },
          { id: "nbSdb", name: "Nb de salle de bain" },
          { id: "nbSdeau", name: "Nb de salle d'eau" },
          { id: "nbWc", name: "Nb de WC" },
          { id: "dpe", name: "DPE" },
          { id: "ges", name: "GES" },
          { id: "commentaireInterneLot", name: "Commentaire (interne)" },
        ],
      },
      {
        title: "Caractéristiques",
        content: [
          { name: "Parking", id: "nbParkings", type: "boolean" },
          { name: "Cave", id: "nbCaves", type: "boolean" },
          { name: "Balcon", id: "balcon", type: "boolean" },
          { name: "Terasse", id: "terrasse", type: "boolean" },
          { name: "Jardin", id: "jardin", type: "boolean" },
          { name: "Cheminée", id: "cheminee", type: "boolean" },
          { name: "Duplex", id: "duplex", type: "boolean" },
        ],
      },
      {
        title: "break",
        id: "break1",
      },
      {
        title: "Ancien locataire",
        content: [
          { name: "Prénom et nom", id: "nomAncienLocataire" },
          {
            name: "Loyer HC",
            id: "ancienLoyerHc",

            suffixe: "€",
          },

          {
            name: "Date d'entrée",
            id: "dateDebutVacance",
            type: "date",
          },
          {
            name: "Date réception congé",
            id: "dateReceptionConge",
            type: "date",
          },
          {
            name: "Date de fin de facturation",
            id: "dateFinFacturation",
            type: "date",
          },
          { name: "Code ALTAIX", id: "sourceAltaix" },
        ],
      },
      {
        title: "Commercialisation",
        content: [
          {
            name: "Loyer CC",
            id: "loyerCc",
            suffixe: "€",
          },
          { name: "Loyer HC", id: "loyerHc", suffixe: "€" },
          { name: "Charges", id: "charges", suffixe: "€" },
          {
            name: "Dépôt de garantie",
            id: "depotGarantie",
            suffixe: "€",
          },
          {
            name: "Total honoraire locataire",
            id: "honoraireTotalLocataire",
            suffixe: "€",
          },
        ],
      },
      {
        title: "break",
        id: "break2",
      },

      {
        title: "Travaux",
        content: [
          {
            name: "Date de disponibilité",
            id: "dateDispo",
            type: "date",
          },
          // Special treatment here
          { name: "Etat de l’appartement", id: "etat" },
        ],
      },
      {
        title: "Disponibilité",
        content: [
          { name: "Loué", id: "topLoue" },
          { name: "Option", id: "topOption" },
          { name: "Agence", id: "topAgence" },
        ],
      },
    ],
    // 2nd page
    [
      {
        title: "Information sur la résidence",
        type: "residence",
        content: [
          { name: "Libellé immeuble", id: "libelle", sub: "residence" },
          { name: "Propriétaire", id: "nomProprietaire", sub: "residence" },
          { name: "Adresse 1", id: "adresse1", sub: "residence" },
          { name: "Code postal", id: "codePostal", sub: "residence" },
          { name: "Ville", id: "ville", sub: "residence" },
        ],
      },
      {
        title: "",
        content: [
          {
            name: "Attaché commercial",
            id: "attacheCommercial",
          },
          { name: "Négociateur", id: "negociateur" },
          {
            name: "Gardien",
            id: "idGardien",
            visualId: "gardien",
          },
        ],
      },
      {
        title: "break",
        id: "break3",
      },
      {
        title: "Détail de la résidence",
        content: [
          {
            name: "École à proximité",
            id: "ecole",
            type: "boolean",
            sub: "residence",
          },
          {
            name: "Commerce à proximité",
            id: "commerce",
            type: "boolean",
            sub: "residence",
          },
          {
            name: "Métro à proximité",
            id: "metro",
            type: "boolean",
            sub: "residence",
          },
          {
            name: "Nom de station",
            id: "nomMetro",
            type: "autocomplete",
            sub: "residence",
          },
          {
            name: "Bus à proximité",
            id: "bus",
            type: "boolean",
            sub: "residence",
          },
          {
            name: "Nom de l'arrêt de bus",
            id: "nomBus",
            sub: "residence",
          },
        ],
      },
      {
        title: "",
        content: [
          {
            name: "Piscine à proximité",
            id: "piscine",
            type: "boolean",
            sub: "residence",
          },
          {
            name: "Espace vert à proximité",
            id: "espaceVert",
            type: "boolean",
            sub: "residence",
          },
          {
            name: "Chauffage collectif",
            id: "chauffageCollectif",
            type: "boolean",
            sub: "residence",
          },
          {
            name: "Eau chaude collective",
            id: "eauChaudeCollective",
            type: "boolean",
            sub: "residence",
          },
        ],
      },
      {
        title: "break",
        id: "break4",
      },
      {
        title: "Accès",
        type: "table",
        accessPoint: "escaliers",
        full: true,
        content: [
          { name: "Bâtiment", id: "codeBatiment", disabled: true },
          { name: "Escalier", id: "codeEscalier", disabled: true },
          { name: "Ascenceur", id: "nbAscenseurs", type: "number" },
          { name: "Vide ordure", id: "poubelle", type: "boolean" },
          { name: "Accès handicapé", id: "accesHandicape", type: "boolean" },
          { name: "Vidéo-phone", id: "videophone", type: "boolean" },
          { name: "Interphone", id: "interphone", type: "boolean" },
          { name: "Digicode", id: "digicode", type: "boolean" },
        ],
      },
    ],
  ],
};

export default lotStructure;
