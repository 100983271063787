import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  buttonSearch: {
    // width: 100,
    // margin: "auto",
    marginTop: 15,
    marginBottom: 20,
  },
  leftPanel: {
    padding: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "top",
    alignItems: "center",
    borderRight: "1px solid black",
  },
  rightPanel: {
    paddingLeft: 10,
    display: "flex",
    flexDirection: "column",
  },
  rightB: {
    marginTop: 20,
  },
  backButton: {
    position: "absolute",
    right: "20px",
    top: "80px",
  },
}));

export default useStyles;
