import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // root: {
  //   "& > * + *": {
  //     marginTop: theme.spacing(2),
  //   },
  //   marginTop: theme.spacing(2),
  // },
  // breadcrumbs: {
  //   color: theme.palette.white.main,
  //   fontWeight: "500",
  //   fontSize: "0.875rem",
  //   lineHeight: "1.57",
  // },
  // separator: {
  //   color: theme.palette.gray.main,
  // },

  dashboard: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: theme.spacing(3),
  },
  title: {
    // color: "white",
    color: theme.palette.text.default,
    fontSize: "0.85rem",
  },
  text: {
    color: theme.palette.green.main,
    margin: "20px 0",
  },
  alertContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export default useStyles;
