import MailIcon from "@material-ui/icons/Mail";
import HomeIcon from "@material-ui/icons/Home";
import LanguageIcon from "@material-ui/icons/Language";
import DomainIcon from "@material-ui/icons/Domain";
import StorageIcon from "@material-ui/icons/Storage";
import PersonIcon from "@material-ui/icons/Person";
import LayersIcon from "@material-ui/icons/Layers";
import SettingsIcon from "@material-ui/icons/Settings";

const menus = [
  {
    name: "Accueil",
    href: "/",
    icon: <HomeIcon />,
    expand: false,
  },
  {
    name: "BackOffice",
    icon: <LanguageIcon />,
    expand: true,
    ref: "backoffice",
    childs: [
      {
        name: "Résidences",
        href: "/residences",
        icon: <DomainIcon />,
        expand: false,
      },
      {
        name: "Lots",
        icon: <StorageIcon />,
        expand: true,
        ref: "lots",
        childs: [
          {
            name: "Lots dispos",
            href: "/lots/dispos",
            icon: false,
            expand: false,
          },
          {
            name: "Lots loués",
            href: "/lots/loues",
            icon: false,
            expand: false,
          },
        ],
      },
      {
        name: "Agences",
        href: "/agences",
        icon: <PersonIcon />,
        expand: false,
      },
      {
        name: "Négociateurs",
        href: "/negociateurs",
        icon: <PersonIcon />,
        expand: false,
      },
      {
        name: "Attachés commerciaux",
        href: "/attachesCommerciaux",
        icon: <PersonIcon />,
        expand: false,
      },
      {
        name: "AlerteEmail",
        href: "/alerteEmail",
        icon: <MailIcon />,
        expand: false,
      },
      {
        name: "Contacts",
        href: "/contacts",
        icon: <MailIcon />,
        expand: false,
      },
    ],
  },
  {
    name: "Altaix",
    icon: <LayersIcon />,
    expand: true,
    ref: "altaix",
    childs: [
      {
        name: "Gardiens",
        href: "/gardiens",
        icon: <PersonIcon />,
        expand: false,
      },
      {
        name: "Propositions",
        href: "/propositions",
        icon: <StorageIcon />,
        expand: false,
      },
    ],
  },
  {
    name: "Administration",
    icon: <SettingsIcon />,
    expand: true,
    ref: "admin",
    childs: [
      {
        name: "Utilisateurs",
        href: "/users",
        icon: <PersonIcon />,
        expand: false,
      },
    ],
  },
];

export { menus };
