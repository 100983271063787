export const metro = [
  "",
  "Malakoff - Rue Étienne Dolet",
  "Le Bourget",
  "La Défense - Grande Arche",
  "Parc de Saint-Cloud",
  "Les Sablons",
  "Argentine",
  "George V",
  "Franklin D. Roosevelt",
  "Concorde",
  "Tuileries",
  "Louvre — Rivoli",
  "Châtelet",
  "Saint-Paul",
  "Reuilly — Diderot",
  "Porte de Vincennes",
  "Musée de Sèvres",
  "Marcel Sembat",
  "La Motte-Picquet - Grenelle",
  "Cambronne",
  "Dupleix",
  "Cité Universitaire",
  "Gennevilliers",
  "Arcueil - Cachan",
  "Sevran - Beaudottes",
  "Mairie d'Issy",
  "Corentin Celton",
  "Porte de Versailles",
  "Convention",
  "Invalides",
  "Champ de Mars - Tour Eiffel",
  "Bry-sur-Marne",
  "Noisy-Champs",
  "Les Noues",
  "Meudon-sur-Seine",
  "Porte d'Orléans",
  "Alésia",
  "Mouton-Duvernet",
  "Denfert-Rochereau",
  "Raspail",
  "Saint-Jacques",
  "Glacière",
  "Place d'Italie",
  "Nationale",
  "Chevaleret",
  "Quai de la Gare",
  "Stalingrad",
  "Neuville Université",
  "Roissy-en-Brie",
  "Émerainville Pontault-Combault",
  "Les Yvris Noisy-le-Grand",
  "Les Boullereaux - Champigny",
  "Noisy-le-Grand — Mont d’Est",
  "Marne-la-Vallée Chessy - Parcs Disneyland (RER)",
  "Nation",
  "Château de Vincennes",
  "Bérault",
  "Fontenay-sous-Bois",
  "Boissy-Saint-Léger",
  "Rosny Bois Perrier",
  "Buttes Chaumont",
  "Bolivar",
  "Buzenval",
  "Saint-Mandé",
  "Ménilmontant",
  "Père Lachaise",
  "Philippe Auguste",
  "Alexandre Dumas",
  "Belleville",
  "Couronnes",
  "Colonel Fabien",
  "Blanche",
  "Place de Clichy",
  "Rome",
  "Villiers",
  "Monceau",
  "Porte Dauphine",
  "Rue Saint-Maur",
  "Parmentier",
  "Temple",
  "Arts et Métiers",
  "Bourse",
  "Quatre Septembre",
  "Opéra",
  "Havre - Caumartin",
  "Saint-Lazare",
  "Malesherbes",
  "Wagram",
  "Pereire",
  "Porte de Champerret",
  "Vaugirard",
  "Volontaires",
  "Falguière",
  "Duroc",
  "Torcy",
  "Bel-Air",
  "Évry Val de Seine",
  "Corbeil-Essonnes",
  "Maisse",
  "Buno - Gironville",
  "Ballancourt",
  "Moulin-Galant",
  "Essonnes Robinson",
  "Ponthierry Pringy",
  "Melun",
  "Marx Dormoy",
  "Gabriel Péri",
  "Jacques Bonsergent",
  "Les Coquetiers",
  "Gargan",
  "Freinville - Sevran",
  "Rougemont - Chanteloup",
  "Aulnay-sous-Bois",
  "Allée de la Tour-Rendez-Vous",
  "Anatole France",
  "Croix de Chavaux",
  "Mairie de Montreuil",
  "Pont de Levallois – Bécon",
  "Louise Michel",
  "Issy Val-de-Seine",
  "Maraîchers",
  "Robespierre",
  "Rue des Boulets",
  "Charonne",
  "Voltaire",
  "Saint-Ambroise",
  "Oberkampf",
  "Bonne Nouvelle",
  "Grands Boulevards",
  "Richelieu - Drouot",
  "Saint-Augustin",
  "Cardinal Lemoine",
  "Maubert - Mutualité",
  "Cluny-La Sorbonne",
  "Odéon",
  "Mabillon",
  "Balard",
  "Lourmel",
  "Boucicaut",
  "Félix Faure",
  "Dugommier",
  "Bercy",
  "Montparnasse-Bienvenüe",
  "Pasteur",
  "Kléber",
  "Passy",
  "Iéna",
  "Rue de la Pompe",
  "La Muette",
  "Ranelagh",
  "Jasmin",
  "Michel-Ange - Auteuil",
  "Michel-Ange - Molitor",
  "Exelmans",
  "Porte de Saint-Cloud",
  "Alma-Marceau",
  "Saint-Philippe du Roule",
  "Miromesnil",
  "Sèvres-Babylone",
  "Mairie de Clichy",
  "Brochant",
  "La Fourche",
  "Ségur",
  "Avenue Émile Zola",
  "Assemblée Nationale",
  "Solférino",
  "Rennes",
  "Notre-Dame des Champs",
  "Commerce",
  "École Militaire",
  "La Tour Maubourg",
  "Filles du Calvaire",
  "Saint-Sébastien - Froissart",
  "Chemin Vert",
  "Ledru-Rollin",
  "Faidherbe — Chaligny",
  "Montgallet",
  "Michel Bizot",
  "Liberté",
  "Porte Dorée",
  "Picpus",
  "Porte de Charenton",
  "Trinité d'Estienne d'Orves",
  "Notre-Dame de Lorette",
  "Saint-Georges",
  "Abbesses",
  "Lamarck - Caulaincourt",
  "Jules Joffrin",
  "Porte de la Chapelle",
  "Simplon",
  "Gare de l'Est",
  "Étienne Marcel",
  "Château d'Eau",
  "Les Halles",
  "Cité",
  "Saint-Michel",
  "Saint-Germain-des-Prés",
  "Saint-Placide",
  "Vavin",
  "Charles Michels",
  "Javel - André Citroën",
  "Église d'Auteuil",
  "Porte d'Auteuil",
  "Chardon-Lagache",
  "Mirabeau",
  "Boulogne - Jean Jaurès",
  "Boulogne - Pont de Saint-Cloud",
  "Goncourt",
  "Rambuteau",
  "Télégraphe",
  "Pyrénées",
  "Jourdain",
  "Gaîté – Joséphine Baker",
  "Plaisance",
  "Pernety",
  "Malakoff - Plateau de Vanves",
  "Bréguet-Sabin",
  "Richard Lenoir",
  "Riquet",
  "Crimée",
  "Corentin Cariou",
  "Porte de la Villette",
  "Le Peletier",
  "Cadet",
  "Pont Neuf",
  "Sully - Morland",
  "Pont Marie",
  "Place Monge",
  "Censier - Daubenton",
  "Les Gobelins",
  "Danube",
  "Mairie des Lilas",
  "Saint-Fargeau",
  "Pelleport",
  "Saint-Marcel",
  "Campo-Formio",
  "Guy Môquet",
  "Porte de Saint-Ouen",
  "Garibaldi",
  "Saint-Ouen",
  "Avenue Henri-Martin",
  "Avenue Foch",
  "Tolbiac",
  "Maison Blanche",
  "Porte d'Italie",
  "Les Agnettes",
  "Pont de l'Alma",
  "Saint-Michel Notre-Dame",
  "Fort d'Aubervilliers",
  "Aubervilliers - Pantin - Quatre Chemins",
  "La Courneuve - 8 mai 1945",
  "Cergy Préfecture",
  "Garges - Sarcelles",
  "Champs-Élysées - Clemenceau",
  "Le Vésinet - Le Pecq",
  "Le Bras de Fer - Évry Génopole",
  "Évry - Courcouronnes",
  "Orangis - Bois de l'Épine",
  "La Ferté Alais",
  "Noisiel",
  "Lognes",
  "Créteil-L'Échat",
  "Créteil Université",
  "Maisons-Alfort — Les Juilliottes",
  "Maisons-Alfort — Stade",
  "École Vétérinaire de Maisons-Alfort",
  "Charenton-Écoles",
  "Les Grésillons",
  "Brimborion",
  "La Plaine - Stade de France",
  "L'Abbaye",
  "Lycée Henri Sellier",
  "Villejuif - Léo Lagrange",
  "Villejuif - Louis Aragon",
  "Villejuif - Paul Vaillant-Couturier",
  "Le Kremlin-Bicêtre",
  "Nogent – Le Perreux",
  "Grigny-Centre",
  "Saint-Sulpice",
  "Château Landon",
  "Luxembourg",
  "La Courneuve - Aubervilliers",
  "Magenta",
  "Pantin",
  "Mitry-Claye",
  "Liège",
  "Drancy",
  "Survilliers - Fosses",
  "Chantilly – Gouvieux",
  "Orry-la-Ville - Coye-la-Forêt",
  "La Borne Blanche",
  "Gretz-Armainvilliers",
  "Le Blanc-Mesnil",
  "Parc des Expositions",
  "Bir-Hakeim",
  "Boutigny",
  "Auber",
  "Billancourt",
  "Grand Bourg",
  "Franconville - Le Plessis-Bouchard",
  "Pont de Sèvres",
  "Mennecy",
  "Nanterre-Préfecture",
  "Porte de Choisy",
  "Pierre et Marie Curie",
  "Vaneau",
  "Boussy Saint-Antoine",
  "Combs-la-Ville - Quincy",
  "Vigneux-sur-Seine",
  "Remise à Jorelle",
  "Bondy",
  "Villabé",
  "Brunoy",
  "Le Plessis-Chenet",
  "Nanterre-Université",
  "Ternes",
  "Créteil-Pointe du Lac",
  "Robinson",
  "Bourg-la-Reine",
  "Val d'Europe",
  "Alexandra David-Néel",
  "Porte de Pantin - Parc de la Villette",
  "Marie de Miribel",
  "Issy",
  "Jacques-Henri Lartigue",
  "Avron",
  "Neuilly - Porte Maillot",
  "Gare de Lyon",
  "Gentilly",
  "Nogent-sur-Marne",
  "Henri Farman",
  "Belvédère",
  "Achères - Ville",
  "Conflans-Fin-d'Oise",
  "Cergy-le-Haut",
  "Cergy - Saint-Christophe",
  "Barbès - Rochechouart",
  "Marcadet - Poissonniers",
  "Les Coteaux",
  "Les Milons",
  "Les Moulineaux",
  "Puteaux",
  "Suresnes - Longchamp",
  "Saint-Ouen-l'Aumône",
  "Choisy-le-Roi",
  "Sèvres-Lecourbe",
  "Vincennes",
  "Charles de Gaulle — Étoile (RER)",
  "Les Ardoines",
  "Bobigny - Pablo Picasso",
  "Poterne des Peupliers",
  "Montsouris",
  "Porte de Clignancourt",
  "Desnouettes",
  "Pont du Garigliano",
  "Gagny",
  "Le Vésinet Centre",
  "Joinville-le-Pont",
  "Chelles Gournay",
  "Ermont - Eaubonne",
  "Sartrouville",
  "Europe",
  "Botzaris",
  "Jaurès",
  "Place des Fêtes",
  "Porte de Vanves",
  "Mairie de Montrouge",
  "Porte de Montreuil",
  "Porte des Lilas",
  "Escadrille Normandie-Niémen",
  "Pont de Bondy",
  "Auguste Delaune",
  "Avenue du Président Kennedy",
  "Pontoise",
  "Étampes",
  "Issy - Val-de-Seine",
  "Musée d'Orsay",
  "Versailles-Château-Rive-Gauche",
  "Javel",
  "Porte Maillot",
  "Saint-Denis - L'Île-Saint-Denis",
  "Nanterre-Ville",
  "Adrienne Bolland",
  "Butte du Chapeau-Rouge",
  "Rosa Parks",
  "Porte d'Aubervilliers",
  "Canal Saint-Denis",
  "Colette Besson",
  "Delphine Seyrig",
  "Ella Fitzgerald - Grands Moulins de Pantin",
  "Hôpital Robert Debré",
  "Porte de Bagnolet",
  "Séverine",
  "Mairie d'Aubervilliers",
  "Bibliothèque François Mitterrand",
  "Cour Saint-Émilion",
  "Châtelet - Les Halles (voie 2B)",
  "Châtelet - Les Halles (voie 1B)",
  "Châtelet - Les Halles",
  "République",
  "Bibliothèque François Mitterrand - Sortie 2",
  "Bibliothèque François Mitterrand - Sortie 5",
  "Bibliothèque François Mitterrand - Sortie 3",
  "Bagneux",
  "Boissise-le-Roi",
  "Sortie 4 - Pont de Tolbiac",
  "Anvers",
  "La Chapelle",
  "Gare d'Austerlitz",
  "Quai de la Rapée",
  "Sentier",
  "Chamarande",
  "Sortie 1 - rue du Chevaleret",
  "Lieusaint - Moissy",
  "Ablon",
  "Athis-Mons",
  "Juvisy-sur-Orge",
  "Savigny-sur-Orge",
  "Yerres",
  "Épinay-sur-Orge",
  "Bouray",
  "Brétigny-sur-Orge",
  "La Norville - Saint-Germain-lès-Arpajon",
  "Lardy",
  "Marolles-en-Hurepoix",
  "Saint-Martin d'Étampes",
  "Saint-Michel-sur-Orge",
  "Sainte-Geneviève-des-Bois",
  "Étréchy",
  "Arpajon",
  "Breuillet - Bruyères-le-Châtel",
  "Breuillet - Village",
  "Saint-Chéron",
  "Égly",
  "Creil",
  "Bièvres",
  "Chemin d'Antony",
  "Chilly-Mazarin",
  "Longjumeau",
  "Meudon Val Fleury",
  "Gravigny-Balizy",
  "Igny",
  "Massy Verrières",
  "Petit Vaux",
  "Avenue Henri Martin",
  "Pereire - Levallois",
  "Créteil Pompadour",
  "Épinay-sur-Seine",
  "Chaville - Vélizy",
  "Jouy-en-Josas",
  "Versailles-Chantiers",
  "Goussainville",
  "Saint-Ouen-l'Aumône Liesse",
  "Vauboyen",
  "Louvres",
  "Montigny - Beauchamp",
  "Petit Jouy - Les Loges",
  "Pierrelaye",
  "Porchefontaine",
  "La Croix de Berny",
  "Parc de Sceaux",
  "Antony",
  "Bussy-Saint-Georges",
  "Saint-Rémy-lès-Chevreuse",
  "Aéroport Charles-de-Gaulle 2 RER",
  "Saint-Quentin-en-Yvelines",
  "Ivry-sur-Seine",
  "Vitry sur Seine",
  "Maisons-Alfort - Alfortville",
  "Les Saules",
  "Orly Ville",
  "Villeneuve-le-Roi",
  "Pont de Rungis Aéroport d'Orly",
  "Rungis - La Fraternelle",
  "Neuilly-Plaisance",
  "Ris-Orangis",
  "Saint-Cyr",
  "Vosves",
  "Viry-Châtillon",
  "Villiers-sur-Marne - Le Plessis-Trévise",
  "Villiers-le-Bel - Gonesse - Arnouville",
  "Villepinte",
  "Villeparisis - Mitry-le-Neuf",
  "Villeneuve Triage",
  "Villeneuve-Saint-Georges",
  "Boigneville",
  "Massy-Palaiseau",
  "Vert Galant",
  "Rosny-sous-Bois",
  "Pont du Garigliano-Hôpital Européen Georges-Pompidou",
  "Pierrefitte - Stains",
  "Noisy-le-Sec",
  "Mitry - Claye",
  "Victor Hugo",
  "Maisons-Laffitte",
  "Trocadéro",
  "Pigalle",
  "Château Rouge",
  "Jussieu",
  "Viroflay Rive Gauche",
  "Sucy - Bonneuil",
  "Charles de Gaulle — Étoile",
  "Cesson",
  "Poissy",
  "Le Mée",
  "Montgeron - Crosne",
  "Sevran Livry",
  "Tournan",
  "Ozoir-la-Ferrière",
  "Porte d'Asnières-Marguerite Long",
  "Diane Arbus",
  "Angélique Compoint",
  "Honoré de Balzac",
  "Pont de Neuilly",
  "Esplanade de La Défense",
  "Palais Royal - Musée du Louvre",
  "Bastille",
  "Hôtel de Ville",
  "Strasbourg - Saint-Denis",
  "Louis Blanc",
  "Gare du Nord (RER)",
  "Cernay",
  "Charles de Gaulle-Étoile",
  "Mairie de Saint-Ouen",
  "Saint-Denis Pleyel",
  "Carrefour Pleyel",
  "Saint-Denis - Porte de Paris",
  "Basilique de Saint-Denis",
  "Saint-Denis-Université",
  "Gare du Nord (Métro)",
  "Aéroport Charles de Gaulle 1 (Terminal 3)",
  "Val-de-Fontenay (RER A)",
  "Réaumur - Sébastopol",
  "Chaussée d'Antin - La Fayette",
  "Poissonnière",
  "Pyramides",
  "Le Raincy Villemomble Montfermeil",
  "Stade de France - Saint-Denis",
  "Gare du Nord",
  "Porte d'Ivry",
  "Front Populaire",
  "Le Chénay — Gagny",
  "Nogent - Le Perreux",
  "Val de Fontenay",
  "Rosny-Bois-Perrier",
  "Bobigny - Drancy",
  "Palaiseau",
  "Port Royal",
  "Fontenay-aux-Roses",
  "Sceaux",
  "Fontaine-Michalon",
  "Les Baconnets",
  "Palaiseau - Villebon",
  "Lozère",
  "Le Guichet",
  "Orsay-Ville",
  "Bures-sur-Yvette",
  "La Hacquinière",
  "Gif-sur-Yvette",
  "Courcelle-sur-Yvette",
  "Stade Charléty",
  "Dourdan-la-Forêt",
  "Dourdan",
  "Épinettes-Pouchet",
  "Porte de Clichy",
  "Sermaise",
  "Saint-Gratien",
  "Saint-Denis - Université",
  "Houilles - Carrières-Sur-Seine",
  "Achères Grand Cormier",
  "Saint-Germain-en-Laye",
  "Chatou - Croissy",
  "Rueil-Malmaison",
  "Gambetta",
  "Haussmann - Saint-Lazare",
  "Le Vert de Maisons",
  "Maryse Bastié",
  "Courcelles",
  "Boulainvilliers",
  "Mairie d'Ivry",
  "Edgar Quinet",
  "Olympiades",
  "Bobigny - Pantin - Raymond Queneau",
  "Porte de Pantin",
  "Laumière",
  "Ourcq",
  "Hoche",
  "Église de Pantin",
  "Avenue de France",
  "Montempoivre",
  "Baron Le Roy",
  "Jean Moulin",
  "Didot",
  "Brancion",
  "Georges Brassens",
  "Le Luth",
  "Gare de Gennevilliers",
  "Le Village",
  "Mairie de Villeneuve-la-Garenne",
  "La Noue",
  "Parc des Chanteraines",
  "Chemin des Reniers",
  "Maurice Lachâtre",
  "Drancy-Avenir",
  "La Courneuve - 8 Mai 1945",
  "Danton",
  "Stade Géo André",
  "Hôtel de Ville de la Courneuve",
  "La Courneuve 6 Routes",
  "Cosmonautes",
  "Théâtre Gérard Philipe",
  "Gaston Roulaud",
  "Hôpital Avicenne",
  "Hôtel de Ville de Bobigny",
  "Libération",
  "La Ferme",
  "Gare de Noisy-le-Sec",
  "Jean Rostand",
  "Rue du Bac",
  "Gallieni",
  "Boissière",
  "Daumesnil",
  "Corvisart",
  "Saint-François-Xavier",
  "Varenne",
  "Savigny-le-Temple - Nandy",
  "Le Coudray - Montceaux",
  "Pré Saint-Gervais",
  "Les Pavillons-sous-Bois",
  "Gare de Saint-Rémy-lès-Chevreuse",
  "Epinay-sur-Seine",
  "Gare de Bièvres",
  "Massy - Verrières",
  "Créteil-Préfecture",
  "Antony (RER B Orlyval)",
  "Bussy Saint-Georges",
  "Gare de Mennecy",
  "La Varenne - Chennevières",
  "Gare de Pierrelaye",
  "Le Parc de Saint-Maur",
  "Saint-Maur Créteil",
  "Laplace",
  "Gare de Créteil Pompadour",
  "Bibliothèque François Mitterrand - Sortie 1 et 4",
  "Champigny",
  "Massy-Palaiseau RER B",
  "Chaville-Vélizy",
  "Gare du Nord (Métro ligne 5)",
  "Gare du Nord (Métro ligne 4)",
  "Asnières-Gennevilliers–Les Courtilles",
];
