import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  table: {
    // marginTop: theme.spacing(2),
    // "& .MuiTable-root": {
    //   color: theme.palette.white.main,
    // },
    backgroundColor: "red !important",
  },
  // breadcrumbs: {
  //   color: theme.palette.white.main,
  //   fontWeight: "500",
  //   fontSize: "0.875rem",
  //   lineHeight: "1.57",
  // },
  // separator: {
  //   color: theme.palette.gray.main,
  // },

  // dashboard: {
  //   backgroundColor: theme.palette.secondary.main,
  //   marginTop: theme.spacing(3),
  // },
  // title: {
  //   color: "white",
  //   fontSize: "0.85rem",
  // },
  // text: {
  //   color: theme.palette.green.main,
  // },
}));

export default useStyles;
