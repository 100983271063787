import { useOktaAuth } from "@okta/okta-react";
import { PDFViewer } from "@react-pdf/renderer";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import PdfGenerator from "../../Components/PdfGenerator";
import { AppConfigContext } from "../../context";

const PdfLot = ({ match }) => {
  const appConfig = useContext(AppConfigContext);
  const { id } = match.params;
  const { authState } = useOktaAuth();

  const [content, setContent] = useState(null);

  const getDetails = async () => {
    fetch(`${appConfig.BACKEND_API_URL}/v1/secured/api/lots/${id}`, {
      headers: {
        Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setContent(res);
      });
  };

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (!content) return <></>;

  return (
    <PDFViewer width="100%" height="800">
      <PdfGenerator content={content} />
    </PDFViewer>
  );
};

export default PdfLot;
