import Drawer from "@material-ui/core/Drawer";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import SettingsPhoneIcon from "@material-ui/icons/SettingsPhone";

import { useEffect, useState } from "react";
import { menus } from "./utils";
// import { Link } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ArrowForward from "@material-ui/icons/ArrowForward";
import { Button } from "@material-ui/core";
import { useOktaAuth } from "@okta/okta-react";
import { ROLE_ADMIN, ROLE_BO, ROLE_CA } from "../../App";

const DEFAULT_STATE = {
  backoffice: false,
  lots: false,
  altaix: false,
  admin: false,
};

const LeftDrawer = ({ openDrawer, setOpenDrawer, infos }) => {
  const { authState } = useOktaAuth();
  const role = infos?.role || "";

  const [drawerWidth, setDrawerWidth] = useState(270);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      flexShrink: 0,
      width: drawerWidth,
    },
    drawerPaper: {
      backgroundColor: theme.palette.secondary.main,
      borderRight: "1px solid rgba(145, 158, 171, 0.24)",
      width: drawerWidth,
    },
    drawerContainer: {
      overflowX: "hidden",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    icon: {
      color: theme.palette.gray.main,
      "& > span": {
        color: theme.palette.gray.main,
      },
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    doubleNested: {
      paddingLeft: theme.spacing(8),
    },
  }));

  let location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const isWide = useMediaQuery(theme.breakpoints.up("md"));

  const [openState, setOpenState] = useState(DEFAULT_STATE);
  const [saveState, setSaveState] = useState(DEFAULT_STATE);

  const [isFolded, setIsFolded] = useState(false);

  let pathName = location.pathname.replace("/", "");
  pathName = pathName.split("/")[0];

  useEffect(() => {
    if (
      pathName === "lots" ||
      pathName === "propositions" ||
      pathName === "centre-appel"
    ) {
      setSaveState(openState);
      setOpenState(DEFAULT_STATE);
      setIsFolded(true);
    } else setIsFolded(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName]);

  // Handle open state of the drawer based on location
  useEffect(() => {
    const boList = ["residences", "agences", "negociateurs", "commerciaux"];
    const altaixList = ["guardiens", "propositions"];
    const adminList = ["utilisateurs"];
    if (boList.includes(pathName)) {
      setOpenState({ ...openState, backoffice: "open" });
    } else if (pathName === "lots") {
      setOpenState({ ...openState, backoffice: "open", lots: "open" });
    } else if (altaixList.includes(pathName)) {
      setOpenState({ ...openState, altaix: "open" });
    } else if (adminList.includes(pathName)) {
      setOpenState({ ...openState, admin: "open" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (open === false) setOpenDrawer(false);
    setOpenDrawer(!openDrawer);
  };

  useEffect(() => {
    setDrawerWidth(isFolded ? 50 : 270);
  }, [isFolded]);

  if (!authState || !authState.isAuthenticated) return null;

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant={isWide ? "permanent" : "temporary"}
        anchor="left"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          {[ROLE_ADMIN, ROLE_BO].includes(role) && (
            <List>
              <ListItem
                button
                onClick={() => {
                  if (isFolded) {
                    setOpenState(saveState);
                  } else {
                    setSaveState(openState);
                    setOpenState(DEFAULT_STATE);
                  }
                  setIsFolded(!isFolded);
                }}
              >
                <ListItemIcon className={classes.icon}>
                  <ArrowForward
                    style={{
                      transform: `rotate(${isFolded ? "0" : "180"}deg)`,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Plier le menu"
                  className={classes.icon}
                />
              </ListItem>
              {menus.map((menu) => (
                <div key={menu.name}>
                  <ListItem
                    component={!menu.expand ? Link : Button}
                    to={menu.href}
                    button
                    style={{ textTransform: "capitalize" }}
                    onClick={() => {
                      if (isFolded) {
                        setOpenState(saveState);
                        setIsFolded(false);
                      } else if (menu.expand) {
                        setOpenState({
                          ...openState,
                          [menu.ref]: !openState[menu.ref],
                        });
                      }
                    }}
                  >
                    <ListItemIcon className={classes.icon}>
                      {menu.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={menu.name}
                      className={classes.icon}
                    />
                    {menu.expand &&
                      (openState[menu.ref] ? (
                        <ExpandLess className={classes.icon} />
                      ) : (
                        <ExpandMore className={classes.icon} />
                      ))}
                  </ListItem>

                  {menu.expand && (
                    <Collapse
                      in={openState[menu.ref]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {menu.childs.map((nestedMenu) => (
                          <div key={nestedMenu.name}>
                            <ListItem
                              button
                              component={!nestedMenu.expand ? Link : Button}
                              style={{ textTransform: "capitalize" }}
                              to={nestedMenu.href}
                              className={classes.nested}
                              onClick={() => {
                                if (isFolded) {
                                  setOpenState(saveState);
                                  setIsFolded(false);
                                } else if (nestedMenu.expand) {
                                  setOpenState({
                                    ...openState,
                                    [nestedMenu.ref]:
                                      !openState[nestedMenu.ref],
                                  });
                                }
                              }}
                            >
                              <ListItemIcon className={classes.icon}>
                                {nestedMenu.icon}
                              </ListItemIcon>
                              <ListItemText
                                primary={nestedMenu.name}
                                className={classes.icon}
                              />
                              {nestedMenu.expand &&
                                (openState[nestedMenu.ref] ? (
                                  <ExpandLess className={classes.icon} />
                                ) : (
                                  <ExpandMore className={classes.icon} />
                                ))}
                            </ListItem>
                            {nestedMenu.expand && (
                              <Collapse
                                in={openState[nestedMenu.ref]}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List component="div" disablePadding>
                                  {nestedMenu.childs.map((subNestedMenu) => (
                                    <ListItem
                                      button
                                      component={Link}
                                      to={subNestedMenu.href}
                                      className={classes.nested}
                                      key={subNestedMenu.name}
                                    >
                                      <ListItemIcon className={classes.icon}>
                                        {subNestedMenu.icon}
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={subNestedMenu.name}
                                        className={classes.icon}
                                      />
                                    </ListItem>
                                  ))}
                                </List>
                              </Collapse>
                            )}
                          </div>
                        ))}
                      </List>
                    </Collapse>
                  )}
                </div>
              ))}
            </List>
          )}
          <Divider />
          {[ROLE_ADMIN, ROLE_CA].includes(role) && (
            <List>
              <ListItem
                button
                component={Link}
                to="/centre-appel"
                style={{ textTransform: "capitalize" }}
              >
                <ListItemIcon className={classes.icon}>
                  <SettingsPhoneIcon />
                </ListItemIcon>
                <ListItemText primary="Centre appel" className={classes.icon} />
              </ListItem>
            </List>
          )}

          {/* Bottom version & copyright */}
          {!isFolded && (
            <>
              <Divider style={{ width: "130%" }} />
              <List>
                <ListItem
                  button
                  component={Link}
                  to="/"
                  style={{ textTransform: "capitalize" }}
                >
                  <ListItemText
                    primary={`V3 - 2014 - ${new Date().getFullYear()} © ESSET`}
                    className={classes.icon}
                  />
                </ListItem>
              </List>
            </>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default LeftDrawer;
