import { Typography } from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { interpretContent } from "../DetailsContent/utils";
import ModifyField from "../ModifyField";
import PropTypes from "prop-types";

const AccessTable = ({
  content,
  accessPoint,
  structure,
  category,
  modify,
  handleAccessChange,
}) => {
  return (
    <Table
      // sx={{ minWidth: 650 }}
      aria-label={`Tableau des accès`}
    >
      <TableHead>
        <TableRow>
          {structure.map((title) => {
            return (
              <TableCell key={title.id}>
                <Typography variant="subtitle1">{title.name}</Typography>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {content !== null ? (
          content[accessPoint]?.map((access, index) => {
            return (
              <TableRow
                key={access.codeImmeuble + index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                {structure.map((field) => {
                  return (
                    <TableCell key={field.id} component="th" scope="row">
                      {modify && !field.disabled ? (
                        <ModifyField
                          type={field.type}
                          value={access[field.id]}
                          id={field.id}
                          isAccess={true}
                          escalierIndex={index}
                          handleAccessChange={handleAccessChange}
                        />
                      ) : (
                        <Typography variant="subtitle2">
                          {interpretContent(
                            access[field.id],
                            access.name,
                            category,
                            true
                          )}
                        </Typography>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell component="th" scope="row">
              Chargement...
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

AccessTable.propTypes = {
  // TODO : get shape from java API
  content: PropTypes.object.isRequired,
  structure: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      type: PropTypes.oneOf([
        "select",
        "number",
        "boolean",
        "multiline",
        "date",
      ]),
      suffixe: PropTypes.string,
    }).isRequired
  ).isRequired,
  accessPoint: PropTypes.oneOf(["escaliers", "annexes"]).isRequired,
  category: PropTypes.string.isRequired,
  modify: PropTypes.bool.isRequired,
  handleAccessChange: PropTypes.func.isRequired,
};

export default AccessTable;
