import { useContext, useEffect, useState } from "react";
import { Grid, LinearProgress, Typography } from "@material-ui/core";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import DetailsButton from "../../Components/DetailsButton";
import DetailsContent from "../../Components/DetailsContent";
import { Alert, Skeleton } from "@mui/material";
import CustomDialog from "../../Components/CustomDialog";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { detailsStructure } from "../../Components/DetailsContent/utils";
import { useOktaAuth } from "@okta/okta-react";
import PropTypes from "prop-types";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { AppConfigContext } from "../../context";

const Details = ({ match }) => {
  const appConfig = useContext(AppConfigContext);
  const { authState } = useOktaAuth();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { category, id, activateModification, deleteItem } = match.params;
  const [modify, setModify] = useState(false);
  const [open, setOpen] = useState(deleteItem ? true : false);
  const [loading, setLoading] = useState(true);
  const [triggerSave, setTriggerSave] = useState(false);
  const capitalize = (name) => name[0].toUpperCase() + name.slice(1);
  const breadcrumbPath = {
    path: [
      { name: "Accueil", href: "/" },
      {
        name: capitalize(category),
        href: `/${category}`,
      },
    ],
    currentElement: id,
  };

  const [center, setCenter] = useState(null);
  const [content, setContent] = useState(null);

  useEffect(() => {
    if (!content || category !== "residences") return;
    if (content.lat && content.lon) {
      setCenter([content.lat, content.lon]);
      return;
    }

    fetch(
      // `https://nominatim.openstreetmap.org/search?q=${content.adresse1},+${content.ville}&format=json&polygon=1&addressdetails=1`
      `https://nominatim.openstreetmap.org/search?city=${content.ville}&street=${content.adresse1}&format=json&polygon=1&addressdetails=1`
    )
      .then((res) => res.json())
      .then((res) => {
        if (!res[0]) {
          enqueueSnackbar(
            "Impossible de récupérer les coordonnées géographiques.",
            {
              variant: "warning",
            }
          );
          return;
        }
        let data = [parseFloat(res[0].lat), parseFloat(res[0].lon)];
        setCenter(data);

        // Save the lat & long values
        let updatedContent = { ...content };
        updatedContent.lat = parseFloat(res[0].lat);
        updatedContent.lon = parseFloat(res[0].lon);
        setContent(updatedContent);
        setTimeout(() => {
          setTriggerSave(true);
        }, 500);
      })
      .catch((err) => console.error("error lat long", err));
  }, [content, category, enqueueSnackbar]);

  const getDetails = async () => {
    setLoading(true);
    fetch(`${appConfig.BACKEND_API_URL}/v1/secured/api/${category}/${id}`, {
      headers: {
        Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setContent(res);
        setLoading(false);
      });
  };

  const updateOnBo = async () => {
    setLoading(true);
    (async () => {
      fetch(`${appConfig.BACKEND_API_URL}/v1/secured/api/propositions`, {
        method: "POST",
        headers: {
          Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          codeImmeuble: content.codeImmeuble,
          codeLot: content.codeLot,
        }),
      })
        .then(async (res) => {
          setLoading(false);
          if (res.ok) {
            enqueueSnackbar("Proposition mise à jour sur le BO", {
              variant: "success",
            });
            getDetails();
          } else {
            let jsonRes = await res.json();
            console.log("resoins", jsonRes);
            throw new Error("Something went wrong :", res.json());
          }
        })
        .catch((err) => {
          setLoading(false);
          enqueueSnackbar(
            `La proposition n'a pas pu être mise à jour sur le BO : ${err}`,
            {
              variant: "error",
            }
          );
        });
    })();
  };

  const publierSite = async () => {
    setLoading(true);
    const endpoint =
      category === "lots"
        ? `${content.codeImmeuble}-${content.codeLot}`
        : `${content.codeImmeuble}`;

    let errMsg = "Echec de la publication";
    await fetch(
      `${appConfig.BACKEND_API_URL}/v1/secured/api/${category}/publierSite/${endpoint}`,
      {
        method: "POST",
        headers: {
          Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.ok) {
          enqueueSnackbar("Publication effectuée avec succès", {
            variant: "success",
          });
          getDetails();
        } else if (res.status === 400) {
          return res.json().then((data) => {
            let msg = data.msg ? data.msg : errMsg;
            enqueueSnackbar(msg, { variant: "error" });
          });
        } else {
          throw new Error(res);
        }
      })
      .catch((err) => {
        enqueueSnackbar(errMsg, { variant: "error" });
      });

    setLoading(false);
  };

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, id]);

  useEffect(() => {
    setModify(activateModification === "true" ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activateModification]);

  const handleSave = () => {
    setModify(false);
    setTriggerSave(true);
  };

  const handleValidate = () => {
    fetch(`${appConfig.BACKEND_API_URL}/v1/secured/api/${category}/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) {
          enqueueSnackbar("Suppression effectuée avec succès", {
            variant: "success",
          });
          history.push(`/${category}`);
        } else {
          throw new Error("Something went wrong :", res.json());
        }
      })
      .catch((err) => {
        enqueueSnackbar(`La suppression n'a pas pu être effectuée : ${err}`, {
          variant: "error",
        });
      });
    setOpen(false);
  };

  return (
    <Grid container>
      <CustomDialog
        open={open}
        setOpen={setOpen}
        message={detailsStructure[category].msgDelete}
        title="Attention, suppression de données imminente"
        handleValidate={handleValidate}
      />
      <Grid item xs={12}>
        {loading && <LinearProgress />}
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={4}>
            <Typography variant="h1">
              {/* Capitalize and remove plural of the category name */}
              {capitalize(category).replace(/s$/, "")}{" "}
              {content && category === "residences" && `: ${content.libelle}`}{" "}
              {content && category === "lots" && `: ${content.codeLot}`}
            </Typography>
            {modify ? "Modification" : "Visualisation"}
            <Typography variant="subtitle2">
              Dernière mise à jour :{" "}
              {content && content.dateUpdate ? (
                new Date(content.dateUpdate).toLocaleString("fr")
              ) : (
                <Skeleton
                  sx={{ bgcolor: "grey.500" }}
                  width={200}
                  variant="text"
                />
              )}
            </Typography>
            <CustomBreadcrumb breadcrumbPath={breadcrumbPath} />
          </Grid>
          <Grid item style={{ position: "sticky", top: "60px", zIndex: 9 }}>
            <DetailsButton
              category={category}
              modify={modify}
              setModify={setModify}
              handleSave={handleSave}
              setOpen={setOpen}
              updateOnBo={updateOnBo}
              publierSite={publierSite}
              publicationSite={content?.publicationSite}
              loading={loading}
              content={content}
            />
          </Grid>
          {content &&
            detailsStructure[category].alerts?.map((alert) => {
              if (
                alert.display(
                  content.publicUrl,
                  content.dpe,
                  content.ges,
                  content.dateCommandeDpe,
                  content.numDdtCst
                )
              )
                return (
                  <Alert
                    key={alert.message}
                    style={{ width: "100%" }}
                    severity={alert.severity}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: alert.message(content.publicUrl),
                      }}
                    />
                  </Alert>
                );
              else return null;
            })}
          {content && category === "residences" && center && (
            <Grid item xs={12} style={{ height: "25vh", zIndex: 2 }}>
              <div style={{ height: "100%", width: "100%", padding: "0 15px" }}>
                <MapContainer
                  center={center}
                  zoom={13}
                  scrollWheelZoom={false}
                  style={{ height: "100%", width: "100%" }}
                >
                  <TileLayer
                    attribution='<a href="https://esset-pm.com/">Esset</a>'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={center}>
                    <Popup>
                      {content.adresse1} - {content.ville} <br />{" "}
                      {content.libelle}
                    </Popup>
                  </Marker>
                </MapContainer>
              </div>
            </Grid>
          )}
          {content && (
            <DetailsContent
              category={category}
              content={content}
              setContent={setContent}
              modify={modify}
              triggerSave={triggerSave}
              setTriggerSave={setTriggerSave}
              getDetails={getDetails}
              loading={loading}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

Details.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      category: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      activateModification: PropTypes.string,
      deleteItem: PropTypes.string,
    }),
  }),
};

export default Details;
