import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // root: {
  //   "& > * + *": {
  //     marginTop: theme.spacing(2),
  //   },
  //   marginTop: theme.spacing(2),
  // },
  // breadcrumbs: {
  //   color: theme.palette.white.main,
  //   fontWeight: "500",
  //   fontSize: "0.875rem",
  //   lineHeight: "1.57",
  // },
  // separator: {
  //   color: theme.palette.gray.main,
  // },

  editions: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: theme.spacing(3),
  },
  title: {
    color: "white",
    fontSize: "1rem",
  },
  text: {
    color: theme.palette.white.main,
    fontSize: "0.9rem",
  },
  link: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none",
    },
  },
  icon: {
    position: "relative",
    // top: 7,
    left: 5,
  },
}));

export default useStyles;
