// import { createMuiTheme } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";

let themeColor = localStorage.getItem("themeColor");
if (!themeColor) localStorage.setItem("themeColor", "white");

const isNature = themeColor === "nature";

const palette = {
  primary: {
    main: "#5db991",
    light: "#009eb9",
    dark: "#0c2241",
  },
  secondary: {
    main: isNature ? "rgb(41, 49, 66)" : "rgb(255, 255, 255)",
    light: "#FAB49E",
    // dark: "rgb(41, 49, 66)",
    dark: isNature ? "#f7866429" : "red",
  },
  gray: {
    main: isNature ? "rgb(255, 255, 255)" : "rgb(144 158 171)",
    // main: "#c2c7d0",
    light: "#e9ecef",
    superLight: "#f4f6f9",
    dark: "#212529",
  },
  black: {
    main: "#000000",
    light: "#212529",
  },
  white: {
    main: "#ffffff",
  },
  green: {
    main: "#5db991",
  },
  yellow: {
    main: "#ffc107",
    light: "#fbee91",
  },
  red: {
    main: "#dc3545",
    light: "#fba5a1",
  },
  blue: {
    main: "#1a7de2",
    dark: "#343a40",
  },
  violet: {
    main: "#5D55AE",
  },
  orange: {
    main: "#dc3545",
  },
  background: {
    default: isNature ? "rgb(28, 37, 49)" : "rgb(244, 245, 247)",
    black: "rgb(28, 37, 49)",
  },
  text: {
    default: isNature ? "rgb(244, 245, 247)" : "rgb(28, 37, 49)",
  },
};

const typography = {
  h5: {
    fontSize: "1.3rem",
    fontWeight: 500,
    color: palette.text.default,
  },
  // Title of table
  h6: {
    fontSize: "1rem",
    fontWeight: 500,
    color: palette.text.default,
  },
  h3: {
    fontSize: 34,
    fontWeight: 600,
  },
  h4: {
    fontSize: "1.5rem",
    // fontSize: "0.9rem",
    fontWeight: 900,
    color: "#414141",
  },
  h1: {
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: "1.334",
    // color: isNature ? ""
  },
  subtitle1: {
    fontSize: 12,
    fontWeight: 700,
    color: isNature ? palette.gray.light : palette.gray.dark,
  },
  body1: {
    // fontSize: 13,
    // color: palette.gray.dark,
    color: isNature ? palette.gray.light : palette.gray.dark,
    fontWeight: 600,
    fontSize: "0.9rem",
  },
  body2: {
    // fontSize: "0.9rem",
    // color: palette.gray.dark,
    // lineHeight: 1.5,

    fontWeight: 400,
    color: palette.gray.dark,
    fontSize: "0.9rem",
  },
  subtitle2: {
    // fontSize: 10,
    color: palette.gray.main,
  },
  fontFamily: ["Styrene A Web", "sans-serif"].join(","),
};

const overrides = {
  MuiTableCell: {
    sizeMedium: {
      padding: "10px !important",
    },
    root: {
      padding: "10px !important",
      "& a": {
        color: "#5ac68b",
      },
    },
  },
  MuiTableSortLabel: {
    active: {
      color: palette.text.default,
    },
    root: {
      color: palette.text.default,
    },
  },
  MuiPickersDay: {
    daySelected: {
      backgroundColor: "#de7021",
    },
  },
  MuiFormLabel: {
    asterisk: {
      color: "#dc3545",
      "&$error": {
        color: "#dc3545",
      },
    },
  },
  MuiTablePagination: {
    select: {
      // "& div": {
      color: palette.text.default,
    },
    // },
  },
  MuiTypography: {
    caption: {
      color: palette.text.default,
    },
  },
  MuiOutlinedInput: {
    notchedOutline: {
      borderColor: palette.text.default,
    },
  },
  MuiCard: {
    root: {
      backgroundColor: palette.secondary.main,
      color: palette.text.default,
      margin: 10,
      borderRadius: 10,
    },
  },
  MuiCardHeader: {
    title: {
      color: palette.text.default,
    },
  },
  MuiInput: {
    underline: {
      "&:before": { borderColor: `${palette.text.default} !important` },
    },
  },
  MuiInputBase: { input: { color: palette.text.default } },
  MuiIconButton: { label: { color: palette.text.default } },
  MuiPickersBasePicker: {
    container: {
      backgroundColor: palette.secondary.main,
    },
  },
  MuiPickersCalendarHeader: {
    iconButton: {
      backgroundColor: palette.secondary.main,
    },
    dayLabel: {
      color: palette.text.default,
    },
  },
  MuiMenu: {
    list: {
      // "&>li": {
      // backgroundColor: palette.green.main,
      backgroundColor: palette.background.default,
      // "&:hover": {
      //   backgroundColor: palette.green.main,
      // },
      // },
    },
  },
  MuiDialog: {
    paperScrollPaper: {
      color: palette.text.default,
      backgroundColor: palette.secondary.main,
    },
  },
  MuiSelect: {
    icon: {
      color: palette.text.default,
    },
  },
  MuiAutocomplete: {
    option: {
      backgroundColor: palette.background.default,
      "&:hover": {
        backgroundColor: palette.green.main,
      },
    },
  },
  MuiListItemSecondaryAction: {
    root: {
      display: "contents",
    },
  },
  MuiButton: {
    text: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
};

const theme = createTheme({
  palette,
  typography,
  overrides,
  direction: "ltr",
  responsiveFontSizes: true,
  roundedCorners: true,
  theme: "NATURE",
});

export default theme;
