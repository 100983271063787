import React, { useContext, useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import CustomTable from "../../Components/CustomTable";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import PasswordIcon from "@mui/icons-material/Password";
import Edit from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { AppConfigContext } from "../../context";

const Users = () => {
  const appConfig = useContext(AppConfigContext);
  const { authState } = useOktaAuth();
  const history = useHistory();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");

  const breadcrumbPath = {
    path: [{ name: "Accueil", href: "/" }],
    currentElement: "Utilisateurs",
  };

  const formatData = (result) => {
    if (!result.length) return [];
    return result.map((agence) => {
      let output = { ...agence };
      output.nom = `${output.nom} ${output.prenom}`;
      output.email = <a href={`mailto: ${output.email}`}>{output.email}</a>;
      return output;
    });
  };

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        `${appConfig.BACKEND_API_URL}/v1/secured/api/users`,
        {
          headers: {
            Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => res);
      let formattedData = formatData(response);

      setTotalElements(response.totalElements);

      setData(formattedData);
    }

    fetchData();
  }, [
    authState.accessToken.accessToken,
    authState.accessToken.tokenType,
    appConfig.BACKEND_API_URL,
  ]);

  const columns = [
    {
      title: "Utilisateur",
      field: "id", // field is the "key" in the data
      filtering: false,
    },
    {
      title: "Nom",
      field: "nom",
      filtering: false,
    },
    {
      title: "Email",
      field: "email",
      filtering: false,
    },
    {
      title: "Date dernier login",
      field: "lastLoginTime",
      type: "datetime",
      filtering: false,
    },
    {
      title: "Rôle",
      field: "role",
      filtering: false,
    },
  ];

  const actions = [
    {
      icon: Edit,
      tooltip: "Modifier les informations de cet utilisateur",
      onClick: (_event, rowData) =>
        history.push(`/details/users/${rowData.id}/true`),
    },
    {
      icon: PasswordIcon,
      tooltip: "Voir les informations de cet utilisateur",
      onClick: (_event, rowData) =>
        history.push(`/details/users/${rowData.id}/false`),
    },
    {
      icon: DeleteOutline,
      tooltip: "Supprimer cet utilisateur",
      onClick: (_event, rowData) =>
        history.push(`/details/users/${rowData.id}/false/true`),
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1">Utilisateurs</Typography>
        <CustomBreadcrumb breadcrumbPath={breadcrumbPath} />
        <CustomTable
          title="Liste des utilisateurs"
          columns={columns}
          data={data}
          actions={actions}
          size={size}
          setSize={setSize}
          page={page}
          setPage={setPage}
          totalElements={totalElements}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          filter={false}
          noSearch={true}
          autoFocus={true}
        />
      </Grid>
    </Grid>
  );
};
export default Users;
