import { useContext, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Button,
  Typography,
} from "@material-ui/core";
import useStyles from "./style";
import { useOktaAuth } from "@okta/okta-react";
import { AppConfigContext } from "../../context";
import { useSnackbar } from "notistack";

function CircularProgressWithLabel(props) {
  const classes = useStyles();
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} size={80} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" className={classes.title}>
          {props.current}
        </Typography>
      </Box>
    </Box>
  );
}

const Dashboard = ({ infos }) => {
  const appConfig = useContext(AppConfigContext);
  const { authState } = useOktaAuth();
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const [alerts, setAlerts] = useState(null);
  const [dateImportGardiens, setDateImportGardiens] = useState(null);
  const [dateImportPropositions, setDateImportPropositions] = useState(null);

  const sendAlerts = () => {
    enqueueSnackbar("Les emails vont être envoyé", {
      variant: "success",
    });

    fetch(`${appConfig.BACKEND_API_URL}/v1/secured/api/alerteEmail/process`, {
      headers: {
        Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
      },
    });
  };

  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${appConfig.BACKEND_API_URL}/v1/secured/api/alerteEmail/currentStats`,
        {
          headers: {
            Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
          },
        }
      );
      const res = await response.json();
      if (res) {
        setAlerts(res);
      }
    })();
    (async () => {
      const response = await fetch(
        `${appConfig.BACKEND_API_URL}/v1/secured/api/dateImport/gardiens`,
        {
          headers: {
            Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
          },
        }
      );
      const res = await response.json();
      if (res) {
        setDateImportGardiens(res.date);
      }
    })();
    (async () => {
      const response = await fetch(
        `${appConfig.BACKEND_API_URL}/v1/secured/api/dateImport/propositions`,
        {
          headers: {
            Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
          },
        }
      );
      const res = await response.json();
      if (res) {
        setDateImportPropositions(res.date);
      }
    })();
  }, [
    authState.accessToken.accessToken,
    authState.accessToken.tokenType,
    appConfig.BACKEND_API_URL,
  ]);

  return (
    <Grid container>
      <Grid item xs={12}>
        {/* <Paper className={classes.dashboard}>Dashboard</Paper> */}
        <Card className={classes.dashboard} elevation={6}>
          <CardHeader
            classes={{ title: classes.title }}
            title="Tableau de bord"
          />
          <CardContent>
            <Grid container justifyContent="center">
              <Grid item xs={6} md={4} className={classes.alertContainer}>
                <CircularProgressWithLabel
                  value={(alerts?.nbActif / alerts?.nbTotal) * 100}
                  current={`${parseInt(
                    (alerts?.nbActif / alerts?.nbTotal) * 100
                  )}%`}
                />
                <Typography className={classes.text} variant="h6">
                  {alerts?.nbActif} alertes actives sur {alerts?.nbTotal}
                </Typography>
                {/* <CircularProgressWithLabel
                  value={(alerts?.nbSent / alerts?.nbActif) * 100}
                  current={`${parseInt(
                    (alerts?.nbSent / alerts?.nbActif) * 100
                  )}%`}
                /> */}

                <Typography variant="h6">
                  Nombre de clients concernés par le dernier envoi:{" "}
                  {alerts?.nbSent}
                </Typography>
                <Typography variant="h6">
                  Nombre de clients non concernés par le dernier envoi:{" "}
                  {alerts?.nbNotSent}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                direction="column"
                container
                display="flex"
                justifyContent="center"
                style={{ marginLeft: 30 }}
              >
                <Typography className={classes.text} variant="h6">
                  Imports
                </Typography>
                {infos.role === "ROLE_ADMIN" && (
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ width: "fit-content", marginBottom: 20 }}
                    onClick={sendAlerts}
                  >
                    Envoi des alertes
                  </Button>
                )}
                <Typography variant="h6">
                  Date du dernier envoi des alertes email:{" "}
                  {new Date(alerts?.dateEnvoi).toLocaleString("fr")}
                </Typography>
                {dateImportGardiens && (
                  <Typography variant="h6">
                    Date du dernier import des gardiens:{" "}
                    {new Date(dateImportGardiens).toLocaleString("fr")}
                  </Typography>
                )}
                {dateImportPropositions && (
                  <Typography variant="h6">
                    Date du dernier import des propositions:{" "}
                    {new Date(dateImportPropositions).toLocaleString("fr")}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
