const elementDetails = {
  residences: {
    title:
      "Renseignez l'ensemble des champs ci-dessous pour créer une nouvelle résidence",
    fields: [
      { name: "Code résidence", id: "codeImmeuble" },
      { name: "Nom de l'immeuble", id: "libelle" },
      { name: "Adresse", id: "adresse1" },
      { name: "Code postal", id: "codePostal" },
      { name: "Ville", id: "ville" },
      {
        name: "Agence",
        id: "idAgence",
        type: "autocomplete",
        uri: "/v1/secured/api/agences/all",
      },
    ],
  },
  lots: {
    title:
      "Renseignez l'ensemble des champs ci-dessous pour créer un nouveau lot",
    fields: [
      {
        name: "Type commercialisation",
        id: "type",
        type: "select",
        localSelectContent: [
          "Maison",
          "Appartement",
          "Parking",
          "Duplex",
          "Divers",
          "Local commercial",
        ],
      },
      {
        name: "Résidence",
        id: "codeImmeuble",
        type: "autocomplete",
        uri: "/v1/secured/api/residences",
      },
      { name: "Code lot", id: "codeLot" },
    ],
  },
  negociateurs: {
    title:
      "Renseignez l'ensemble des champs ci-dessous pour créer un nouveau négociateur",
    fields: [
      {
        name: "Prénom et nom",
        id: "libelle",
      },
      { name: "e-mail", id: "email" },
      { name: "Téléphone", id: "telephone" },
      { name: "Email en copie (Reporting)", id: "emailCopie" },
    ],
  },
};

export { elementDetails };
