import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useOktaAuth } from "@okta/okta-react";
import PropTypes from "prop-types";

const CustomSelect = ({
  url,
  id,
  handleAutoCompleteChange,
  label,
  limitFetch,
  loadingText,
  multiple = false,
  resetSearch,
  setResetSearch,
  error,
}) => {
  const { authState } = useOktaAuth();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  // Memory options to agency list
  const [memOptions, setMemOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    if (resetSearch) {
      setValue(null);
      setOptions([]);
      setResetSearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetSearch]);

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (limitFetch) return;
      const response = await fetch(url, {
        headers: {
          Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
        },
      });
      const res = await response.json();

      if (active && res.content) {
        setOptions(res.content);
      } else if (active && res) {
        setOptions(res);
        setMemOptions(res);
      }
    })();

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleInputChange = (value) => {
    (async () => {
      const response = await fetch(`${url}?t=${value}`, {
        headers: {
          Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
        },
      });
      const res = await response.json();
      if ((res && id === "villes") || (res && id === "departements"))
        setOptions(res || []);
      if (res.content) setOptions(res.content || []);
    })();
  };

  const handleCallCenterInputChange = (value) => {
    (async () => {
      const response = await fetch(`${url}&t=${value}`, {
        headers: {
          Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
        },
      });
      const res = await response.json();
      if (res) setOptions(res || []);
    })();
  };

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="distant-autocomplete"
      multiple={value ? multiple : false}
      clearText="Effacer"
      loadingText={loadingText}
      style={{ marginTop: 15, marginBottom: 15 }}
      open={open}
      value={value}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(e, value, reason) => {
        if (reason === "select-option") {
          if (id === "idAgence") setValue(value.id);
          else setValue(value);
          if (!limitFetch) {
            if (id === "idAgence") {
              handleAutoCompleteChange(value.id, id);
            } else if (id === "villes")
              handleAutoCompleteChange(value.codePostal, id);
            else if (id === "departements")
              handleAutoCompleteChange(
                value.codeDepartement,
                id,
                value.nomDepartement
              );
            else handleAutoCompleteChange(value.codeImmeuble, id);
          } else handleAutoCompleteChange(value.idContact, id);
        } else if (
          reason === "clear" &&
          (id === "villes" || id === "departements")
        )
          handleAutoCompleteChange("", id);
      }}
      onInputChange={(_e, value, reason) => {
        if (reason === "reset" && id !== "idAgence") setValue(null);
        if (reason === "clear") setValue(null);
        if (!limitFetch) handleInputChange(value);
        else if (limitFetch && value.length > 2)
          handleCallCenterInputChange(value);
        else setOptions([]);
      }}
      fullWidth={true}
      getOptionSelected={(option, value) => {
        if (limitFetch) return option.idContact === value.idContact;
        else if (id === "villes") return option.codePostal === value.codePostal;
        else if (id === "departements")
          return option.codeDepartement === value.codeDepartement;
        else if (id === "idAgence") return option.id === value;
        else return option.libelle === value.libelle;
      }}
      getOptionLabel={(option) => {
        if (limitFetch)
          return `${option.nom} ${option.prenom} - ${option.telephone}`;
        else if (id === "villes")
          return `${option.ville} (${option.codePostal})`;
        else if (id === "departements")
          return `${option.nomDepartement} (${option.codeDepartement})`;
        else if (id === "idAgence") {
          if (typeof option === "object") {
            return `${option.libelle} - ${option.code}`;
          } else if (typeof option === "number") {
            const elementToDisplay = memOptions.find(
              (agence) => agence.id === option
            );
            return `${elementToDisplay?.libelle} - ${elementToDisplay?.code}`;
          }
          if (option) return `${option.libelle} - ${option.code}`;
          else return "";
        } else if (id === "codeImmeuble") {
          return `${option.codeImmeuble} - ${option.libelle}`;
        }
      }}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          style={{ marginTop: 15, marginBottom: 15 }}
          {...params}
          label={label}
          variant="outlined"
          fullWidth
          error={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

CustomSelect.propTypes = {
  url: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  handleAutoCompleteChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
};

export default CustomSelect;
