import { forwardRef } from "react";
import MaterialTable, { MTableToolbar } from "material-table";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import useStyles from "./style";
import { useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const CustomTable = ({
  columns,
  data,
  title,
  actions,
  size,
  setSize,
  page,
  setPage,
  totalElements,
  searchTerm,
  setSearchTerm,
  setSortBy,
  setIsDesc,
  filter,
  noSearch,
  showSearch,
  autoFocus,
  actionLeft,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => (
      <FirstPage
        style={{ color: theme.palette.text.default }}
        {...props}
        ref={ref}
      />
    )),
    LastPage: forwardRef((props, ref) => (
      <LastPage
        style={{ color: theme.palette.text.default }}
        {...props}
        ref={ref}
      />
    )),
    NextPage: forwardRef((props, ref) => (
      <ChevronRight
        style={{ color: theme.palette.text.default }}
        {...props}
        ref={ref}
      />
    )),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft
        style={{ color: theme.palette.text.default }}
        {...props}
        ref={ref}
      />
    )),
    ResetSearch: forwardRef((props, ref) => (
      <Clear
        style={{ color: theme.palette.text.default }}
        {...props}
        ref={ref}
      />
    )),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward
        style={{ color: theme.palette.text.default }}
        {...props}
        ref={ref}
      />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  columns.forEach(
    (column) =>
      (column.headerStyle = {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.text.default,
      })
  );

  return (
    <MaterialTable
      title={title}
      actions={actions}
      columns={columns}
      onOrderChange={(index, variation) => {
        if (noSearch) return;
        setSortBy(columns[index].field);
        setIsDesc(variation === "desc" ? true : false);
      }}
      data={data}
      page={page}
      totalCount={totalElements}
      icons={tableIcons}
      localization={{
        toolbar: {
          searchPlaceholder: "Rechercher",
          searchTooltip: "Entrez les termes de votre recherche",
        },
        pagination: {
          labelDisplayedRows: `	
        {from}-{to} sur {count}`,
          labelRowsSelect: "lignes",
        },
        body: {
          emptyDataSourceMessage: (
            <h3
              style={{
                textAlign: "center",
                color: theme.palette.text.default,
              }}
            >
              Pas de données à afficher
            </h3>
          ),
        },
      }}
      onChangePage={(currentPage, rowPerLine) => {
        if (noSearch) return;
        setPage(currentPage);
      }}
      onChangeRowsPerPage={(nbrRows) => {
        if (noSearch) return;
        setSize(nbrRows);
      }}
      onSearchChange={(term) => {
        setSearchTerm(term || "");
        if (term) setPage(0);
        return;
      }}
      components={{
        Toolbar: (props) => (
          <div
            style={{
              display: "flex",
              justifyContent: "left",
            }}
          >
            <MTableToolbar {...props} />
          </div>
        ),
      }}
      options={{
        search: showSearch ? true : !noSearch,
        searchAutoFocus: autoFocus || false,
        searchFieldVariant: "outlined",
        searchFieldStyle: {
          color: theme.palette.text.default,
          borderColor: theme.palette.text.default,
        },
        filtering: filter,
        actionsColumnIndex: actionLeft ? 0 : -1,
        actionsCellStyle: {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.text.default,
        },
        headerStyle: {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.text.default,
        },
        // searchText: "Rechercher",
        tableLayout: "auto",
        // rowStyle: {
        //   // overflowWrap: "break-word",
        // },
        pageSize: size || 20,
        pageSizeOptions: [10, 20, 30, 40],
        searchText: noSearch && searchTerm,
        showTitle: false,
      }}
      className={classes.table}
      // classes={{ root: classes.root }}
      style={{
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.text.default,
        borderRadius: 15,
      }}
    />
  );
};

CustomTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      width: PropTypes.string,
      filtering: PropTypes.bool,
      headerStyle: PropTypes.object,
      tableData: PropTypes.object,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(PropTypes.object),
  size: PropTypes.number,
  setSize: PropTypes.func,
  page: PropTypes.number,
  setPage: PropTypes.func,
  totalElements: PropTypes.number,
  searchTerm: PropTypes.string,
  setSortBy: PropTypes.func,
  setIsDesc: PropTypes.func,
  filter: PropTypes.bool,
  noSearch: PropTypes.bool,
  showSearch: PropTypes.bool,
  actionLeft: PropTypes.bool,
};

export default CustomTable;
