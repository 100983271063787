// import ImageList from "@material-ui/core/ImageList";
// import ImageListItem from "@material-ui/core/ImageListItem";
import { useContext, useEffect } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Gallery from "react-photo-gallery";
import { useState } from "react";
import { arrayMoveImmutable } from "array-move";
import Carousel, { Modal, ModalGateway } from "react-images";
import { useCallback } from "react";
import { Button } from "@mui/material";
import PopupAddImg from "./PopupAddImg";
import { AppConfigContext } from "../../context";

const imgWithClick = { cursor: "pointer" };

const Photo = ({ index, onClick, photo, margin, direction, top, left }) => {
  // console.log("INDEX ?", index);
  const imgStyle = { margin: margin };
  if (direction === "column") {
    imgStyle.position = "absolute";
    imgStyle.left = left;
    imgStyle.top = top;
  }

  const handleClick = (event) => {
    console.log("here ?");
    onClick(event, { photo, index });
  };

  return (
    <img
      style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
      {...photo}
      // onMouseEnter={(e) => console.log("mouen enter", e)}
      // onMouseDown={(e) => console.log("mouen DOWN", index)}
      // onDoubleClick={(e) => console.log("double click", e)}
      onClick={onClick ? handleClick : null}
      alt="img"
    />
  );
};

/* popout the browser and maximize to see more rows! -> */
const SortablePhoto = SortableElement((item) => {
  return <Photo onClick={() => console.log("yU ?")} {...item} />;
});

const SortableGallery = SortableContainer(({ items, handleDelete }) => (
  <Gallery
    photos={items}
    renderImage={(props) => (
      <SortablePhoto {...props} handleDelete={handleDelete} />
    )}
    // onClick={() => handleDelete}
  />
));

const ImageViewer = ({
  photos,
  modify,
  // conf,
  codeImmeuble,
  codeLot,
  setTriggerSave,
  setContent,
  content,
}) => {
  const appConfig = useContext(AppConfigContext);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  let initialUnformatedImages = photos.map((photo) => {
    return {
      width: 1,
      height: 1,
      name: photo,
      source: `${appConfig.IMG_URL}${photo}`,
      src: `${appConfig.IMG_URL}${photo}`,
    };
  });

  const [items, setItems] = useState(initialUnformatedImages);
  const [addImg, setAddImg] = useState(false);

  useEffect(() => {
    async function asyncImgFormatCalculation() {
      let formatedImages = await Promise.all(
        photos.map((photo) => {
          return (async () => {
            const img = new Image();
            img.src = `${appConfig.IMG_URL}${photo}`;
            await img.decode();
            return {
              // TODO : to finalize
              width: (img.width / img.height) * 0.8,
              height: 1,
              name: photo,
              source: `${appConfig.IMG_URL}${photo}`,
              src: `${appConfig.IMG_URL}${photo}`,
            };
          })();
        })
      );
      setItems(formatedImages);
    }
    asyncImgFormatCalculation();
  }, [appConfig.IMG_URL, photos]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(arrayMoveImmutable(items, oldIndex, newIndex));
  };

  const handleDelete = () => {
    console.log("gonna delete");
  };

  // const handleAddImage = () => {
  //   console.log("gonna add an image");
  // };

  if (!modify) {
    return (
      <div>
        <Gallery photos={items.slice(0, 3)} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={items.map((x) => {
                  console.log("X ??", x);
                  return {
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title,
                  };
                })}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
      // <ImageList rowHeight={160} cols={3}>
      //   {photos.map((photo) => (
      //     <ImageListItem key={photo} cols={1}>
      //       <img src={`https://esset-pm.com${photo}`} alt="esset location" />
      //     </ImageListItem>
      //   ))}
      //   {photos.map((photo) => (
      //     <ImageListItem key={photo} cols={1}>
      //       <img src={`https://esset-pm.com${photo}`} alt="esset location" />
      //     </ImageListItem>
      //   ))}
      //   {photos.map((photo) => (
      //     <ImageListItem key={photo} cols={1}>
      //       <img src={`https://esset-pm.com${photo}`} alt="esset location" />
      //     </ImageListItem>
      //   ))}
      //   {modify && (
      //     <ImageListItem
      //       cols={1}
      //       onClick={handleAddImage}
      //       style={{ cursor: "pointer" }}
      //     >
      //       <img
      //         src={`https://upload.wikimedia.org/wikipedia/commons/thumb/9/9e/Plus_symbol.svg/1200px-Plus_symbol.svg.png`}
      //         alt="Add"
      //       />
      //     </ImageListItem>
      //   )}
      // </ImageList>
    );
  } else {
    return (
      <>
        <SortableGallery
          handleDelete={handleDelete}
          items={items.slice(0, 3)}
          onSortEnd={onSortEnd}
          axis={"xy"}
        />
        <Button onClick={() => setAddImg(true)}>Gestion des images</Button>
        <PopupAddImg
          addImg={addImg}
          setAddImg={setAddImg}
          initialState={items}
          codeImmeuble={codeImmeuble}
          codeLot={codeLot}
          appConfig={appConfig}
          setTriggerSave={setTriggerSave}
          setContent={setContent}
          content={content}
        />
      </>

      // <ImageList rowHeight={160} cols={3}>
      //   {photos.map((photo) => (
      //     <ImageListItem key={photo} cols={1}>
      //       <img src={`https://esset-pm.com${photo}`} alt="esset location" />
      //     </ImageListItem>
      //   ))}
      // </ImageList>
    );
  }
};

export default ImageViewer;
