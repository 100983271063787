import PropTypes from "prop-types";
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core";

const listOfRoom = [
  { id: "chambre1", name: "1 chambre" },
  { id: "chambre2", name: "2 chambres" },
  { id: "chambre3", name: "3 chambres" },
  { id: "chambre4", name: "4 chambres" },
  { id: "chambre5", name: "5 chambres" },
];

function ChambreCheckbox({ nbrChambres, handleChange }) {
  return (
    <FormControl component="fieldset" style={{ marginTop: 10 }}>
      <FormLabel component="legend">Nombre de chambres :</FormLabel>
      <FormGroup row>
        {listOfRoom.map((room) => (
          <FormControlLabel
            key={room.id}
            control={
              <Checkbox
                checked={nbrChambres[room.id]}
                onChange={handleChange}
                name={room.id}
              />
            }
            label={room.name}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}

ChambreCheckbox.propTypes = {
  nbrChambres: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default ChambreCheckbox;
