import React from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import Dashboard from "../../Components/Dashboard";
import Editions from "../../Components/Editions";

function Home({ infos }) {
  const breadcrumbPath = {
    path: [{ name: "Accueil", href: "/" }],
    currentElement: "Interface administration",
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1">Interface administration</Typography>
        <CustomBreadcrumb breadcrumbPath={breadcrumbPath} />
        <Dashboard infos={infos} />
        <Editions />
      </Grid>
    </Grid>
  );
}
export default Home;
