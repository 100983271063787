import { Tooltip } from "@material-ui/core";
import React from "react";

export function ValueLabelComponent(props) {
  const { children, open, value } = props;

  const popperRef = React.useRef(null);
  React.useEffect(() => {
    if (popperRef.current) {
      popperRef.current.update();
    }
  });

  const symbol = children.props["aria-label"] === "surface" ? " m2" : "€";

  return (
    <Tooltip
      PopperProps={{
        popperRef,
      }}
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={value + symbol}
    >
      {children}
    </Tooltip>
  );
}
