import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PrintIcon from "@mui/icons-material/Print";

const detailsStructure = {
  residences: {
    msgDelete:
      "Vous vous apprêtez à supprimer les données de cette résidences ainsi que de tous les lots qui y sont associés. Souhaitez-vous poursuivre ?",
    options: [
      {
        id: "publish",
        name: "Publier sur le site",
        color: "#5db991",
        icon: <LockOpenIcon />,
      },
      {
        id: "unpublish",
        color: "#ee4646",
        name: "Dépublier du site",
        icon: <LockIcon />,
      },
      {
        id: "modify",
        color: "#ff9d38",
        name: "Modifier",
        icon: <CreateIcon />,
      },
      {
        id: "delete",
        color: "#ee4646",
        name: "Supprimer",
        icon: <DeleteIcon />,
      },
      {
        id: "back",
        name: "Retour à la liste",
        icon: <KeyboardBackspaceIcon />,
      },
    ],
    content: [
      {
        title: "Informations générales",
        fields: [
          { name: "Propriétaire", id: "nomProprietaire" },
          { name: "Code El", id: "codeImmeuble", disabled: true },
          { name: "Ville", id: "ville", disabled: true },
          { name: "Code postal", id: "codePostal", disabled: true },
          { name: "Adresse", id: "adresse1", disabled: true },
          {
            name: "Description de la résidence",
            id: "description",
            type: "multiline",
          },
          {
            name: "Attaché commercial",
            id: "idAttacheCommercial",
            visualId: "attacheCommercial",
            type: "select",
            uri: "v1/secured/api/attachesCommerciaux",
          },
          {
            name: "Gardien",
            id: "idGardien",
            visualId: "gardien",
            type: "autocomplete",
            uri: "v1/secured/api/gardiens",
          },
          {
            name: "Négociateur",
            id: "idNegociateur",
            visualId: "negociateur",
            type: "select",
            uri: "v1/secured/api/negociateurs",
          },
          {
            name: "Agence",
            id: "idAgence",
            visualId: "agence",
            type: "autocomplete",
            uri: "v1/secured/api/agences/all",
          },
          { name: "Latitude", id: "lat", type: "number" },
          { name: "Longitude", id: "lon", type: "number" },
        ],
      },
      {
        title: "Détails de la résidence",
        fields: [
          {
            name: "Type de standing",
            id: "standing",
            type: "select",
            localSelectContent: [
              "Autre",
              "Ancien",
              "Brique et pierre",
              "Haussmannien",
              "Moderne",
              "Neuf",
              "Pierre de taille",
              "Récent",
            ],
          },
          { name: "École à proximité", id: "ecole", type: "boolean" },
          { name: "Commerce à proximité", id: "commerce", type: "boolean" },
          { name: "Métro à proximité", id: "metro", type: "boolean" },
          {
            name: "Nom de station",
            id: "nomMetro",
            type: "autocomplete",
            condition: "metro",
          },
          { name: "Bus à proximité", id: "bus", type: "boolean" },
          {
            name: "Nom de l'arrêt de bus",
            id: "nomBus",
            condition: "bus",
          },
          {
            name: "Chauffage collectif",
            id: "chauffageCollectif",
            type: "boolean",
          },
          {
            name: "Eau chaude collective",
            id: "eauChaudeCollective",
            type: "boolean",
          },
          { name: "Piscine à proximité", id: "piscine", type: "boolean" },
          {
            name: "Espace vert à proximité",
            id: "espaceVert",
            type: "boolean",
          },
        ],
      },
      {
        title: "Liste des lots de cette résidence",
        type: "superTable",
        fields: [
          { name: "Code lot", id: "codeLot" },
          { name: "Typologie", id: "typeBien" },
          { name: "Surface", id: "surfaceUtile", suffixe: "m²" },
          { name: "Loyer C.C.", id: "loyerCc", suffixe: "€" },
          { name: "Loué", id: "topLoue" },
          { name: "Option", id: "topOption" },
        ],
      },
      {
        title: "Accès",
        type: "table",
        accessPoint: "escaliers",
        fields: [
          { name: "Bâtiment", id: "codeBatiment", disabled: true },
          { name: "Escalier", id: "codeEscalier", disabled: true },
          { name: "Étage maxi", id: "etageMax", type: "number" },
          { name: "Ascenceur", id: "nbAscenseurs", type: "number" },
          { name: "Vide ordure", id: "poubelle", type: "boolean" },
          { name: "Vidéo-phone", id: "videophone", type: "boolean" },
          { name: "Interphone", id: "interphone", type: "boolean" },
          { name: "Digicode", id: "digicode", type: "boolean" },
          { name: "Accès handicapé", id: "accesHandicape", type: "boolean" },
        ],
      },
    ],
  },
  lots: {
    options: [
      {
        id: "publish",
        color: "#5db991",
        name: "Publier sur le site",
        icon: <LockOpenIcon />,
      },
      {
        id: "unpublish",
        color: "#ee4646",
        name: "Dépublier du site",
        icon: <LockIcon />,
      },
      {
        id: "modify",
        name: "Modifier",
        color: "#ff9d38",
        icon: <CreateIcon />,
      },
      { id: "print", name: "Imprimer", color: "#8562f2", icon: <PrintIcon /> },
      {
        id: "back",
        name: "Retour à la liste",
        icon: <KeyboardBackspaceIcon />,
      },
    ],
    alerts: [
      {
        message: () =>
          "Pour publier sur le site, un bien ESSET doit avoir un diagnostic DPE, GES et une date de réalisation. (hors type parking)",
        severity: "info",
        display: (_publicationSite, dpe, ges, dateCommandeDpe) => {
          if (!dpe || !ges || !dateCommandeDpe) return true;
          else return false;
        },
      },
      {
        message: () =>
          "Pour publier sur le site, un bien FONCIA doit avoir un numéro DDT Constatimmo.",
        severity: "info",
        display: (_publicationSite, _dpe, _ges, _dateCommandeDpe, numDdtCst) =>
          !numDdtCst,
      },
      {
        message: () => "Ce lot n'est pas affiché sur le site",
        severity: "warning",
        display: (publicationSite) => !publicationSite,
      },
      {
        message: (publicationSite) =>
          `Ce lot est affiché sur <a target='_blank' href='${publicationSite}'>ce site</a>`,
        severity: "success",
        display: (publicationSite) => publicationSite,
      },
    ],
    msgDelete:
      "Vous vous apprêtez à supprimer les données de ce lot. Souhaitez-vous poursuivre ?",
    content: [
      {
        title: "Description",
        fields: [
          {
            name: "Résidence",
            sub: "residence",
            id: "libelle",
            disabled: true,
            isLink: "/details/residences/",
            linkEndpoint: "codeImmeuble",
          },
          { name: "Type de commercialisation", id: "typeBien", disabled: true },
          { name: "N° Lot principal", id: "codeLot", disabled: true },
          { name: "N° de porte", id: "porte" },
          {
            name: "Commentaire usage intene",
            id: "commentaireInterneLot",
            type: "multiline",
          },
          { name: "Étage", id: "etage" },
          {
            name: "Surface utile",
            id: "surfaceUtile",
            type: "number",
            suffixe: "m²",
          },
          {
            name: "Surface Carrez",
            id: "surfaceCarrez",
            type: "number",
            suffixe: "m²",
          },
          { name: "Nb de pièces", id: "nbPieces", type: "number" },
          { name: "Nb de chambres", id: "nbChambres", type: "number" },
          { name: "Nb de salle de bain", id: "nbSdb", type: "number" },
          { name: "Nb de salle d'eau", id: "nbSdeau", type: "number" },
          { name: "Nb de WC", id: "nbWc", type: "number" },
          { name: "N° DDT Constatimo", id: "numDdtCst", type: "number" },
          { name: "DPE", id: "dpe", type: "number" },
          { name: "Gaz à effet de serre", id: "ges", type: "number" },
          {
            name: "Date de réalisation GES",
            id: "dateCommandeDpe",
            type: "date",
          },
          {
            name: "Montant estimé des dépenses annuelles d’énergie pour un usage standard",
            id: "depenseAnnuelleEnergie",
            type: "number",
            suffixe: "€",
          },
          {
            name: "Année de référence dépenses d'énergie",
            id: "anneeRefDepenseEnergie",
            type: "number",
          },
        ],
      },
      {
        title: "Caractéristiques",
        fields: [
          { name: "Parking", id: "nbParkings", type: "number" },
          { name: "Cave", id: "nbCaves", type: "number" },
          { name: "Balcon", id: "balcon", type: "boolean" },
          { name: "Jardin", id: "jardin", type: "boolean" },
          { name: "Cheminée", id: "cheminee", type: "boolean" },
          { name: "Duplex", id: "duplex", type: "boolean" },
          { name: "Terasse", id: "terrasse", type: "boolean" },
          {
            name: "Surface de la terrasse",
            id: "surfaceTerrasse",
            type: "number",
            suffixe: "m²",
          },
          {
            name: "Surface du jardin",
            id: "surfaceJardin",
            type: "number",
            suffixe: "m²",
          },
        ],
      },
      {
        title: "Annexes du lot",
        type: "table",
        accessPoint: "annexes",
        fields: [
          { name: "Type", id: "type", disabled: true },
          { name: "Code", id: "codeAnnexe", disabled: true },
          { name: "Numéro", id: "numero", disabled: true },
          { name: "Surface", id: "surfaceUtile", disabled: true },
        ],
      },
      {
        title: "Travaux",
        fields: [
          {
            name: "Date de fin de travaux",
            id: "dateFinTravaux",
            type: "date",
          },
          { name: "Relouable en l'état", id: "relouable", type: "boolean" },
          { name: "Travaux", id: "travaux", type: "boolean" },
          { name: "Nettoyage", id: "nettoyage", type: "boolean" },
        ],
      },
      {
        title: "Disponibilité",
        fields: [
          {
            name: "Date de disponibilité",
            id: "dateDispo",
            type: "date",
          },
          {
            name: "Date de vacance",
            id: "dateVacance",
            type: "date",
          },
          { name: "Loué", id: "topLoue", type: "boolean" },
          { name: "Nom locataire", id: "nomLocataire", condition: "topLoue" },
          {
            name: "Date de location",
            id: "dateLocation",
            type: "date",
            condition: "topLoue",
          },
          { name: "Option", id: "topOption", type: "boolean" },
          {
            name: "Dossier retenu",
            id: "dossierRetenu",
            condition: "topOption",
          },
          {
            name: "Date d'effet souhaité",
            id: "dateEffetSouhaite",
            type: "date",
            condition: "topOption",
          },
          { name: "Agence", id: "topAgence", type: "boolean" },
          {
            name: "Agence mandatée",
            id: "agenceMandatee",
            condition: "topAgence",
          },
          {
            name: "Date de délégation",
            id: "dateDelegation",
            type: "date",
            condition: "topAgence",
          },
          {
            name: "Commentaire",
            id: "commentaire",
            condition: "topAgence",
          },
        ],
      },
      {
        title: "Ancien locataire",
        fields: [
          { name: "Prénom et nom", id: "nomAncienLocataire", disabled: true },
          { name: "Code bail", id: "codeBail", disabled: true },
          {
            name: "Loyer HC",
            id: "ancienLoyerHc",
            disabled: true,
            type: "number",
            suffixe: "€",
          },
          {
            name: "Charges",
            id: "ancienCharges",
            type: "number",
            suffixe: "€",
            disabled: true,
          },
          {
            name: "Loyer CC",
            id: "ancienLoyerCc",
            type: "number",
            suffixe: "€",
            disabled: true,
          },
          {
            name: "Dépôt de garantie",
            id: "ancienDepotGarantie",
            type: "number",
            suffixe: "€",
            disabled: true,
          },
          {
            name: "Date d'entrée",
            id: "dateEntreeAncienLocataire",
            type: "date",
            disabled: true,
          },
          {
            name: "Date réception congés",
            id: "dateReceptionConge",
            type: "date",
            disabled: true,
          },
          {
            name: "Date de fin de facturation",
            id: "dateFinFacturation",
            type: "date",
            disabled: true,
          },
        ],
      },
      {
        title: "Commercialisation",
        fields: [
          { name: "N° d'offre", id: "numeroOffre", disabled: true },
          { name: "Loyer HC", id: "loyerHc", type: "number", suffixe: "€" },
          { name: "Charges", id: "charges", type: "number", suffixe: "€" },
          {
            name: "Loyer CC",
            id: "loyerCc",
            type: "number",
            suffixe: "€",
            disabled: true,
          },
          {
            name: "Dépôt de garantie",
            id: "depotGarantie",
            type: "number",
            suffixe: "€",
          }
        ],
        subSection: {
          title: "Honoraires",
          type: "styledTable",
          fields: [
            {
              name: "Honoraires location",
              id1: "honoraireRedactionLocation",
              id2: "honoraireLocationProprietaire",
              type: "number",
              suffixe: "€",
              disabled: false
            },
            {
              name: "Honoraires état des lieux",
              id1: "honoraireEtatDesLieuxLocataire",
              id2: "honoraireEtatDesLieuxProprietaire",
              type: "number",
              suffixe: "€",
              disabled: false
            },
            {
              name: "Total honoraires",
              id1: "honoraireTotalLocataire",
              id2: "honoraireTotalProprietaire",
              type: "number",
              suffixe: "€",
              disabled: true
            }
          ],
          columns: [
            {
              name: "",
              id: "fieldId"
            },
            {
              name: "Locataire",
              id: "locatiare"
            },
            {
              name: "Propriétaire",
              id: "propriétaire"
            }
          ]
        }
      },
      {
        title: "Annonce",
        fields: [
          { name: "Titre", id: "titreAnnonce" },
          {
            name: "Description",
            id: "descriptionAnnonce",
            type: "htmlEditor",
            html: true,
          },
          { name: "Nombre de visites", id: "visitNumber", type: "number" },
          { name: "Commentaire", id: "commentaireAnnonce" },
        ],
      },
      {
        title: "Photos associées à ce lot",
        type: "photos",
        fields: [
          // {
          //   name: "URL image luminosité",
          //   id: "urlImgLuminosite",
          // },
          {
            name: "URL certificat de luminosité",
            id: "urlCertifLuminosite",
          },
          {
            name: "URL visite 360",
            id: "urlVisite360",
          },
        ],
      },
      {
        title: "Informations sur la résidence",
        type: "residence",
        fields: [
          { name: "Libellé immeuble", id: "libelle", disabled: true },
          { name: "Description", id: "description", disabled: true },
          { name: "Propriétaire", id: "nomProprietaire", disabled: true },
          { name: "Adresse 1", id: "adresse1", disabled: true },
          { name: "Adresse 2", id: "adresse2", disabled: true },
          { name: "Code postal", id: "codePostal", disabled: true },
          { name: "Ville", id: "ville", disabled: true },
          {
            name: "Attaché commercial",
            id: "attacheCommercial",
            disabled: true,
          },
          { name: "Négociateur", id: "negociateur", disabled: true },
          { name: "Intervenant", id: "intervenant", disabled: true },
          { name: "Agence", id: "agence", disabled: true },
        ],
      },
      {
        title: "Détails de la résidence",
        type: "residence",
        fields: [
          { name: "Type de standing", id: "standing", disabled: true },
          { name: "École à proximité", id: "ecole", disabled: true },
          { name: "Commerce à proximité", id: "commerce", disabled: true },
          { name: "Métro à proximité", id: "metro", disabled: true },
          { name: "Bus à proximité", id: "bus", disabled: true },
          { name: "Piscine à proximité", id: "piscine", disabled: true },
          { name: "Espace vert à proximité", id: "espaceVert", disabled: true },
          {
            name: "Chauffage collectif",
            id: "chauffageCollectif",
            disabled: true,
          },
          {
            name: "Eau chaude collective",
            id: "eauChaudeCollective",
            disabled: true,
          },
        ],
      },
      {
        title: "Accès",
        type: "table",
        accessPoint: "escaliers",
        fields: [
          { name: "Bâtiment", id: "codeBatiment", disabled: true },
          { name: "Escalier", id: "codeEscalier", disabled: true },
          { name: "Étage maxi", id: "etageMax", disabled: true },
          { name: "Ascenceur", id: "nbAscenseurs", disabled: true },
          { name: "Vide ordure", id: "poubelle", disabled: true },
          { name: "Vidéophone", id: "videophone", disabled: true },
          { name: "Interphone", id: "interphone", disabled: true },
          { name: "Digicode", id: "digicode", disabled: true },
          { name: "Accès handicapé", id: "accesHandicape", disabled: true },
        ],
      },
    ],
  },
  agences: {
    options: [
      {
        id: "back",
        name: "Retour à la liste",
        icon: <KeyboardBackspaceIcon />,
      },
    ],
    content: [
      {
        title: "Informations générales",
        fields: [
          { name: "Name", id: "libelle" },
          { name: "Référence SI Commercial", id: "refSiCommercial" },
          { name: "Référence SI Gestion", id: "refSiGestion" },
          { name: "e-mail", id: "email" },
          { name: "Téléphone", id: "phone" },
          { name: "Adresse", id: "adresse1" },
          { name: "Code postal", id: "codePostal" },
          { name: "Ville", id: "ville" },
        ],
      },
      {
        title: "Résidences associées",
        type: "superTable",
        fields: [{ name: "Libelle", id: "libelle" }],
      },
    ],
  },
  negociateurs: {
    options: [
      {
        id: "modify",
        name: "Modifier",
        color: "#ff9d38",
        icon: <CreateIcon />,
      },
      {
        id: "delete",
        name: "Supprimer",
        color: "#ee4646",
        icon: <DeleteIcon />,
      },
      {
        id: "back",
        name: "Retour à la liste",
        icon: <KeyboardBackspaceIcon />,
      },
    ],
    msgDelete:
      "Vous vous apprêtez à supprimer les données de ce négociateur. Souhaitez-vous poursuivre ?",
    content: [
      {
        title: "Informations générales",
        fields: [
          { name: "Prénom et nom", id: "libelle" },
          { name: "e-mail", id: "email" },
          { name: "Téléphone", id: "telephone" },
          { name: "Email en copie (Reporting)", id: "emailCopie" },
        ],
      },
      {
        title: "Résidences associées",
        type: "superTable",
        fields: [{ name: "Libelle", id: "libelle" }],
      },
    ],
  },
  attachesCommerciaux: {
    options: [
      {
        id: "modify",
        name: "Modifier",
        color: "#ff9d38",
        icon: <CreateIcon />,
      },
      {
        id: "delete",
        name: "Supprimer",
        color: "#ee4646",
        icon: <DeleteIcon />,
      },
      {
        id: "back",
        name: "Retour à la liste",
        icon: <KeyboardBackspaceIcon />,
      },
    ],
    msgDelete:
      "Vous vous apprêtez à supprimer les données de cet attaché commercial. Souhaitez-vous poursuivre ?",
    content: [
      {
        title: "Informations générales",
        fields: [
          { name: "Code", id: "code", disabled: true },
          { name: "Prénom et nom", id: "libelle" },
          { name: "e-mail", id: "email" },
          { name: "Téléphone", id: "telephone" },
        ],
      },
      {
        title: "Résidences associées",
        type: "superTable",
        fields: [{ name: "Libelle", id: "libelle" }],
      },
    ],
  },
  contacts: {
    options: [
      {
        id: "modify",
        name: "Modifier",
        color: "#ff9d38",
        icon: <CreateIcon />,
      },
      {
        id: "delete",
        name: "Supprimer",
        color: "#ee4646",
        icon: <DeleteIcon />,
      },
      {
        id: "back",
        name: "Retour à la liste",
        icon: <KeyboardBackspaceIcon />,
      },
    ],
    msgDelete:
      "Vous vous apprêtez à supprimer les données de ce contact. Souhaitez-vous poursuivre ?",
    content: [
      {
        title: "Informations générales",
        fields: [
          { name: "Civilité", id: "civilite", disabled: true },
          { name: "Prénom", id: "prenom", disabled: true },
          { name: "Nom", id: "nom", disabled: true },
          { name: "Adresse", id: "residence", disabled: true },
          { name: "Téléphone", id: "telephone", disabled: true },
          { name: "Email", id: "email", disabled: true },
        ],
      },
      {
        title: "Détails appel",
        fields: [
          { name: "Origine", id: "origine", disabled: true },
          { name: "Code annonce", id: "codeAnnonce", disabled: true },
          { name: "Typologie", id: "type", disabled: true },
          { name: "Message", id: "message", disabled: true, html: true },
          // { name: "Commentaire", id: "commentaire", disabled: true },
          {
            name: "Clôture appel",
            id: "noteFin",
            disabled: true,
            clotureAppel: true,
          },
        ],
      },
      {
        title: "Intérêt contact",
        fields: [
          { name: "Intéressé par le lot", id: "interesseLot", type: "boolean" },
          {
            name: "Proposition d'un autre lot",
            id: "propositionAutreLot",
            type: "boolean",
          },
          { name: "De même typologie", id: "memeTypologie", type: "boolean" },
          { name: "Autre typologie", id: "autreTypologie" },
          { name: "De même ville", id: "memeVille", type: "boolean" },
          { name: "Autre ville", id: "autreVille" },
          {
            name: "Dossier acceptable",
            id: "dossierAcceptable",
            type: "boolean",
          },
          { name: "Solvable", id: "solvable", type: "boolean" },
        ],
      },
    ],
  },
  propositions: {
    options: [
      {
        id: "majOnBo",
        name: "MAJ sur le BO",
        color: "#ff9d38",
        icon: <CreateIcon />,
      },
      {
        id: "back",
        name: "Retour à la liste",
        icon: <KeyboardBackspaceIcon />,
      },
    ],
    msgDelete: "Nope",
    content: [
      {
        title: "Informations générales",
        fields: [
          { name: "Code immeuble", id: "codeImmeuble" },
          { name: "Code lot", id: "codeLot" },
          { name: "Code bâtiment", id: "codeBatiment" },
          { name: "Libellé résidence", id: "libelleResidence" },
          { name: "Client", id: "nomProprietaire" },
          {
            name: "Date création proposition bail",
            id: "datePropositionBail",
            type: "date",
          },
          {
            name: "Date de disponibilité",
            id: "dateDispo",
            type: "date",
          },
        ],
      },
      {
        title: "Détails",
        fields: [
          { name: "N° de l'offre de commercialisation", id: "numeroOffre" },
          { name: "Adresse", id: "adresse1" },
          { name: "Code postal", id: "codePostal" },
          { name: "Ville", id: "ville" },
          { name: "Étage", id: "etage" },
          { name: "Typologie", id: "typeBien" },
          { name: "Surface utile", id: "surfaceUtile", suffixe: "m²" },
        ],
      },
      {
        title: "Locataire sortant",
        fields: [
          { name: "Ancien locataire", id: "nomAncienLocataire" },
          { name: "Montant loyer HC", id: "ancienLoyerHc", suffixe: "€" },
          { name: "Montant charges", id: "ancienCharges", suffixe: "€" },
          { name: "Montant DG", id: "ancienDepotGarantie", suffixe: "€" },
          { name: "Montant des travaux", id: "montantTravaux", suffixe: "€" },
          {
            name: "Date d'entrée",
            id: "dateEntreeAncienLocataire",
            type: "date",
          },
          {
            name: "Date réception de congé",
            id: "dateReceptionConge",
            type: "date",
          },
          {
            name: "Date fin de facturation",
            id: "dateFinFacturation",
            type: "date",
          },
          { name: "Date fin des travaux", id: "dateFinTravaux", type: "date" },
          { name: "Code bail ancien locataire", id: "codeAncienBail" },
        ],
      },
    ],
  },
  users: {
    options: [
      {
        id: "modify",
        color: "#ff9d38",
        name: "Modifier",
        icon: <CreateIcon />,
      },
      {
        id: "back",
        name: "Retour à la liste",
        icon: <KeyboardBackspaceIcon />,
      },
    ],
    msgDelete:
      "Vous vous apprêtez à supprimer les données de cet utilisateur. Souhaitez-vous poursuivre ?",
    content: [
      {
        title: "Informations générales",
        fields: [
          {
            name: "Rôle",
            id: "role",
            type: "select",
            localSelectContent: [
              "ROLE_BO",
              "ROLE_USER",
              "ROLE_ADMIN",
              "ROLE_CA",
            ],
          },
          { name: "Prénom", id: "prenom", disabled: true },
          { name: "Nom", id: "nom", disabled: true },
          { name: "E-mail", id: "email", disabled: true },
          { name: "Login", id: "id", disabled: true },
        ],
      },
    ],
  },
  offreCallCenter: {
    content: [
      {
        title: "Informations immeuble",
        fields: [
          { name: "Référence de la résidence", id: "codeImmeuble" },
          { name: "Nom de la résidence", id: "nomProgramme" },
          { name: "Adresse", id: "adresse1" },
          { name: "Ville", id: "ville" },
          { name: "Code postal", id: "codePostal" },
          { name: "Accroche", id: "accroche" },
          { name: "Type de standing", id: "standing" },
          { name: "Nombre d'étages", id: "etage" },
          { name: "Eau chaude collective", id: "eauChaudeCollective" },
          { name: "Informations complémentaires", id: "descriptifResidence" },
          { name: "Métro", id: "stationMetro" },
          { name: "Bus", id: "stationBus" },
        ],
      },
      {
        title: "Informations appartement",
        fields: [
          { name: "Surface", id: "surface", suffixe: "m²" },
          { name: "Étages", id: "etage" },
          { name: "Nombre de pièces", id: "nbPieces" },
          { name: "Nombre de chambres", id: "nbChambres" },
          { name: "Nombre de SDB", id: "nbSdb" },
          { name: "Nombre de salle d'eau", id: "nbSdeau" },
          { name: "Nombre de WC", id: "nbWc" },
          { name: "Duplex", id: "duplex" },
          { name: "Jardin", id: "jardin" },
          { name: "Terrasse", id: "terrasse" },
          { name: "Balcon", id: "balcon" },
          { name: "Cheminée", id: "cheminee" },
        ],
      },
      {
        title: "Renseignements financier",
        fields: [
          { name: "Montant du loyer", id: "loyerHc", suffixe: "€" },
          { name: "Montant des charges", id: "charges", suffixe: "€" },
          { name: "Total du loyer", id: "loyerCc", suffixe: "€" },
          { name: "Montant des honoraires", id: "honoraires", suffixe: "€" },
          { name: "Disponibilité", id: "dateDisponibilite", type: "date" },
        ],
      },
      {
        title: "Visites",
        fields: [
          { name: "Référence du bien", id: "codeAnnonce" },
          { name: "Code bâtiment", id: "codeBatiment" },
          { name: "Descriptif", id: "descriptifBien", html: true },
        ],
      },
      {
        title: "Négociateur",
        fields: [
          { name: "Nom", id: "nomNegociateur" },
          { name: "Email", id: "emailNegociateur" },
          { name: "Téléphone", id: "telephoneNegociateur" },
        ],
      },
      {
        title: "Information parking et cave",
        fields: [
          { name: "Parking", id: "nbParkings" },
          { name: "Cave", id: "nbCaves" },
        ],
      },
    ],
  },
};

const interpretContent = (val, name, category, isAccess, type, suffixe, id) => {
  if (val === true || (type === "boolean" && val)) {
    return "Oui";
  } else if (val === false || (type === "boolean" && !val)) {
    return "Non";
  } else if (!val && category === "residences" && !isAccess) {
    return `Aucun(e) ${name?.toLowerCase()} n'est rattaché à cette résidence`;
  } else if (type === "date") {
    return isNaN(Date.parse(val))
      ? "Aucune date définie"
      : new Date(val).toLocaleDateString("fr");
  } else if (val === undefined) {
    if (category === "pdf") return "?";
    return "Non renseigné";
  } else if (id === "noteFin") {
    let result = "";
    switch (val) {
      case "N10":
        result = "Client transféré au responsable des visites";
        break;
      case "N20":
        result = "Numéro du chargé de visite communiqué";
        break;
      case "N30":
        result = "Client non intéressé";
        break;
      case "N40":
        result = "Client hors cible (hors critère d'éligibilité)";
        break;
      case "N50":
        result = "Client à recontacter";
        break;
      case "N99":
        result = "Autres motifs";
        break;

      default:
        result = "Information manquante";
        break;
    }
    return result;
  } else {
    return `${val}${
      suffixe
        ? type === "number" &&
          (parseFloat(val) % 1 === 0 || parseInt(val) === 0)
          ? ".00" + suffixe
          : " " + suffixe
        : ""
    }`;
  }
};

export { detailsStructure, interpretContent };
