import React, { useContext, useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import CustomTable from "../../Components/CustomTable";
import Search from "@material-ui/icons/Search";
import { useOktaAuth } from "@okta/okta-react";
import { AppConfigContext } from "../../context";
import { useHistory } from "react-router-dom";

const Agencies = ({ match }) => {
  const history = useHistory();

  const appConfig = useContext(AppConfigContext);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  // const [sortBy, setSortBy] = useState(null);
  // const [isDesc, setIsDesc] = useState(false);

  const { authState } = useOktaAuth();

  const breadcrumbPath = {
    path: [{ name: "Accueil", href: "/" }],
    currentElement: "Agences",
  };

  const formatData = (result) => {
    if (!result.length) return [];
    return result.map((agence) => {
      let output = { ...agence };
      output.email = <a href={`mailto: ${output.email}`}>{output.email}</a>;
      return output;
    });
  };

  useEffect(() => {
    async function fetchData() {
      // WARNING : IF LAG, use /agences paginated endpoint
      const response = await fetch(
        `${appConfig.BACKEND_API_URL}/v1/secured/api/agences/list`,
        {
          headers: {
            Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => res);
      let formattedData = formatData(response);
      setTotalElements(response.totalElements);

      setData(formattedData);
    }

    fetchData();
  }, [authState.accessToken, appConfig.BACKEND_API_URL]);

  // useEffect(() => {
  //   if (data.length) {
  //     fetchWithParams();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [page, size, sortBy, isDesc]);

  // const fetchWithParams = async (filterAgency) => {
  //   const response = await fetch(
  //     `${
  //       appConfig.BACKEND_API_URL
  //     }/v1/secured/agences/list?page=${page}&size=${size}${
  //       searchTerm.length ? "&t=" + searchTerm : ""
  //     }${isDesc === true ? "&isDesc=true" : "&isDesc=false"}${
  //       sortBy ? "&sortBy=" + sortBy : ""
  //     }`,
  //     {
  //       headers: {
  //         Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
  //       },
  //     }
  //   )
  //     .then((res) => res.json())
  //     .then((res) => res);
  //   let formattedData = formatData(response);

  //   // setTotalPages(response.totalPages);
  //   setTotalElements(response.totalElements);
  //   setData(formattedData);
  // };

  const columns = [
    {
      title: "Ref. SI Com.",
      field: "refSiCommercial", // field is the "key" in the data
      width: "2%",
      filtering: false,
    },
    {
      title: "Siret",
      field: "siret",
      filtering: false,
    },
    {
      title: "Agence",
      field: "libelle",
      filtering: false,
    },
    {
      title: "Ville",
      field: "ville",
      filtering: false,
    },
    {
      title: "Email",
      field: "email",
      filtering: false,
    },
    {
      title: "Téléphone",
      field: "phone",
      filtering: false,
    },
  ];

  const actions = [
    {
      icon: Search,
      tooltip: "Voir les détails de l'agence",
      onClick: (_e, rowData) =>
        history.push(`/details/agences/${rowData.id}/false`),
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1">Agences</Typography>
        <CustomBreadcrumb breadcrumbPath={breadcrumbPath} />
        <CustomTable
          title="Liste des agences"
          columns={columns}
          data={data}
          actions={actions}
          size={size}
          setSize={setSize}
          page={page}
          setPage={setPage}
          totalElements={totalElements}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          noSearch={true}
          showSearch={true}
          // setSortBy={setSortBy}
          // setIsDesc={setIsDesc}
          filter={false}
          autoFocus={true}
        />
      </Grid>
    </Grid>
  );
};
export default Agencies;
