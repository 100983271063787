import React from "react";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { IconButton, Tooltip } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import Badge from "@material-ui/core/Badge";
import Menu from "@material-ui/core/Menu";
import SettingsIcon from "@material-ui/icons/Settings";
// import NotificationsIcon from "@material-ui/icons/Notifications";
import AccountCircle from "@material-ui/icons/AccountCircle";
// import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import MoreIcon from "@material-ui/icons/MoreVert";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useOktaAuth } from "@okta/okta-react";

const useStyles = makeStyles((theme) =>
  createStyles({
    toolbarContainer: {
      flex: 1,
      display: "flex",
      justifyContent: "space-between",
      // paddingTop: 15,
    },
    // appbar: {
    //   backgroundColor: "rgb(41, 49, 66) !important",
    //   color: "white",
    //   boxShadow: "none !important",
    //   borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
    //   [theme.breakpoints.up("md")]: {
    //     height: "65px !important",
    //   },
    //   zIndex: theme.zIndex.drawer + 1,
    // },
    appBar: {
      backgroundColor: theme.palette.secondary + " !important",
      color: "white",
      zIndex: theme.zIndex.drawer + 1,
      borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
      [theme.breakpoints.up("md")]: {
        height: "65px !important",
      },
    },

    toolbar: {
      // backgroundColor: theme.palette.white.main,
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    grow: {
      flexGrow: 1,
    },
    button: {
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(1),
      // color: theme.palette.white.main,
      // fontWeight: "bold",
      textTransform: "inherit",
      fontWeight: 500,
      fontSize: "1rem",
    },

    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    icon: {
      color: theme.palette.text.default,
    },
  })
);

const TopMenu = ({ setOpenDrawer, openDrawer, infos }) => {
  const classes = useStyles();
  const { oktaAuth, authState } = useOktaAuth();
  const theme = useTheme();
  const isWide = useMediaQuery(theme.breakpoints.up("md"));
  let themeColor = localStorage.getItem("themeColor");
  const menuId = "primary-search-account-menu";
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleColorChange = () => {
    let themeColor = localStorage.getItem("themeColor");
    localStorage.setItem(
      "themeColor",
      themeColor === "nature" ? "white" : "nature"
    );
    window.location.reload();
  };

  const handleDisconnect = () => {
    oktaAuth.signOut();
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>{infos?.name}</MenuItem>
      <MenuItem onClick={handleMenuClose}>
        {infos?.id} | {infos?.role}
      </MenuItem>
    </Menu>
  );
  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => handleColorChange()}>
        <IconButton aria-label="show testapi">
          <Badge>
            <InvertColorsIcon />
          </Badge>
        </IconButton>
        <p>Changer de mode de couleur</p>
      </MenuItem>
      {/* <MenuItem>
        <IconButton
          aria-label="show testapi"
          color="inherit"
          component={Link}
          to={"/testapi"}
        >
          <Badge color="primary">
            <SettingsIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem> */}
      {/* <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="primary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}
      <MenuItem onClick={handleDisconnect}>
        <IconButton color="inherit">
          <ExitToAppIcon />
        </IconButton>
        <p>Déconnexion</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  if (!authState || !authState.isAuthenticated) return null;

  return (
    <AppBar position="fixed" color="secondary" className={classes.appBar}>
      <Toolbar>
        <div className={classes.toolbarContainer}>
          {isWide ? (
            // <Button
            //   component={Link}
            //   to="/"
            //   className={classes.button}
            //   style={{
            //     marginTop: "-5px",
            //     backgroundColor: themeColor === "nature" ? "white" : "inherit",
            //   }}
            // >
            <div component={Link} to="/" className={classes.button}>
              <img
                src={"/logo_dark.png"}
                // width={210}
                height={64}
                className="App-logo"
                alt="logo"
                style={{
                  backgroundColor:
                    themeColor === "nature" ? "white" : "inherit",
                }}
              />
            </div>
          ) : (
            // </Button>
            <IconButton
              edge="start"
              // className={classes.menuButton}
              // color="secondary"
              aria-label="open drawer"
              onClick={() => setOpenDrawer(!openDrawer)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <div className={classes.grow} />

          <div className={classes.sectionDesktop}>
            <Tooltip title="Changer de mode de couleur">
              <IconButton aria-label="Change color" onClick={handleColorChange}>
                <Badge className={classes.icon}>
                  <InvertColorsIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <IconButton
              aria-label="show testapi"
              component={Link}
              to={"/testapi"}
            >
              <Badge className={classes.icon}>
                <SettingsIcon />
              </Badge>
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
            >
              <AccountCircle className={classes.icon} />
            </IconButton>
            <IconButton aria-label="déconnexion" onClick={handleDisconnect}>
              <Tooltip title="Déconnexion">
                <Badge className={classes.icon}>
                  <ExitToAppIcon />
                </Badge>
              </Tooltip>
            </IconButton>
            {/* <IconButton aria-label="show 17 new notifications">
              <Badge badgeContent={17} className={classes.icon}>
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
            >
              <MoreIcon />
            </IconButton>
          </div>
        </div>
      </Toolbar>
      {renderMobileMenu}
      {renderMenu}
    </AppBar>
  );
};

export default TopMenu;
