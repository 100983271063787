import React, { useContext, useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import CustomTable from "../../Components/CustomTable";
import Search from "@material-ui/icons/Search";
import Edit from "@material-ui/icons/Edit";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { AppConfigContext } from "../../context";

const Commerciaux = () => {
  const appConfig = useContext(AppConfigContext);
  const { authState } = useOktaAuth();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");

  const breadcrumbPath = {
    path: [{ name: "Accueil", href: "/" }],
    currentElement: "Commerciaux",
  };

  const formatData = (result) => {
    if (!result.length) return [];
    return result.map((agence) => {
      let output = { ...agence };
      output.email = <a href={`mailto: ${output.email}`}>{output.email}</a>;
      return output;
    });
  };

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        `${appConfig.BACKEND_API_URL}/v1/secured/api/attachesCommerciaux`,
        {
          headers: {
            Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => res);
      let formattedData = formatData(response);

      setTotalElements(response.totalElements);

      setData(formattedData);
    }

    fetchData();
  }, [
    authState.accessToken.accessToken,
    authState.accessToken.tokenType,
    appConfig.BACKEND_API_URL,
  ]);

  const columns = [
    {
      title: "Code",
      field: "id", // field is the "key" in the data
      width: "8%",
      filtering: false,
    },
    {
      title: "Gestionnaire",
      field: "libelle",
      filtering: false,
    },
    {
      title: "Email",
      field: "email",
      filtering: false,
    },
    {
      title: "Téléphone",
      field: "telephone",
      filtering: false,
    },
  ];

  const actions = [
    {
      icon: Edit,
      tooltip: "Modifier l'attaché commercial",
      onClick: (_event, rowData) =>
        history.push(`/details/attachesCommerciaux/${rowData.id}/true`),
    },
    {
      icon: Search,
      tooltip: "Voir les détails de l'attaché commercial",
      onClick: (_event, rowData) =>
        history.push(`/details/attachesCommerciaux/${rowData.id}/false`),
    },
    {
      icon: DeleteOutline,
      tooltip: "Suuprimer l'attaché commercial",
      onClick: (_event, rowData) =>
        history.push(`/details/attachesCommerciaux/${rowData.id}/false/true`),
    },
    {
      icon: PictureAsPdfIcon,
      tooltip: "Télécharger le PDF du négociateurs",
      onClick: async (_event, rowData) => {
        await fetch(
          `${appConfig.BACKEND_API_URL}/v1/secured/api/extraction/lotsBy?codeGest=${rowData.id}`,
          {
            headers: {
              Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
              "Content-Type": "blob",
              responseType: "arraybuffer",
            },
          }
        )
          .then((res) => res.blob())
          .then((res) => {
            const url = URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `lots-par-attache-${rowData.libelle}.pdf`
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          })
          .catch(console.error);
      },
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1">Commerciaux</Typography>
        <CustomBreadcrumb breadcrumbPath={breadcrumbPath} />
        <CustomTable
          title="Liste des attachés commerciaux"
          columns={columns}
          data={data}
          actions={actions}
          size={size}
          setSize={setSize}
          page={page}
          setPage={setPage}
          totalElements={totalElements}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          filter={false}
          noSearch={true}
          autoFocus={true}
        />
      </Grid>
    </Grid>
  );
};
export default Commerciaux;
