const editionLinks = [
  {
    name: "Extraction du suivi de commercialisation",
    href: "/v1/secured/api/extraction/suiviCommercial",
    filename: "suivi_commercial.xlsx",
  },
  {
    name: "Extraction de la totalité des lots",
    href: "/v1/secured/api/extraction/lots",
    filename: "lots.xlsx",
  },
  {
    name: "Extraction des lots disponibles en PDF",
    href: "/v1/secured/api/extraction/lotsBy",
    filename: "lots-par-negociateur.pdf",
  },
];

export default editionLinks;
