import React, { useContext, useEffect, useState, useCallback } from "react";

import Grid from "@material-ui/core/Grid";
import { Typography, Chip } from "@material-ui/core";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import CustomTable from "../../Components/CustomTable";
import Search from "@material-ui/icons/Search";
import DetailsMenu from "./DetailsMenu";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { AppConfigContext } from "../../context";
import debounce from "lodash.debounce";
import TabIcon from "@material-ui/icons/Tab";

const Propositions = () => {
  const appConfig = useContext(AppConfigContext);
  const { authState } = useOktaAuth();

  const history = useHistory();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("datePropositionBail");
  const [isDesc, setIsDesc] = useState(true);

  const breadcrumbPath = {
    path: [{ name: "Accueil", href: "/" }],
    currentElement: "Propositions",
  };

  const getPresentBo = (presentBo, modifAltaix) => {
    if (presentBo) {
      const color = modifAltaix ? "orange" : "#5db991";
      return (
        <Chip
          label="Oui"
          variant="outlined"
          style={{ color: color, borderColor: color }}
          color="primary"
        />
      );
    } else {
      const color = modifAltaix ? "orange" : "#dc3545";
      return (
        <Chip
          label="Non"
          variant="outlined"
          style={{ color: color, borderColor: color }}
          color="primary"
        />
      );
    }
  };

  const formatData = (result) => {
    if (!result.content) return [];
    return result.content.map((proposition) => {
      let output = { ...proposition };

      output.presentBo = getPresentBo(output.presentBo, output.modifAltaix);

      output.datePropositionBail = new Date(
        output.datePropositionBail
      ).toLocaleDateString("fr-FR");
      return output;
    });
  };

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        `${appConfig.BACKEND_API_URL}/v1/secured/api/propositions`,
        {
          headers: {
            Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => res);
      let formattedData = formatData(response);

      setTotalElements(response.totalElements);

      setData(formattedData);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appConfig.BACKEND_API_URL,
    authState.accessToken.accessToken,
    authState.accessToken.tokenType,
  ]);

  useEffect(() => {
    // if (data.length) {
    fetchWithParams();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, searchTerm, sortBy, isDesc]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchWithParams = useCallback(
    debounce(async () => {
      const response = await fetch(
        `${
          appConfig.BACKEND_API_URL
        }/v1/secured/api/propositions?page=${page}&size=${size}${
          searchTerm.length ? "&t=" + searchTerm : ""
        }${isDesc === true ? "&isDesc=true" : "&isDesc=false"}${
          sortBy ? "&sortBy=" + sortBy : ""
        }`,
        {
          headers: {
            Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => res);

      let formattedData = formatData(response);

      setTotalElements(response.totalElements);
      setData(formattedData);
    }, 500),
    [searchTerm, isDesc, sortBy, size, page]
  );

  const columns = [
    {
      title: "Code El",
      field: "codeImmeuble", // field is the "key" in the data
      width: "8%",
      filtering: false,
      customFilterAndSearch: () => {
        return true;
      },
    },
    {
      title: "Code lot",
      field: "codeLot",
      filtering: false,
      customFilterAndSearch: () => {
        return true;
      },
    },
    {
      title: "N° offre",
      field: "numeroOffre",
      filtering: false,
      customFilterAndSearch: () => {
        return true;
      },
    },
    {
      title: "Immeuble",
      field: "libelleResidence",
      filtering: false,
      customFilterAndSearch: () => {
        return true;
      },
    },
    {
      title: "Client",
      field: "nomProprietaire",
      filtering: false,
      customFilterAndSearch: () => {
        return true;
      },
    },
    {
      title: "Typologie",
      field: "typeBien",
      filtering: false,
      customFilterAndSearch: () => {
        return true;
      },
    },
    {
      title: "Adresse",
      field: "adresse1",
      filtering: false,
      customFilterAndSearch: () => {
        return true;
      },
    },
    {
      title: "Code postal",
      field: "codePostal",
      filtering: false,
      customFilterAndSearch: () => {
        return true;
      },
    },
    {
      title: "Ville",
      field: "ville",
      filtering: false,
      customFilterAndSearch: () => {
        return true;
      },
    },
    {
      title: "Présent BO",
      field: "presentBo",
      filtering: false,
      customFilterAndSearch: () => {
        return true;
      },
    },
    {
      title: "Attaché commercial",
      field: "libelleAttacheCommercial",
      filtering: false,
      customFilterAndSearch: () => {
        return true;
      },
    },
    {
      title: "Date création",
      field: "datePropositionBail",
      filtering: false,
      customFilterAndSearch: () => {
        return true;
      },
    },
  ];

  const actions = [
    {
      icon: Search,
      tooltip: "Voir les détails de la proposition",
      onClick: (_event, rowData) =>
        history.push(
          `/details/propositions/${rowData.codeImmeuble}-${rowData.codeLot}`
        ),
    },
    {
      icon: TabIcon,
      tooltip: "Ouvrir la proposition dans un nouvel onglet",
      onClick: (_event, rowData) =>
        window.open(
          `/details/propositions/${rowData.codeImmeuble}-${rowData.codeLot}`,
          "_blank"
        ),
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="h1">Propositions</Typography>
          </Grid>
          <Grid item xs={4}>
            <DetailsMenu />
          </Grid>
        </Grid>
        <CustomBreadcrumb breadcrumbPath={breadcrumbPath} />
        <CustomTable
          title="Liste des propositions de bails"
          columns={columns}
          data={data}
          actions={actions}
          size={size}
          setSize={setSize}
          page={page}
          setPage={setPage}
          totalElements={totalElements}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setSortBy={setSortBy}
          setIsDesc={setIsDesc}
          filter={false}
          autoFocus={true}
          actionLeft={true}
        />
      </Grid>
    </Grid>
  );
};
export default Propositions;
