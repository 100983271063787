import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Link,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useOktaAuth } from "@okta/okta-react";
import { useContext, useState } from "react";
import { AppConfigContext } from "../../context";
import useStyles from "./style";
import editionLinks from "./utils";

const Editions = () => {
  const classes = useStyles();
  const appConfig = useContext(AppConfigContext);
  const { authState } = useOktaAuth();
  const [loading, setLoading] = useState([]);

  const handleClick = async (el, index) => {
    const loadingValue = [];
    loadingValue.push(index);

    setLoading(loadingValue);
    await fetch(`${appConfig.BACKEND_API_URL}${el.href}`, {
      headers: {
        Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
        "Content-Type": "blob",
        responseType: "arraybuffer",
      },
    })
      .then((res) => res.blob())
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", el.filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoading(loading.filter((i) => i !== index));
      })
      .catch(console.error);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card className={classes.editions} elevation={6}>
          <CardHeader classes={{ title: classes.title }} title="Éditions" />
          <CardContent>
            <Grid container>
              <Grid item xs={6}>
                {editionLinks.map((el, index) => (
                  <div key={el.name}>
                    <Link
                      onClick={() => {
                        if (el.href && !loading.includes(index))
                          handleClick(el, index);
                      }}
                      className={classes.link}
                    >
                      <Button
                        disabled={loading.includes(index)}
                        style={{ borderRadius: 30 }}
                        color="primary"
                      >
                        {el.name}
                        {loading.includes(index) ? (
                          <CircularProgress
                            size={20}
                            style={{ marginLeft: 20 }}
                          />
                        ) : (
                          <ArrowForwardIcon className={classes.icon} />
                        )}
                      </Button>
                    </Link>
                    <br />
                  </div>
                ))}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Editions;
