// import React, { useContext } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import RUG from "react-upload-gallery";
import "react-upload-gallery/dist/style.css";
import { useSnackbar } from "notistack";
import { useOktaAuth } from "@okta/okta-react";
// import { AppConfigContext } from "../../context";

const style = {
  position: "absolute",
  top: "100vh",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const PopupAddImg = ({
  addImg,
  setAddImg,
  initialState,
  codeImmeuble,
  codeLot,
  appConfig,
  setTriggerSave,
  setContent,
  content,
}) => {
  // const appConfig = useContext(AppConfigContext);
  const { enqueueSnackbar } = useSnackbar();
  const { authState } = useOktaAuth();

  const handleEndChange = () => {
    setAddImg(false);
    setTriggerSave(true);
  };

  return (
    <Modal
      open={addImg}
      onClose={() => setAddImg(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ overflowY: "auto", height: "100%" }}
    >
      <Box sx={style} textAlign="center">
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Gestion des images
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Vous pouvez ajouter, supprimer et réorganiser les photos du lot avec
          un glissé / déposé
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          L'image de couverture sera la première photo
        </Typography>
        <RUG
          sorting={true}
          onConfirmDelete={(currentImage, images) => {
            console.log("about to delete", currentImage, images);
            return window.confirm(
              "Êtes-vous certain de vouloir supprimer cette image ?"
            );
          }}
          onDeleted={(image) => {
            console.log("delete this image :", image);
            fetch(
              `${appConfig.BACKEND_API_URL}/v1/secured/api/lots/photo?i=${image.name}`,
              {
                method: "DELETE",
                headers: {
                  Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
                },
              }
            )
              .then(async (res) => {
                if (res.ok) {
                  // setTriggerSave();
                  enqueueSnackbar(
                    "Suppression de l'image effectuée avec succès",
                    {
                      variant: "success",
                    }
                  );
                  // getDetails();
                } else {
                  let nextRes = await res.json();
                  throw nextRes;
                }
              })
              .catch((err) => {
                let errArray = [];
                for (const prop in err) {
                  if (prop !== "timestamp") errArray.push(err[prop]);
                }
                enqueueSnackbar(
                  `Les données non pas pu être supprimées correctement : ${errArray.map(
                    (el) => el
                  )}`,
                  {
                    variant: "error",
                  }
                );
              });
          }}
          onSortEnd={(images, { oldIndex, newIndex }) => {
            let newOrder = images.map((el) =>
              el.name.includes("/lot/")
                ? el.name
                : `/lot/${content.codeImmeuble}/${content.codeLot}/${el.name}`
            );
            setContent({ ...content, photos: newOrder });
          }}
          // action="http://example.com/upload"
          customRequest={({
            uid,
            file,
            data, // blob
            send,
            action,
            headers,
            onProgress,
            onSuccess,
            onError,
          }) => {
            // const response = { url: "http://example.com/upload" };
            let photoContent = new FormData();
            photoContent.append("photo", file);

            let response = fetch(
              `${appConfig.BACKEND_API_URL}/v1/secured/api/lots/photo/${codeImmeuble}-${codeLot}`,
              {
                method: "PUT",
                headers: {
                  Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
                },
                body: photoContent,
              }
            )
              .then(async (res) => res.json())
              .then((res) => {
                if (res.imgPath) {
                  const nextPhotos = content.photos || [];
                  nextPhotos.push(res.imgPath);
                  setContent({ ...content, photos: nextPhotos });
                  enqueueSnackbar("Image ajoutée avec succès", {
                    variant: "success",
                  });
                } else if (res.msg) {
                  enqueueSnackbar(`Impossible d'ajouter les photos: ${res.msg}`, 
                  {
                    variant: "error",
                  });
                }
              })
              .catch((err) => {
                console.log("err", err);
                let errArray = [];
                for (const prop in err) {
                  if (prop !== "timestamp") errArray.push(err[prop]);
                }
                enqueueSnackbar(
                  `Les données non pas pu être sauvegardées : ${errArray.map(
                    (el) => el
                  )}`,
                  {
                    variant: "error",
                  }
                );
              });

            onProgress(uid, response);
            onSuccess(uid, response);
            onError(uid, {
              action,
              // status,
              response,
            });

            return {
              abort() {
                // for cancels the request
              },
            };
          }}
          source={(response) => response.url}
          initialState={initialState}
          accept={["jpg", "jpeg", "png", "gif"]}
          rules={{
            limit: 15,
          }}
          onChange={(images) => {
            console.log("change ?", images);
          }}
          onWarning={(type, rules) => {
            switch (type) {
              case "accept":
                enqueueSnackbar(
                  `Vous ne pouvez ajouter que les extensions suivantes : ${rules.accept.join(
                    ", "
                  )}`,
                  {
                    variant: "warning",
                  }
                );
                break;
              case "limit":
                enqueueSnackbar(
                  `Vous ne pouvez ajouter que ${rules.limit} photos`,
                  {
                    variant: "warning",
                  }
                );
                break;

              case "maxWidth":
              case "maxHeight":
                console.log(
                  "Dimensions < ",
                  `${rules.width.max}x${rules.height.max}`
                );
                break;
              default:
            }
          }}
        />
        <Button onClick={handleEndChange}>Terminer les modifications</Button>
      </Box>
      {/* yo */}
    </Modal>
  );
};

export default PopupAddImg;
