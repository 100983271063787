const prospectFields = [
  {
    name: "Origine",
    id: "origine",
    mandatory: true,
    type: "callcenter-select",
    selectContent: [
      { name: "Divers", id: "divers" },
      { name: "Explorimo", id: "explorimo" },
      { name: "esset-pm.com", id: "icade.fr" },
      { name: "Internet", id: "internet" },
      { name: "Notoriété", id: "notoriete" },
      { name: "Panneau", id: "panneau" },
      { name: "Paru Vendu", id: "paru_vendu" },
      { name: "SeLoger", id: "se_loger" },
      { name: "Top Annonce", id: "top_annonce" },
      { name: "À Vendre À Louer", id: "A Vendre à Louer" },
      { name: "Le Bon Coin", id: "Le Bon Coin" },
      { name: "Cadre de Vie", id: "Cadre de Vie" },
      { name: "Superimmo", id: "Superimmo" },
      { name: "Logic-Immo", id: "Logic-Immo" },
      { name: "Bien 'Ici", id: "Bien 'Ici" },
      { name: "Foncia.com", id: "Foncia.com" },
      { name: "Centre d'appel", id: "centreAppel" },
    ],
  },
  {
    name: "Civilité",
    id: "civilite",
    mandatory: true,
    type: "callcenter-select",
    selectContent: [
      { name: "M.", id: "Monsieur" },
      { name: "Mme", id: "Madame" },
      { name: "Mlle", id: "Mademoiselle" },
    ],
  },
  {
    name: "Nom",
    id: "nom",
    mandatory: true,
    type: "text",
  },
  {
    name: "Prénom",
    id: "prenom",
    mandatory: true,
    type: "text",
  },
  {
    name: "Adresse",
    id: "adresse",
    mandatory: false,
    type: "text",
  },
  {
    name: "Code Postal",
    id: "codePostal",
    mandatory: false,
    type: "text",
  },
  {
    name: "Ville",
    id: "ville",
    mandatory: false,
    type: "text",
  },
  {
    name: "Téléphone",
    id: "telephone",
    mandatory: true,
    type: "text",
  },
  {
    name: "Email",
    id: "email",
    mandatory: false,
    type: "text",
  },
  {
    name: "idContact",
    id: "idContact",
    type: "hidden",
  },
];

const detailsLot = [
  {
    name: "Clôture appel",
    id: "noteFin",
    type: "callcenter-select",
    selectContent: [
      { name: "Client transféré au responsable des visites", id: "N10" },
      { name: "Numéro du chargé de visite communiqué", id: "N20" },
      { name: "Client non intéressé", id: "N30" },
      { name: "Client hors cible (hors critère d'éligibilité)", id: "N40" },
      { name: "Client à recontacter", id: "N50" },
      { name: "Autres motifs", id: "N99" },
    ],
  },
  {
    name: "Commentaire appel",
    id: "commAppel",
    mandatory: false,
    type: "multiline",
  },
];

const typeOfSearchValues = [
  { name: "Nom", id: "nom" },
  { name: "Prénom", id: "prenom" },
  { name: "Téléphone", id: "telephone" },
];

const particularite = [
  { title: "Balcon", id: "balcon" },
  { title: "Cheminée", id: "cheminee" },
  { title: "Duplex", id: "duplex" },
  { title: "Cave", id: "cave" },
  { title: "Jardin", id: "jardin" },
  { title: "Terrasse", id: "terrasse" },
  { title: "Stationnement", id: "stationnement" },
];

export { prospectFields, typeOfSearchValues, particularite, detailsLot };
