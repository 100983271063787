import React, { useContext, useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import { Typography, Chip, Button, TextField } from "@material-ui/core";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import CustomTable from "../../Components/CustomTable";
import Search from "@material-ui/icons/Search";
import Edit from "@material-ui/icons/Edit";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import AddElementDialog from "../../Components/AddElementDialog.js";
import { useOktaAuth } from "@okta/okta-react";
import { AppConfigContext } from "../../context";
import Autocomplete from "@material-ui/lab/Autocomplete";

const Residences = () => {
  const appConfig = useContext(AppConfigContext);
  const { authState } = useOktaAuth();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [agency, setAgency] = useState(null);
  const [listAgencies, setListAgencies] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("publicationSite");
  const [isDesc, setIsDesc] = useState(true);
  const [open, setOpen] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const breadcrumbPath = {
    path: [{ name: "Accueil", href: "/" }],
    currentElement: "Résidences",
  };

  useEffect(() => {
    setSearchTerm("");
  }, [breadcrumbPath.currentElement]);

  const formatData = (result) => {
    if (!result.content) return [];
    return result.content.map((residence) => {
      let output = {};
      output.codeImmeuble = residence.codeImmeuble;
      output.libelle = residence.libelle;
      output.adresse1 = `${residence.adresse1} ${residence.codePostal} ${residence.ville} `;
      output.agence = residence.agence ? residence.agence : "N/C";
      output.nbLots = residence.nbLots;
      output.publicationSite = residence.publicationSite ? (
        <Chip label="Oui" variant="outlined" color="primary" />
      ) : (
        <Chip
          label="Non"
          variant="outlined"
          style={{ color: "red", borderColor: "red" }}
        />
      );
      output.dateUpdate = new Date(residence.dateUpdate).toLocaleString(
        "fr-FR"
      );
      return output;
    });
  };

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        `${appConfig.BACKEND_API_URL}/v1/secured/api/residences`,
        {
          headers: {
            Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => res);
      let formattedData = formatData(response);

      setTotalElements(response.totalElements);

      setData(formattedData);
    }

    async function fetchAgenciesList() {
      const response = await fetch(
        `${appConfig.BACKEND_API_URL}/v1/secured/api/agences/all`,
        {
          headers: {
            Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => res);
      setListAgencies(response);
    }

    fetchData();
    fetchAgenciesList();
  }, [
    authState.accessToken.accessToken,
    authState.accessToken.tokenType,
    appConfig.BACKEND_API_URL,
  ]);

  useEffect(() => {
    console.log("here");
    fetchWithParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, searchTerm, sortBy, isDesc, agency]);

  const fetchWithParams = async (filterAgency) => {
    const response = await fetch(
      `${
        appConfig.BACKEND_API_URL
      }/v1/secured/api/residences?page=${page}&size=${size}${
        "&t=" + searchTerm
      }${isDesc === true ? "&isDesc=true" : "&isDesc=false"}${
        agency !== null && agency !== "" ? "&a=" + agency : ""
      }${sortBy ? "&sortBy=" + sortBy : ""}`,
      {
        headers: {
          Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => res);
    let formattedData = formatData(response);

    setTotalElements(response.totalElements);
    setData(formattedData);
  };

  const columns = [
    {
      title: "Code immeuble",
      field: "codeImmeuble", // field is the "key" in the data
      width: "8%",
      filtering: false,
      align: "right",
    },
    {
      title: "Libellé",
      field: "libelle",
      filtering: false,
    },
    {
      title: "Adresse",
      field: "adresse1",
      filtering: false,
    },
    {
      title: "Agence",
      field: "agence",
      filterComponent: (props) => {
        if (!listAgencies.length) return <></>;
        return (
          <Autocomplete
            native
            value={agency}
            options={listAgencies}
            getOptionLabel={(option) => {
              if (typeof option === "object") {
                return `${option.libelle} - ${option.refSiCommercial}`;
              } else if (typeof option === "number") {
                const elementToDisplay = listAgencies.find(
                  (agence) => agence.id === option
                );
                return `${elementToDisplay?.libelle} - ${elementToDisplay?.refSiCommercial}`;
              }
              if (option) return `${option.libelle} - ${option.refSiCommercial}`;
              else return "";
            }}
            getOptionSelected={(option, value) => {
              return option.id === value;
            }}
            fullWidth
            onChange={(_e, value, reason) => {
              if (reason === "select-option") setAgency(value.id);
              else if (reason === "clear") setAgency("");
            }}
            renderInput={(params) => (
              <TextField
                placeholder="Filtrer via agence"
                {...params}
                variant="outlined"
              />
            )}
          />
        );
      },
      width: "150px",
    },
    {
      title: "Publiée",
      field: "publicationSite",
      filtering: false,
    },
  ];

  const actions = [
    {
      icon: Edit,
      tooltip: "Modifier la résidence",
      onClick: (_event, rowData) =>
        history.push(`/details/residences/${rowData.codeImmeuble}/true`),
    },
    {
      icon: Search,
      tooltip: "Voir les détails de la résidence",
      onClick: (_event, rowData) =>
        history.push(`/details/residences/${rowData.codeImmeuble}/false`),
    },
    {
      icon: DeleteOutline,
      tooltip: "Supprimer la résidence",
      onClick: (_event, rowData) =>
        history.push(`/details/residences/${rowData.codeImmeuble}/false/true`),
    },
  ];

  return (
    <Grid container>
      <AddElementDialog open={open} setOpen={setOpen} category="residences" />
      <Grid item xs={12}>
        <Typography variant="h1">Résidences</Typography>
        <Grid container justifyContent="space-between">
          <CustomBreadcrumb breadcrumbPath={breadcrumbPath} />{" "}
          <Button
            style={{ margin: 5 }}
            variant="outlined"
            size="small"
            endIcon={<AddIcon />}
            onClick={() => setOpen(true)}
            color="primary"
          >
            Ajouter une résidence
          </Button>
        </Grid>
        <CustomTable
          title="Liste des résidences"
          columns={columns}
          data={data}
          actions={actions}
          size={size}
          setSize={setSize}
          page={page}
          setPage={setPage}
          totalElements={totalElements}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setSortBy={setSortBy}
          setIsDesc={setIsDesc}
          filter={true}
          autoFocus={true}
        />
      </Grid>
    </Grid>
  );
};
export default Residences;
