import React, { useContext, useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import CustomTable from "../../Components/CustomTable";
// import Search from "@material-ui/icons/Search";
// import Edit from "@material-ui/icons/Edit";
// import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { useOktaAuth } from "@okta/okta-react";
import { AppConfigContext } from "../../context";

const Guardiens = () => {
  const appConfig = useContext(AppConfigContext);
  const { authState } = useOktaAuth();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState(null);
  const [isDesc, setIsDesc] = useState(false);

  const breadcrumbPath = {
    path: [{ name: "Accueil", href: "/" }],
    currentElement: "Gardiens",
  };

  const formatData = (result) => {
    if (!result.length) return [];
    return result.map((gardiens) => {
      let output = { ...gardiens };
      output.nom = `${output.titre} ${output.nom}`;
      output.email = (
        <a style={{ color: "white" }} href={`mailto: ${output.email}`}>
          {output.email}
        </a>
      );
      return output;
    });
  };

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        `${appConfig.BACKEND_API_URL}/v1/secured/api/gardiens`,
        {
          headers: {
            Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => res);
      let formattedData = formatData(response);
      setTotalElements(response.totalElements);

      setData(formattedData);
    }
    fetchData();
  }, [
    authState.accessToken.accessToken,
    authState.accessToken.tokenType,
    appConfig.BACKEND_API_URL,
  ]);

  useEffect(() => {
    if (data.length) {
      fetchWithParams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, searchTerm, sortBy, isDesc]);

  const fetchWithParams = async (filterAgency) => {
    const response = await fetch(
      `${
        appConfig.BACKEND_API_URL
      }/v1/secured/gardiens?page=${page}&size=${size}${
        searchTerm.length && "&t=" + searchTerm
      }${isDesc === true ? "&isDesc=true" : "&isDesc=false"}${
        sortBy ? "&sortBy=" + sortBy : ""
      }`,
      {
        headers: {
          Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("response with params:", res);
        return res;
      });
    let formattedData = formatData(response);

    setTotalElements(response.totalElements);
    setData(formattedData);
  };

  const columns = [
    {
      title: "Code",
      field: "id", // field is the "key" in the data
      width: "8%",
      filtering: false,
    },
    {
      title: "Immeuble",
      field: "libelleImmeuble",
      filtering: false,
    },
    {
      title: "Code d'immeuble",
      field: "codeImmeuble",
      filtering: false,
    },
    {
      title: "Gardien",
      field: "nom",
      filtering: false,
    },
    {
      title: "Téléphone bureau",
      field: "telephoneBureau",
      filtering: false,
    },
    {
      title: "Téléphone mobile",
      field: "telephonePortable",
      filtering: false,
    },
  ];

  const actions = [
    // {
    //   icon: Edit,
    //   tooltip: "Modifier le gardien",
    //   onClick: (event, rowData) => alert("You saved " + rowData.name),
    // },
    // {
    //   icon: Search,
    //   tooltip: "Voir les détails du gardien",
    //   onClick: (event, rowData) => alert("You saved " + rowData.name),
    // },
    // {
    //   icon: DeleteOutline,
    //   tooltip: "Supprimer le gardien",
    //   onClick: (event, rowData) => alert("You saved " + rowData.name),
    // },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1">Gardiens</Typography>
        <CustomBreadcrumb breadcrumbPath={breadcrumbPath} />
        <CustomTable
          title="Liste des gardiens"
          columns={columns}
          data={data}
          actions={actions}
          size={size}
          setSize={setSize}
          page={page}
          setPage={setPage}
          totalElements={totalElements}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setSortBy={setSortBy}
          setIsDesc={setIsDesc}
          filter={false}
          noSearch={true}
          showSearch={true}
          autoFocus={true}
        />
      </Grid>
    </Grid>
  );
};

export default Guardiens;
