import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import frLocale from "date-fns/locale/fr";
import format from "date-fns/format";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { metro } from "./metro";
import { useContext } from "react";
import { AppConfigContext } from "../../context";

// const contentChange = (prevState, nextState) => {
//   const isSame = prevState.value === nextState.value;
//   return isSame;
// };

class FrLocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "dd/MM/yyyy", { locale: this.locale });
  }
}

const ModifyField = ({
  type,
  value,
  id,
  url,
  handleChange,
  handleAccessChange,
  isAccess = false,
  escalierIndex,
  localSelectContent,
  label,
  mandatory,
  size,
  error,
}) => {
  const { authState } = useOktaAuth();
  const appConfig = useContext(AppConfigContext);

  const [localvalue, setLocalValue] = useState(value || "");
  const [selectContent, setSelectContent] = useState([]);

  const useStyles = makeStyles((theme) => ({
    root: {
      color: theme.palette.text.default,
      "& polygon, & path, & line": {
        stroke: `${theme.palette.text.default} !important`,
      },
      "& span": {
        color: `${theme.palette.text.default} !important`,
      },
    },
  }));
  const classes = useStyles();

  const handleLocalChange = (e, id, variant) => {
    switch (variant) {
      case "checkbox":
        setLocalValue(e.target.checked);
        break;
      case "date":
        // Do not change value if the date is not a valid date
        if (isNaN(Date.parse(e))) return;
        // Trick to handle local timezone due to date picker taking interest in time..
        const correctDate = new Date(new Date(e).setHours(10, 56));

        setLocalValue(e);
        handleChange(correctDate.toISOString(), id, variant);
        break;
      case "html":
      case "autocomplete":
        setLocalValue(e);
        break;

      default:
        setLocalValue(e.target.value);
        break;
    }
    if (isAccess) {
      handleAccessChange(e, id, escalierIndex, variant);
    } else {
      if (variant !== "date") {
        handleChange(e, id, variant);
      }
    }
  };

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    const doFetch = async () => {
      await fetch(`${appConfig.BACKEND_API_URL}/${url}`, {
        method: "GET",
        headers: {
          Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("Something went wrong :", res.json());
          }
        })
        .then((res) => {
          setSelectContent(res);
        })
        .catch((err) => console.log("error fetching", err));
    };
    if (url) {
      doFetch();
    } else if (localSelectContent) {
      setSelectContent(localSelectContent);
    }
  }, [
    appConfig.BACKEND_API_URL,
    authState.accessToken.accessToken,
    authState.accessToken.tokenType,
    localSelectContent,
    url,
  ]);

  const renderField = () => {
    switch (type) {
      case "number":
        return (
          <TextField
            fullWidth
            onChange={(e) => handleLocalChange(e, id)}
            type="number"
            value={value}
            variant="outlined"
          />
        );
      case "boolean":
        return (
          <Checkbox
            error={error}
            onChange={(e) => handleLocalChange(e, id, "checkbox")}
            checked={!!localvalue}
          />
        );
      case "select":
        return (
          <FormControl fullWidth error={error}>
            <Select
              labelId={`select-${id}`}
              value={localvalue}
              onChange={(e) => handleLocalChange(e, id)}
            >
              <MenuItem value="">Aucun(e)</MenuItem>
              {selectContent.map((el) => {
                return (
                  <MenuItem
                    value={localSelectContent ? el : el.id}
                    key={localSelectContent ? el : el.id}
                  >
                    {localSelectContent && el}
                    {el.code && el.code + " - "}
                    {!localSelectContent &&
                      (el.libelle || el.titre + " " + el.nom)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      case "callcenter-select":
        // Set the value from id instead than from libelle
        if (localSelectContent) {
          selectContent.forEach((el) => {
            if (el.libelle === localvalue) setLocalValue(el.id);
          });
        }
        return (
          <FormControl fullWidth required={mandatory} error={error}>
            <InputLabel id={`select-${id}`}>{label}</InputLabel>
            <Select
              labelId={`select-${id}`}
              value={localvalue}
              onChange={(e) => handleLocalChange(e, id)}
              size={size ? size : "medium"}
            >
              <MenuItem value="">Aucun(e)</MenuItem>
              {selectContent.map((el) => {
                return (
                  <MenuItem value={el.id} key={el.id}>
                    {el.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      case "date":
        return (
          <MuiPickersUtilsProvider utils={FrLocalizedUtils} locale={frLocale}>
            <KeyboardDatePicker
              fullWidth
              // disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              value={localvalue || null}
              onChange={(e, a, z) => handleLocalChange(e, id, "date", a, z)}
              // onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        );
      case "htmlEditor":
        return (
          <ReactQuill
            theme="snow"
            className={classes.root}
            value={localvalue || ""}
            onChange={(e) => {
              handleLocalChange(e, id, "html");
            }}
          />
        );
      case "autocomplete":
        if (!selectContent.length && id !== "nomMetro") return <></>;
        return (
          <Autocomplete
            id="autocomplete"
            options={id === "nomMetro" ? metro : selectContent}
            value={localvalue || ""}
            loadingText="Chargement en cours..."
            clearText="Effacer"
            closeText="Fermer"
            openText="Ouvrir"
            noOptionsText="Aucune option"
            getOptionLabel={(option) => {
              if (id === "nomMetro") return option;
              else if (id === "idGardien") {
                if (typeof option === "object") {
                  return `${option.titre} ${option.nom}`;
                } else if (
                  (typeof option === "string" && option.length) ||
                  typeof option === "number"
                ) {
                  const elementToDisplay = selectContent.find(
                    (gardien) => gardien.id === option.toString()
                  );
                  return `${elementToDisplay?.titre} - ${elementToDisplay?.nom}`;
                } else {
                  console.error("weird type autocomplete");
                }
              } else if (id === "idAgence") {
                if (typeof option === "object") {
                  return `${option.libelle} - ${option.refSiCommercial}`;
                } else if (typeof option === "number") {
                  const elementToDisplay = selectContent.find(
                    (agence) => agence.id === option
                  );
                  return `${elementToDisplay?.libelle} - ${elementToDisplay?.refSiCommercial}`;
                } else {
                  console.error("weird type autocomplete");
                }
              }
            }}
            getOptionSelected={(option, value) => {
              if (id === "nomMetro") return option === value;
              else if (id === "idAgence" || id === "idGardien") {
                return option.id === value;
              }
            }}
            style={{ width: "100%" }}
            onChange={(_e, value, reason) => {
              const valueToReturn =
                id === "nomMetro" ? value : parseInt(value?.id) || "";
              if (reason === "select-option") {
                handleLocalChange(valueToReturn, id, "autocomplete");
              } else if (reason === "clear") handleLocalChange("", id, "date");
            }}
            renderInput={(params) => (
              <TextField {...params} multiline variant="outlined" />
            )}
          />
        );
      case "hidden":
        return <></>;

      default:
        return (
          <TextField
            fullWidth
            required={mandatory}
            type="text"
            label={label}
            multiline={type === "multiline" ? true : false}
            rows={type === "multiline" ? 3 : 1}
            onChange={(e) => handleLocalChange(e, id)}
            value={localvalue}
            variant="outlined"
            size={size ? size : "medium"}
            error={error}
          />
        );
    }
  };
  return renderField();
};

// export default ModifyField;
// export default React.memo(ModifyField, contentChange);
export default React.memo(ModifyField);
