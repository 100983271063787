import Button from "@mui/material/Button";
import { detailsStructure } from "../DetailsContent/utils";
import { Card, CardContent } from "@material-ui/core";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";

const DetailsButton = ({
  category,
  setModify,
  modify,
  handleSave,
  setOpen,
  updateOnBo,
  publierSite,
  publicationSite,
  loading,
  content,
}) => {
  const history = useHistory();
  const handleClick = (id, option) => {
    switch (id) {
      case "back":
        history.push(
          `/${category}${
            category === "lots" && !content.topLoue ? "/dispos" : ""
          }`
        );
        break;
      case "modify":
        if (!modify) {
          setModify(true);
        } else {
          handleSave();
        }
        break;
      case "publish":
      case "unpublish":
        publierSite();
        break;
      case "delete":
        setOpen(true);
        break;
      case "majOnBo":
        updateOnBo();
        break;

      default:
        break;
    }
  };

  const shouldHide = (option) => {
    // Handle display of publicationSite => OUI & NON
    if (category !== "lots" && category !== "residences") return false;
    else if (option.id === "publish" && publicationSite) return true;
    else if (option.id === "unpublish" && !publicationSite) return true;
    else return false;
  };

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Box sx={{ "& button": { m: 0.5 } }}>
          {detailsStructure[category].options.map((option) => {
            if (loading)
              return (
                <Skeleton
                  sx={{ bgcolor: "grey.500" }}
                  width={125}
                  key={option.id}
                  height={50}
                  variant="text"
                  style={{
                    display: "inline-flex",
                    marginRight: 20,
                  }}
                />
              );
            // if (category === "lots")
            else if (option.id === "print")
              return (
                <Button
                  variant="contained"
                  size="small"
                  key={option.id}
                  endIcon={option.icon}
                  style={{
                    backgroundColor: option.color ? option.color : "none",
                    display: shouldHide(option) ? "none" : "inline-flex",
                  }}
                  onClick={() =>
                    history.push(
                      `/pdf/${content.codeImmeuble}-${content.codeLot}`
                    )
                  }
                >
                  Télécharger
                </Button>
                //   )}
                // </PDFDownloadLink>
              );

            return (
              <Button
                variant="contained"
                size="small"
                key={option.id}
                endIcon={option.icon}
                onClick={() => handleClick(option.id, option)}
                style={{
                  backgroundColor: option.color ? option.color : "none",
                  display: shouldHide(option) ? "none" : "inline-flex",
                }}
              >
                {option.id === "modify" && modify ? "Sauvegarder" : option.name}
              </Button>
            );
          })}
        </Box>
      </CardContent>
    </Card>
  );
};

DetailsButton.propTypes = {
  category: PropTypes.string.isRequired,
  modify: PropTypes.bool.isRequired,
  setModify: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  updateOnBo: PropTypes.func.isRequired,
  publierSite: PropTypes.func.isRequired,
  publicationSite: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
};

export default DetailsButton;
