import PropTypes from "prop-types";
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core";

const listOfRoom = [
  { id: "chambre", name: "Chambre" },
  { id: "studio", name: "Studio" },
  { id: "f2", name: "Deux pièces" },
  { id: "f3", name: "Trois pièces" },
  { id: "f4", name: "Quatre pièces" },
  { id: "f5", name: "Cinq pièces" },
];

function PiecesCheckbox({ nbrPieces, handleChange }) {
  return (
    <FormControl component="fieldset" style={{ marginTop: 10 }}>
      <FormLabel component="legend">Nombre de pièces :</FormLabel>
      <FormGroup row>
        {listOfRoom.map((room) => (
          <FormControlLabel
            key={room.id}
            control={
              <Checkbox
                checked={nbrPieces[room.id]}
                onChange={handleChange}
                name={room.id}
              />
            }
            label={room.name}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}

PiecesCheckbox.propTypes = {
  nbrPieces: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default PiecesCheckbox;
