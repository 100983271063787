import React, { useEffect, useMemo, useState } from "react";
import { Route, Switch, useHistory, withRouter } from "react-router-dom";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import TopMenu from "./Components/TopMenu";
import Home from "./Views/Home";
import LeftDrawer from "./Components/LeftDrawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import Residences from "./Views/Residences";
import Lots from "./Views/Lots";
import Agencies from "./Views/Agencies";
import Negociateurs from "./Views/Negociateurs";
import Commerciaux from "./Views/Commerciaux";
import Contacts from "./Views/Contacts";
import Guardiens from "./Views/Guardiens";
import Propositions from "./Views/Propositions";
import TestApi from "./Views/TestApi";
import Users from "./Views/Users";
import Details from "./Views/Details";
import AlerteEmail from "./Views/AlerteEmail";
import CallCenter from "./Views/CallCenter";
import Login from "./Login";

import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { AppConfigContext } from "./context";
import PdfLot from "./Views/PdfLot";

export const ROLE_ADMIN = "ROLE_ADMIN";
export const ROLE_BO = "ROLE_BO";
export const ROLE_CA = "ROLE_CA";
export const ROLE_USER = "ROLE_USER";

const NO_ACCESS = "Votre rôle ne permet pas d'afficher ce contenu";

const hasRoleCA = (role) => [ROLE_ADMIN, ROLE_CA].includes(role);
const hasRoleBO = (role) => [ROLE_ADMIN, ROLE_BO].includes(role);
const hasRoleADMIN = (role) => role && role === ROLE_ADMIN;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: { display: "flex" },
    container: {
      backgroundColor: "rgb(28, 37, 49)",
      display: "flex",
      height: "100%",
      overflow: "hidden",
      width: "100%",
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.default,
      height: "100%",
      padding: theme.spacing(3),
    },
  })
);

function App() {
  const appConfig = React.useContext(AppConfigContext);

  const classes = useStyles();

  const [openDrawer, setOpenDrawer] = useState(false);

  const history = useHistory();
  const [infos, setInfos] = useState(null);

  const customAuthHandler = () => {
    history.push("/login");
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const oktaAuthConfig = appConfig
    ? {
        issuer: appConfig.OKTA_ISSUER,
        clientId: appConfig.OKTA_CLIENT_ID,
        redirectUri: window.location.origin + "/login/callback",
      }
    : null;

  const oktaAuth = useMemo(
    function () {
      return oktaAuthConfig ? new OktaAuth(oktaAuthConfig) : null;
    },
    [oktaAuthConfig]
  );
  // const oktaAuth = oktaAuthConfig ? new OktaAuth(oktaAuthConfig) : null;

  useEffect(() => {
    const fetchProfile = async () => {
      let accessToken = await oktaAuth.getAccessToken();
      if (!accessToken) return;
      let tokenType = "Bearer";
      await fetch(
        `${appConfig.BACKEND_API_URL}/v1/secured/api/users/currentAuth`,
        {
          headers: {
            Authorization: `${tokenType} ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setInfos({
            name: `${res.prenom} ${res.nom}`,
            id: res.id,
            role: res.role,
          });
        })
        .catch((err) => console.log("err", err));
    };

    if (oktaAuth && !infos) fetchProfile();
  }, [oktaAuth, appConfig, infos]);

  if (!appConfig) return null;

  const oktaSignInConfig = {
    baseUrl: appConfig.OKTA_URL,
    clientId: appConfig.OKTA_CLIENT_ID,
    redirectUri: window.location.origin + "/login/callback",
    logo: `${appConfig.IMG_URL}/base/logo-header.png`,
    language: "fr",
    authParams: {
      issuer: appConfig.OKTA_ISSUER,
      scopes: ["openid", "profile", "email"],
    },
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <div className={classes.root}>
        <CssBaseline />
        <TopMenu
          setOpenDrawer={setOpenDrawer}
          openDrawer={openDrawer}
          infos={infos}
        />
        <LeftDrawer
          setOpenDrawer={setOpenDrawer}
          openDrawer={openDrawer}
          infos={infos}
        />

        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            <Route
              path="/login"
              render={() => <Login config={oktaSignInConfig} />}
            />
            <Route path="/login/callback" component={LoginCallback} />

            <SecureRoute
              path="/"
              exact={true}
              render={() =>
                hasRoleBO(infos?.role) ? <Home infos={infos} /> : NO_ACCESS
              }
            />
            <SecureRoute
              path="/residences"
              exact={true}
              render={() =>
                hasRoleBO(infos?.role) ? <Residences /> : NO_ACCESS
              }
            />
            <SecureRoute
              path="/lots/:available?"
              render={(props) =>
                hasRoleBO(infos?.role) ? <Lots {...props} /> : NO_ACCESS
              }
            />
            <SecureRoute
              path="/pdf/:id"
              render={(props) =>
                hasRoleBO(infos?.role) ? <PdfLot {...props} /> : NO_ACCESS
              }
            />
            <SecureRoute
              path="/agences"
              render={() => (hasRoleBO(infos?.role) ? <Agencies /> : NO_ACCESS)}
            />
            <SecureRoute
              path="/negociateurs"
              render={() =>
                hasRoleBO(infos?.role) ? <Negociateurs /> : NO_ACCESS
              }
            />
            <SecureRoute
              path="/attachesCommerciaux"
              render={() =>
                hasRoleBO(infos?.role) ? <Commerciaux /> : NO_ACCESS
              }
            />
            <SecureRoute
              path="/alerteEmail"
              render={() =>
                hasRoleBO(infos?.role) ? <AlerteEmail /> : NO_ACCESS
              }
            />
            <SecureRoute
              path="/contacts"
              render={() => (hasRoleBO(infos?.role) ? <Contacts /> : NO_ACCESS)}
            />
            <SecureRoute
              path="/gardiens"
              render={() =>
                hasRoleBO(infos?.role) ? <Guardiens /> : NO_ACCESS
              }
            />
            <SecureRoute
              path="/propositions"
              render={() =>
                hasRoleBO(infos?.role) ? <Propositions /> : NO_ACCESS
              }
            />

            <SecureRoute
              path="/details/:category/:id/:activateModification?/:deleteItem?"
              render={(props) =>
                hasRoleBO(infos?.role) ? <Details {...props} /> : NO_ACCESS
              }
            />

            <SecureRoute
              path="/users"
              render={() => (hasRoleADMIN(infos?.role) ? <Users /> : NO_ACCESS)}
            />
            <SecureRoute
              path="/testapi"
              render={() =>
                hasRoleADMIN(infos?.role) ? <TestApi /> : NO_ACCESS
              }
            />

            <SecureRoute
              path="/centre-appel"
              render={() =>
                hasRoleCA(infos?.role) ? <CallCenter /> : NO_ACCESS
              }
            />
          </Switch>
        </main>
      </div>
    </Security>
  );
}

export default withRouter(App);
