import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  breadcrumbs: {
    color: theme.palette.white.main,
    fontWeight: "500",
    fontSize: "0.875rem",
    lineHeight: "1.57",
  },
  separator: {
    color: theme.palette.gray.main,
  },
}));

export default useStyles;
