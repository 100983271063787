import React, { useContext, useState } from "react";
import {
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  TextField,
  Divider,
  FormControl,
  MenuItem,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ListIcon from "@material-ui/icons/List";
import EditIcon from "@material-ui/icons/Edit";
import PublishIcon from "@material-ui/icons/Publish";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import UserIcon from "@material-ui/icons/VerifiedUser";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import DescriptionIcon from "@material-ui/icons/Description";
import { useOktaAuth } from "@okta/okta-react";
import { AppConfigContext } from "../../context";

const useStyles = makeStyles({
  textField: {
    margin: "20px 0px 20px 20px",
    width: "600px",
  },
  radio: {
    margin: "20px 0px 20px 20px",
    width: "600px",
  },
  table: {
    minWidth: 650,
  },
  formControl: {
    minWidth: 120,
  },
});

function fetchData(uri, accessToken, setData, setError) {
  fetch(uri, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
    .then((res) => res.json())
    .then(
      (result) => {
        setData(result);
        setError(null);
      },
      (error) => {
        setError(error);
      }
    );
}

function postData(url, accessToken, data, setError) {

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }
  };

  if(data) 
    options.body = data;

  fetch(url, options)
    .then((res) => res.json())
    .then(
      (result) => {
        console.log("POST result", result);
        setError(null);
      },
      (error) => {
        setError(error);
      }
    );
}

function deleteData(url, accessToken, setError) {
  let options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  fetch(url, options)
    .then((res) => res.json())
    .then(
      (result) => {
        console.log("DELETE result", result);
        setError(null);
      },
      (error) => {
        setError(error);
      }
    );
}

function uploadPhoto(url, accessToken, file, setData) {
  const formData = new FormData();
  formData.append('photo', file);

  fetch(url,
    {
      method: 'PUT',
      body: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Accept': 'application/json'
      }
    })
    .then(res => res.json())
    .then(json => setData(json))
    .catch(console.error);
}

function deletePhoto(url, accessToken, setData) {

  fetch(url,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(res => {
      if(res.ok) setData({})
    })
    .catch(console.error);
}

function TestApi() {

  const appConfig = useContext(AppConfigContext);

  const { authState } = useOktaAuth();

  const classes = useStyles();

  const [error, setError] = useState(null);

  const [object, setObject] = useState({});

  const [list, setList] = useState([]);

  const [text, setText] = useState("1034");

  const [dataName, setDataName] = useState("agences");
  const [pageDataName, setPageDataName] = useState("residences");

  const [selectedFile, setSelectedFile] = useState({});

  if (!authState || !authState.isAuthenticated) return null;
  const accessToken = authState.accessToken.accessToken;
  console.log("object", object, "list", list);
  return (
    <Grid container>
      <Grid item xs={12}>
        {error && <h3>Erreur survenue! {error.message}</h3>}

        <Grid item xs={12}>
          <Paper>
            <TextField
              id="outlined-basic"
              label="text"
              variant="outlined"
              className={classes.textField}
              onChange={(e) => setText(e.target.value)}
              value={text}
              multiline={true}
            />

            <FormControl component="fieldset" className={classes.radio}>
              <FormLabel component="legend">Backend URL = {appConfig.BACKEND_API_URL}</FormLabel>
            </FormControl>
          </Paper>
        </Grid>

        <Divider />

        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>API</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Users</TableCell>
                <TableCell>
                  <Tooltip title="Liste">
                    <IconButton
                      aria-label="list"
                      onClick={() =>
                        fetchData(
                          `${appConfig.BACKEND_API_URL}/v1/secured/api/users`,
                          accessToken,
                          setList,
                          setError
                        )
                      }
                    >
                      <ListIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="User authentifié">
                    <IconButton
                      aria-label="auth"
                      onClick={() =>
                        fetchData(
                          `${appConfig.BACKEND_API_URL}/v1/secured/api/users/currentAuth`,
                          accessToken,
                          setObject,
                          setError
                        )
                      }
                    >
                      <UserIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="User detail by id">
                    <IconButton
                      aria-label="detail"
                      onClick={() =>
                        fetchData(
                          `${appConfig.BACKEND_API_URL}/v1/secured/api/users/${text}`,
                          accessToken,
                          setObject,
                          setError
                        )
                      }
                    >
                      <DescriptionIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="createUpdateUser">
                    <IconButton
                      aria-label="createUpdate"
                      onClick={() =>
                        postData(
                          `${appConfig.BACKEND_API_URL}/v1/secured/api/users`,
                          accessToken,
                          text,
                          setError
                        )
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="deleteUser">
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        deleteData(
                          `${appConfig.BACKEND_API_URL}/v1/secured/api/users/${text}`,
                          accessToken,
                          setError
                        )
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="pageDataName-label">
                      pageDataName
                    </InputLabel>
                    <Select
                      labelId="pageDataName-select-label"
                      id="pageDataName-select"
                      value={pageDataName}
                      onChange={(e) => setPageDataName(e.target.value)}
                    >
                      <MenuItem value={"residences"}>Résidences</MenuItem>
                      <MenuItem value={"alerteEmail"}>Alerte Email</MenuItem>
                      <MenuItem value={"contacts"}>Contacts</MenuItem>
                      <MenuItem value={"propositions"}>
                        Propositions de bail
                      </MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <Tooltip title="Liste avec pagination">
                    <IconButton
                      aria-label="list"
                      onClick={() =>
                        fetchData(
                          `${appConfig.BACKEND_API_URL}/v1/secured/api/${pageDataName}`,
                          accessToken,
                          setObject,
                          setError
                        )
                      }
                    >
                      <ListIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Détail">
                    <IconButton
                      aria-label="detail"
                      onClick={() =>
                        fetchData(
                          `${appConfig.BACKEND_API_URL}/v1/secured/api/${pageDataName}/${text}`,
                          accessToken,
                          setObject,
                          setError
                        )
                      }
                    >
                      <DescriptionIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Recherche avec pagination">
                    <IconButton
                      aria-label="search"
                      onClick={() =>
                        fetchData(
                          `${appConfig.BACKEND_API_URL}/v1/secured/api/${pageDataName}`,
                          accessToken,
                          text,
                          setError
                        )
                      }
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="createUpdate">
                    <IconButton
                      aria-label="createUpdate"
                      onClick={() =>
                        postData(
                          `${appConfig.BACKEND_API_URL}/v1/secured/api/${pageDataName}`,
                          accessToken,
                          text,
                          setError
                        )
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="delete">
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        deleteData(
                          `${appConfig.BACKEND_API_URL}/v1/secured/api/${pageDataName}/${text}`,
                          accessToken,
                          setError
                        )
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Lots</TableCell>
                <TableCell>
                  <Tooltip title="Liste Dispo">
                    <IconButton
                      aria-label="list"
                      onClick={() =>
                        fetchData(
                          `${appConfig.BACKEND_API_URL}/v1/secured/api/lots/dispo?page=1&size=25&sortBy=codePostal&isDesc=true`,
                          accessToken,
                          setObject,
                          setError
                        )
                      }
                    >
                      <ListIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Liste Loué">
                    <IconButton
                      aria-label="list"
                      onClick={() =>
                        fetchData(
                          `${appConfig.BACKEND_API_URL}/v1/secured/api/lots/loue`,
                          accessToken,
                          setObject,
                          setError
                        )
                      }
                    >
                      <ListAltIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Détail">
                    <IconButton
                      aria-label="detail"
                      onClick={() =>
                        fetchData(
                          `${appConfig.BACKEND_API_URL}/v1/secured/api/lots/${text}`,
                          accessToken,
                          setObject,
                          setError
                        )
                      }
                    >
                      <DescriptionIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Recherche">
                    <IconButton
                      aria-label="search"
                      onClick={() =>
                        fetchData(
                          `${appConfig.BACKEND_API_URL}/v1/secured/api/lots/dispo?t=${text}`,
                          accessToken,
                          setObject,
                          setError
                        )
                      }
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="createUpdate">
                    <IconButton
                      aria-label="createUpdate"
                      onClick={() =>
                        postData(
                          `${appConfig.BACKEND_API_URL}/v1/secured/api/lots`,
                          accessToken,
                          text,
                          setError
                        )
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="publierSite">
                    <IconButton
                      aria-label="publierSite"
                      onClick={() =>
                        postData(
                          `${appConfig.BACKEND_API_URL}/v1/secured/api/lots/publierSite/${text}`,
                          accessToken,
                          null,
                          setError
                        )
                      }
                    >
                      <PublishIcon />
                    </IconButton>
                  </Tooltip>
                  
                  <Tooltip title="delete">
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        deleteData(
                          `${appConfig.BACKEND_API_URL}/v1/secured/api/lots/${text}`,
                          accessToken,
                          setError
                        )
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="dataName-label">dataName</InputLabel>
                    <Select
                      labelId="dataName-select-label"
                      id="dataName-select"
                      value={dataName}
                      onChange={(e) => setDataName(e.target.value)}
                    >
                      <MenuItem value={"agences"}>Agences</MenuItem>
                      <MenuItem value={"negociateurs"}>Négociateurs</MenuItem>
                      <MenuItem value={"attachesCommerciaux"}>
                        Attachés Commerciaux
                      </MenuItem>
                      <MenuItem value={"gardiens"}>Gardiens</MenuItem>
                      <MenuItem value={"dateImport"}>Date import</MenuItem>
                      <MenuItem value={"offreVille"}>Offre ville</MenuItem>
                      <MenuItem value={"offreDepartement"}>
                        Offre departement
                      </MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <Tooltip title="Liste">
                    <IconButton
                      aria-label="list"
                      onClick={() =>
                        fetchData(
                          `${appConfig.BACKEND_API_URL}/v1/secured/api/${dataName}`,
                          accessToken,
                          setList,
                          setError
                        )
                      }
                    >
                      <ListIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Détail">
                    <IconButton
                      aria-label="detail"
                      onClick={() =>
                        fetchData(
                          `${appConfig.BACKEND_API_URL}/v1/secured/api/${dataName}/${text}`,
                          accessToken,
                          setObject,
                          setError
                        )
                      }
                    >
                      <DescriptionIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="createUpdate">
                    <IconButton
                      aria-label="createUpdate"
                      onClick={() =>
                        postData(
                          `${appConfig.BACKEND_API_URL}/v1/secured/api/${dataName}`,
                          accessToken,
                          text,
                          setError
                        )
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="delete">
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        deleteData(
                          `${appConfig.BACKEND_API_URL}/v1/secured/api/${dataName}/${text}`,
                          accessToken,
                          setError
                        )
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Prospect</FormLabel>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      defaultValue="nom"
                      onChange={(e) => setDataName(e.target.value)}
                    >
                      <FormControlLabel
                        value="nom"
                        control={<Radio />}
                        label="Nom"
                      />
                      <FormControlLabel
                        value="prenom"
                        control={<Radio />}
                        label="Prenom"
                      />
                      <FormControlLabel
                        value="telephone"
                        control={<Radio />}
                        label="Téléphone"
                      />
                    </RadioGroup>
                  </FormControl>
                </TableCell>

                <TableCell>
                  <Tooltip title="Liste prospects">
                    <IconButton
                      aria-label="list"
                      onClick={() =>
                        fetchData(
                          `${appConfig.BACKEND_API_URL}/v1/secured/api/contacts/prospects?f=${dataName}&t=${text}`,
                          accessToken,
                          setList,
                          setError
                        )
                      }
                    >
                      <ListIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Détail prospect">
                    <IconButton
                      aria-label="detail prospect"
                      onClick={() =>
                        fetchData(
                          `${appConfig.BACKEND_API_URL}/v1/secured/api/contacts/prospects/${text}`,
                          accessToken,
                          setObject,
                          setError
                        )
                      }
                    >
                      <DescriptionIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="createUpdate prospect">
                    <IconButton
                      aria-label="createUpdate prospect"
                      onClick={() =>
                        postData(
                          `${appConfig.BACKEND_API_URL}/v1/secured/api/contacts/prospects`,
                          accessToken,
                          text,
                          setError
                        )
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Divider />

        <Grid item xs={12}>
          <Paper>

            <TextField
              id="upload"
              className={classes.textField}
              onChange={event => setSelectedFile(event.target.files[0])}
              type={"file"}
            />

            <Tooltip title="upload photo">
              <IconButton
                aria-label="upload photo"
                onClick={() => uploadPhoto(`${appConfig.BACKEND_API_URL}/v1/secured/api/lots/photo/${text}`, accessToken, selectedFile, setObject)}
              >
                <CloudUploadIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="delete photo">
              <IconButton
                aria-label="delete photo"
                onClick={() => deletePhoto(`${appConfig.BACKEND_API_URL}/v1/secured/api/lots/photo?i=${text}`, accessToken, setObject)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>

            <br />
            {object && object.imgPath && 
            <p>
              <img id="img" src={`https://img.fonciadev.net/esset${object.imgPath}`} alt="test-img" title={object.imgPath} />
              
            </p>
            }
            
          </Paper>


        </Grid>

      </Grid>
    </Grid>
  );
}
export default TestApi;
