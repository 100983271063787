import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { interpretContent } from "../DetailsContent/utils";
import ModifyField from "../ModifyField";
import { Typography } from "@material-ui/core";


const StyledTable = ({ 
  fields, 
  columns, 
  content, 
  category,
  modify,
  handleChange
}) => {

  let rows = [];
  
  function createData(thead, locataire, proprietaire, type, suffix, disabled, idLocataire, idProprietaire) {
    return { thead, locataire, proprietaire, type, suffix, disabled, idLocataire, idProprietaire };
  }
  
  fields.forEach((field) => {
      if (field.name === "Honoraires location")
        rows.push(createData(field.name, content.honoraireRedactionLocation, content.honoraireLocationProprietaire, field.type, field.suffixe, field.disabled, field.id1, field.id2));

      if (field.name === "Honoraires état des lieux")
        rows.push(createData(field.name, content.honoraireEtatDesLieuxLocataire, content.honoraireEtatDesLieuxProprietaire, field.type, field.suffixe, field.disabled, field.id1, field.id2));

      if (field.name === "Total honoraires")
        rows.push(createData(field.name, content.honoraireTotalLocataire ,content.honoraireTotalProprietaire, field.type, field.suffixe, field.disabled, field.id1, field.id2));
  })


  return (
    <TableContainer>
      <Table aria-label="Tableau des honoraires">
        <TableHead>
          <TableRow>
            {columns.map((column) => {
              return (
                <TableCell key={column.id}>
                  <Typography variant="subtitle1">{column.name}</Typography>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {content !== null ? (
            rows.map((row) => (
              <TableRow
                key={row.thead}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell 
                  component="th" 
                  scope="row">
                    <Typography variant="subtitle1">
                      {row.thead}
                    </Typography>
                </TableCell>
                <TableCell 
                  key={row.idLocataire}
                  component="td"
                  scope="row"
                  style={{ width: "30%" }}>
                  {modify && !row.disabled ? (
                        <ModifyField
                          type={row.type}
                          value={content[row.idLocataire]}
                          id={row.idLocataire}
                          handleChange={handleChange}
                        />
                    ) : (
                    <Typography variant="subtitle2">
                      {interpretContent(
                        row.locataire, 
                        row.field,
                        category,
                        false,
                        row.type,
                        row.suffix
                        )}
                    </Typography>
                    )}
                </TableCell>
                <TableCell
                  key={row.idProprietaire}
                  component="td"
                  scope="row"
                  style={{ width: "30%" }}>
                    {modify && !row.disabled ? (
                        <ModifyField
                          type={row.type}
                          value={content[row.idProprietaire]}
                          id={row.idProprietaire}
                          handleChange={handleChange}
                        />
                    ) : (
                    <Typography variant="subtitle2">
                      {interpretContent(
                        row.proprietaire, 
                        row.thead,
                        category,
                        false,
                        row.type,
                        row.suffix
                        )}
                    </Typography>
                    )}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell component="th" scope="row">
                Chargement...
              </TableCell>
            </TableRow>
          )}
          
        </TableBody>
      </Table>
    </TableContainer>
  );
  
};

export default React.memo(StyledTable);
