import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import ChambreCheckbox from "./ChambreCheckbox";
import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Slider,
  TextField,
  Typography,
} from "@material-ui/core";
import { ValueLabelComponent } from "./ValueLabelComponent";
import { Autocomplete } from "@material-ui/lab";
import { particularite } from "./utils";
import CustomSelect from "../../Components/AddElementDialog.js/CustomSelect";
import PiecesCheckbox from "./PiecesCheckbox";
import SearchIcon from "@material-ui/icons/Search";
import CallCenterTable from "./CallCenterTable";
import { useSnackbar } from "notistack";
import { AppConfigContext } from "../../context";

const SearchEngine = ({ listAnnonces, setListAnnonces }) => {
  const appConfig = useContext(AppConfigContext);
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({
    budget: [0, 15000],
    surface: [0, 1000],
    typeLieu: "",
    codeLieu: "",
    lieu: "",
  });

  const [nbrPieces, setNbrPieces] = useState({
    chambre: false,
    studio: false,
    f2: false,
    f3: false,
    f4: false,
    f5: false,
  });

  const [nbrChambres, setNbrChambres] = useState({
    chambre1: false,
    chambre2: false,
    chambre3: false,
    chambre4: false,
    chambre5: false,
  });

  const [particularites, setParticularites] = useState({
    balcon: false,
    cheminee: false,
    duplex: false,
    jardin: false,
    terrasse: false,
    cave: false,
    stationnement: false,
    parking: false,
  });

  const [resetVille, setResetVille] = useState(false);
  const [resetDepartement, setResetDepartement] = useState(false);

  const handlePiecesChange = (event) => {
    setNbrPieces({ ...nbrPieces, [event.target.name]: event.target.checked });
  };

  const handleChambresChange = (event) => {
    setNbrChambres({
      ...nbrChambres,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSliderChange = (newValue, name) => {
    setState({ ...state, [name]: newValue });
  };

  const handleParticularites = (values) => {
    let nextPart = { ...particularites };
    // set all to false
    for (var i in nextPart) nextPart[i] = false;

    for (const partProp in nextPart) {
      values.forEach(function (val) {
        if (val.id === partProp) nextPart[partProp] = true;
      });
    }

    setParticularites(nextPart);
  };

  const handleLocationAutoCompleteChange = (value, id, name) => {
    let nextState = { ...state };
    if (id === "villes") {
      nextState.typeLieu = "v";
      setResetDepartement(true);
    } else if (id === "departements") {
      nextState.typeLieu = "d";
      setResetVille(true);
    }

    if (!value.length) nextState.typeLieu = "";

    nextState.codeLieu = value;
    nextState.lieu = name || "";
    nextState[id] = value;
    setState(nextState);
  };

  const handleSearch = () => {
    const mergedState = {
      ...state,
      ...nbrPieces,
      ...nbrChambres,
      ...particularites,
    };

    mergedState.budgetMin = mergedState.budget[0];
    mergedState.budgetMax = mergedState.budget[1];

    mergedState.surfaceMin = mergedState.surface[0];
    mergedState.surfaceMax = mergedState.surface[1];

    delete mergedState.budget;
    delete mergedState.surface;

    for (const stateProp in mergedState) {
      if (mergedState[stateProp] === "" || mergedState[stateProp] === false)
        delete mergedState[stateProp];
    }

    fetchSearch(mergedState);
  };

  const fetchSearch = (data) => {
    fetch(`${appConfig.BACKEND_API_URL}/v1/public/api/offreCentreAppel`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        if (!res.length) {
          enqueueSnackbar(
            "Aucun résultat ne correspond à ces critères de recherche",
            {
              variant: "warning",
            }
          );
        }
        if (res.status) return;
        setListAnnonces(res);
      })
      .catch((err) => console.log("err post", err));
  };

  return (
    <div>
      {listAnnonces.length ? (
        <CallCenterTable listAnnonces={listAnnonces} />
      ) : (
        <>
          <PiecesCheckbox
            nbrPieces={nbrPieces}
            handleChange={handlePiecesChange}
          />
          <br />
          <ChambreCheckbox
            nbrChambres={nbrChambres}
            handleChange={handleChambresChange}
          />
          <Typography variant="body1">Caractéristiques</Typography>
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormGroup row style={{ marginTop: 40 }}>
                <FormControlLabel
                  control={
                    <Slider
                      min={0}
                      step={5}
                      max={500}
                      name="surface"
                      value={state.surface}
                      onChange={(_e, newValue) =>
                        handleSliderChange(newValue, "surface")
                      }
                      getAriaLabel={(index) => "surface"}
                      ValueLabelComponent={ValueLabelComponent}
                      // This line triggers a React warning due to Transition  + Strict mode being enabled
                      // Ignore this warning
                      valueLabelDisplay="on"
                      style={{ marginLeft: "5%" }}
                    />
                  }
                  name="surface"
                  labelPlacement="start"
                  label="Surface"
                  style={{ marginLeft: 0, width: "95%" }}
                  color="secondary"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup row style={{ marginTop: 40 }}>
                <FormControlLabel
                  control={
                    <Slider
                      min={0}
                      step={50}
                      max={15000}
                      name="budget"
                      value={state.budget}
                      onChange={(_e, newValue) =>
                        handleSliderChange(newValue, "budget")
                      }
                      aria-labelledby="range-slider"
                      ValueLabelComponent={ValueLabelComponent}
                      // This line triggers a React warning due to Transition  + Strict mode being enabled
                      // Ignore this warning
                      valueLabelDisplay="on"
                      style={{ marginLeft: "5%" }}
                    />
                  }
                  name="budget"
                  labelPlacement="start"
                  label="Budget"
                  style={{ marginLeft: 0, width: "95%" }}
                  color="secondary"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={7} style={{ marginTop: 20 }}>
              <Autocomplete
                multiple={true}
                id="particularite"
                options={particularite}
                getOptionLabel={(option) => option.title}
                disableCloseOnSelect
                // defaultValue={[top100Films[13]]}
                onChange={(_e, values) => handleParticularites(values)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Particularités"
                    // placeholder="Particularités"
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{ marginTop: 20, paddingRight: 10 }}
            >
              <CustomSelect
                url={`${appConfig.BACKEND_API_URL}/v1/secured/api/ca/offreVille`}
                id="villes"
                handleAutoCompleteChange={handleLocationAutoCompleteChange}
                label="Villes"
                // multiple={true}
                loadingText="Chargement en cours..."
                resetSearch={resetVille}
                setResetSearch={setResetVille}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{ marginTop: 20, paddingLeft: 10 }}
            >
              <CustomSelect
                url={`${appConfig.BACKEND_API_URL}/v1/secured/api/ca/offreDepartement`}
                id="departements"
                handleAutoCompleteChange={handleLocationAutoCompleteChange}
                label="Départements"
                // multiple={true}
                loadingText="Chargement en cours..."
                resetSearch={resetDepartement}
                setResetSearch={setResetDepartement}
              />
            </Grid>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={handleSearch}
              startIcon={<SearchIcon />}
              style={{ marginLeft: 20, height: 54 }}
            >
              Rechercher les biens
            </Button>
          </Grid>
        </>
      )}
    </div>
  );
};

SearchEngine.propTypes = {
  listAnnonces: PropTypes.array.isRequired,
  setListAnnonces: PropTypes.func.isRequired,
};

export default SearchEngine;
