import React, { useContext, useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import { Typography, Chip } from "@material-ui/core";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import CustomTable from "../../Components/CustomTable";
import Search from "@material-ui/icons/Search";
import Edit from "@material-ui/icons/Edit";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { useOktaAuth } from "@okta/okta-react";
import { useHistory } from "react-router-dom";
import { AppConfigContext } from "../../context";

const Contacts = () => {
  const appConfig = useContext(AppConfigContext);
  const history = useHistory();
  const { authState } = useOktaAuth();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState(null);
  const [isDesc, setIsDesc] = useState(false);

  const breadcrumbPath = {
    path: [{ name: "Accueil", href: "/" }],
    currentElement: "Contacts",
  };

  const getType = (nbPieces) => {
    if (nbPieces === 1) {
      return "studio";
    } else if (nbPieces > 0) {
      return `F${nbPieces}`;
    } else {
      return "";
    }
  };

  const formatData = (result) => {
    if (!result.content) return [];
    return result.content.map((contact) => {
      let output = { ...contact };

      output.typologie = `${
        contact.type === "a" ? "Appartement " + getType(contact.nbPieces) : ""
      }`;
      output.tagEnvoi = contact.tagEnvoi ? (
        <Chip label="Envoyé" variant="outlined" color="primary" />
      ) : (
        <Chip
          label="Non envoyé"
          variant="outlined"
          style={{ color: "red", borderColor: "red" }}
        />
      );

      output.dateCreation = output.dateCreation
        ? new Date(contact.dateCreation).toLocaleString("fr-FR")
        : "";
      return output;
    });
  };

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        `${appConfig.BACKEND_API_URL}/v1/secured/api/contacts`,
        {
          headers: {
            Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => res);
      let formattedData = formatData(response);

      setTotalElements(response.totalElements);

      setData(formattedData);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.length) {
      fetchWithParams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, searchTerm, sortBy, isDesc]);

  const fetchWithParams = async () => {
    const response = await fetch(
      `${
        appConfig.BACKEND_API_URL
      }/v1/secured/api/contacts?page=${page}&size=${size}${
        searchTerm.length ? "&t=" + searchTerm : ""
      }${isDesc === true ? "&isDesc=true" : "&isDesc=false"}${
        sortBy ? "&sortBy=" + sortBy : ""
      }`,
      {
        headers: {
          Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => res);
    let formattedData = formatData(response);

    // setTotalPages(response.totalPages);
    setTotalElements(response.totalElements);
    setData(formattedData);
  };

  const columns = [
    {
      title: "Date",
      field: "dateCreation", // field is the "key" in the data
      width: "8%",
      filtering: false,
    },
    {
      title: "Origine",
      field: "origine",
      filtering: false,
    },
    {
      title: "Nom",
      field: "nom",
      filtering: false,
    },
    {
      title: "Typologie",
      field: "typeBien",
      filtering: false,
    },
    {
      title: "Téléphone",
      field: "telephone",
      filtering: false,
    },
    {
      title: "Code annonce",
      field: "codeAnnonce",
      filtering: false,
    },
    {
      title: "Résidence",
      field: "residence",
      filtering: false,
    },
    {
      title: "Statut",
      field: "tagEnvoi",
      filtering: false,
    },
    {
      title: "Négociateur",
      field: "negociateur",
      filtering: false,
    },
  ];

  const actions = [
    {
      icon: Edit,
      tooltip: "Modifier le contact",
      onClick: (_event, rowData) =>
        history.push(
          `/details/contacts/${rowData.idContact}-${rowData.idAnnonce}/true`
        ),
    },
    {
      icon: Search,
      tooltip: "Voir les détails du contact",
      onClick: (_event, rowData) =>
        history.push(
          `/details/contacts/${rowData.idContact}-${rowData.idAnnonce}/false`
        ),
    },
    {
      icon: DeleteOutline,
      tooltip: "Supprimer le contact",
      onClick: (_event, rowData) =>
        history.push(
          `/details/contacts/${rowData.idContact}-${rowData.idAnnonce}/false/true`
        ),
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1">Contacts</Typography>
        <CustomBreadcrumb breadcrumbPath={breadcrumbPath} />
        <CustomTable
          title="Liste des contacts"
          columns={columns}
          data={data}
          actions={actions}
          size={size}
          setSize={setSize}
          page={page}
          setPage={setPage}
          totalElements={totalElements}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setSortBy={setSortBy}
          setIsDesc={setIsDesc}
          filter={false}
          autoFocus={true}
        />
      </Grid>
    </Grid>
  );
};
export default Contacts;
