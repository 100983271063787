import React, { useState, useEffect } from "react";

const AppConfigContext = React.createContext();

function AppConfigProvider(props) {
  const [appConfig, setAppConfig] = useState(null);

  useEffect(() => {
    fetch("/conf.json", { headers: { Accept: "application/json" } })
      .then((res) => res.json())
      .then((json) => setAppConfig(json))
      .catch((err) => console.log(err));
  }, []);

  return (
    <AppConfigContext.Provider value={appConfig}>
      {props.children}
    </AppConfigContext.Provider>
  );
}

export { AppConfigProvider, AppConfigContext };
