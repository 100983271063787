import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import {
  Button,
  TextField,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Card,
  CardContent,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  List,
  Tooltip,
  Divider,
} from "@material-ui/core";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import useStyles from "./style";
import { detailsLot, prospectFields, typeOfSearchValues } from "./utils";
import ModifyField from "../../Components/ModifyField";
import CustomSelect from "../../Components/AddElementDialog.js/CustomSelect";
// import { FormControl, FormLabel, RadioGroup } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import SearchEngine from "./SearchEngine";
import SearchIcon from "@material-ui/icons/Search";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DetailsContent from "../../Components/DetailsContent";
import CustomDialog from "../../Components/CustomDialog";
import { useSnackbar } from "notistack";
import CallCenterTable from "./CallCenterTable";
import { AppConfigContext } from "../../context";

const initValue = () =>
  prospectFields.map((field) => {
    return { id: field.id, value: "" };
  });

function CallCenter() {
  const appConfig = useContext(AppConfigContext);
  const { enqueueSnackbar } = useSnackbar();
  const { authState } = useOktaAuth();
  const classes = useStyles();
  const breadcrumbPath = {
    path: [{ name: "Accueil", href: "/" }],
    currentElement: "Centre appel",
  };

  const [typeOfSearch, setTypeOfSearch] = useState("nom");
  const [idContact, setIdContact] = useState(null);
  const [prospectDetails, setProspectDetails] = useState(null);
  const [prospectForm, setProspectForm] = useState(initValue());
  const [resetSearch, setResetSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const [annonceId, setAnnonceId] = useState("");
  const [detailsOffre, setDetailsOffre] = useState(null);
  const [lots, setLots] = useState([]);
  const [errors, setErrors] = useState([]);
  const [currentLot, setCurrentLot] = useState(null);
  const [listAnnonces, setListAnnonces] = useState([]);

  useEffect(() => {
    if (idContact !== null) {
      (async () => {
        const response = await fetch(
          `${appConfig.BACKEND_API_URL}/v1/secured/api/contacts/prospects/${idContact}`,
          {
            headers: {
              Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
            },
          }
        );
        const res = await response.json();
        if (res) {
          setLots(res.annonces || []);
          setProspectDetails(res || null);
        }
      })();
    }
  }, [
    authState.accessToken.accessToken,
    authState.accessToken.tokenType,
    idContact,
    appConfig.BACKEND_API_URL,
  ]);

  useEffect(() => {
    if (prospectDetails) {
      let nextState = [...prospectForm];
      for (const prospectProp in prospectDetails) {
        // eslint-disable-next-line array-callback-return
        nextState.find((el, index) => {
          if (el.id === prospectProp) {
            nextState[index].value = prospectDetails[prospectProp];
          }
        });
      }
      setProspectForm(nextState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospectDetails]);

  const handleClickNewProspect = () => {
    // Reset values
    setIdContact(null);
    setProspectDetails(null);
    setLots([]);
    setResetSearch(true);
    setProspectForm(initValue());
  };

  const handleClickFormProspect = () => {
    setErrors([]);
    let dataToSend = {};
    prospectForm.forEach(function (field) {
      if (field.value.length || field.id === "idContact")
        dataToSend[field.id] = field.value;
    });
    dataToSend.annonces = lots;

    let localErrors = [];

    prospectFields.forEach(function (field) {
      if (field.mandatory && !dataToSend[field.id]?.length) {
        localErrors.push(field.name);
      }
    });

    setErrors(localErrors);

    if (localErrors.length) {
      enqueueSnackbar(
        `Veuillez compléter les champs suivants: ${localErrors.map(
          (error) => `${error} `
        )}`,
        {
          variant: "warning",
        }
      );
    } else {
      (async () => {
        const response = await fetch(
          `${appConfig.BACKEND_API_URL}/v1/secured/api/contacts/prospects`,
          {
            method: "POST",
            headers: {
              Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(dataToSend),
          }
        );
        const res = await response;
        if (res) {
          if (res.status === 200) {
            enqueueSnackbar(
              `Les données du prospect ${idContact} ont bien été enregistrées`,
              {
                variant: "success",
              }
            );
          } else {
            enqueueSnackbar(
              "Une erreur est survenue lors de l'enregistrement",
              {
                variant: "error",
              }
            );
          }
        }
      })();
    }
  };

  const handleAddLotToProspect = () => {
    if (lots.includes(detailsOffre.codeAnnonce)) {
      enqueueSnackbar(
        `L'annonce ${detailsOffre.codeAnnonce} est déjà associée à la fiche prospect sélectionnée`,
        {
          variant: "warning",
        }
      );
      return;
    }
    let nextLots = [...lots];
    nextLots.push({
      codeAnnonce: detailsOffre.codeAnnonce,
      dateAppel: new Date().toISOString(),
      commAppel: "",
      noteFin: "",
    });
    setLots(nextLots);
    enqueueSnackbar(
      `L'annonce ${detailsOffre.codeAnnonce} a été associée à la fiche prospect sélectionnée`,
      {
        variant: "success",
      }
    );
  };

  const handleChange = (e, id) => {
    let nextState = [...prospectForm];
    // eslint-disable-next-line array-callback-return
    nextState.find((el, index) => {
      if (el.id === id) nextState[index].value = e.target.value;
    });
    setProspectForm(nextState);
  };

  const handleLotsChange = (e, id, index) => {
    let nextLots = [...lots];
    nextLots[index][id] = e.target.value;
    setLots(nextLots);
  };

  const handleNumberSearch = (_e, id = undefined) => {
    let url = `${appConfig.BACKEND_API_URL}/v1/public/api/offre/${annonceId}`;
    if (id) url = `${appConfig.BACKEND_API_URL}/v1/public/api/offre/${id}`;
    fetch(url)
      .then((res) => {
        if (res.status === 404 && id !== undefined) {
          throw new Error();
        }
        return res.json();
      })
      .then((res) => {
        setDetailsOffre(res);
      })
      .catch((err) => {
        enqueueSnackbar(
          "L'offre asssociée à ce contact n'est plus disponible",
          {
            variant: "error",
          }
        );
      });
  };

  return (
    <Grid container>
      <CustomDialog
        open={open}
        setOpen={setOpen}
        title="Suppression du lot de ce contact"
        message="Vous vous apprêtez à désassocier ce lot de ce contact, souhaitez-vous poursuivre ?"
        handleValidate={() => {
          let nextLots = [...lots];
          nextLots.splice(currentLot.index, 1);
          setLots(nextLots);
          setOpen(false);
        }}
      />
      <Grid item xs={12}>
        <Typography variant="h1">Centre d'appel</Typography>
        <CustomBreadcrumb breadcrumbPath={breadcrumbPath} />
        {detailsOffre && (
          <Button
            variant="outlined"
            startIcon={<KeyboardBackspaceIcon />}
            color="primary"
            className={classes.backButton}
            onClick={() => {
              // currentLot.log('')
              setDetailsOffre(null);
            }}
          >
            Retour aux résultats de recherche
          </Button>
        )}
        {listAnnonces.length && !detailsOffre ? (
          <Button
            variant="outlined"
            startIcon={<KeyboardBackspaceIcon />}
            color="primary"
            className={classes.backButton}
            onClick={() => setListAnnonces([])}
          >
            Retour au moteur de recherche
          </Button>
        ) : null}
        <Grid container>
          <Grid item xs={12} md={3} className={classes.leftPanel}>
            <Typography variant="h4">Recherche prospect</Typography>
            <FormControl component="fieldset" style={{ marginTop: 10 }}>
              <FormLabel component="legend">Rechercher par:</FormLabel>
              <RadioGroup
                row
                aria-label="search-by"
                value={typeOfSearch}
                onChange={(e) => setTypeOfSearch(e.target.value)}
              >
                {typeOfSearchValues.map((val) => (
                  <FormControlLabel
                    key={val.id}
                    value={val.id}
                    control={<Radio />}
                    label={val.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <CustomSelect
              url={`${appConfig.BACKEND_API_URL}/v1/secured/api/contacts/prospects?f=${typeOfSearch}`}
              id="prospect"
              handleAutoCompleteChange={(value) => setIdContact(value)}
              label="Nom, Prénom ou Téléphone"
              limitFetch={true}
              loadingText="Chargement, entrez au moins 3 caractères"
              resetSearch={resetSearch}
              setResetSearch={setResetSearch}
            />
            {prospectDetails && (
              <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={handleClickNewProspect}
                className={classes.buttonSearch}
                startIcon={<AddCircleOutlineIcon />}
              >
                Créer un nouveau prospect
              </Button>
            )}
            <Typography variant="h4">Fiche prospect {idContact}</Typography>
            {prospectFields.map((field) => {
              let valueToSend = prospectForm.find(
                (el) => el.id === field.id
              ).value;
              return (
                <div style={{ marginTop: 10, width: "100%" }} key={field.id}>
                  <ModifyField
                    error={errors.includes(field.name)}
                    label={field.name}
                    type={field.type}
                    value={valueToSend}
                    id={field.id}
                    localSelectContent={field.selectContent}
                    handleChange={handleChange}
                    mandatory={field.mandatory}
                    size="small"
                  />
                </div>
              );
            })}
            <Card style={{ width: "100%" }}>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Liste des lots du contact :
                </Typography>
                <List dense={true}>
                  {!lots.length && (
                    <Typography gutterBottom>
                      Aucun lot de sélectionné
                    </Typography>
                  )}
                  {lots.map((lot, index) => (
                    <ListItem key={lot.idAnnonce} style={{ flexFlow: "wrap" }}>
                      <ListItemAvatar style={{ cursor: "pointer" }}>
                        <Tooltip
                          title="Visionner ce lot"
                          onClick={(e) => {
                            handleNumberSearch(e, lot.codeAnnonce);
                          }}
                        >
                          <Avatar style={{ backgroundColor: "#5db991" }}>
                            <FindInPageIcon />
                          </Avatar>
                        </Tooltip>
                      </ListItemAvatar>
                      <ListItemText
                        style={{ maxWidth: 180 }}
                        primary={lot.codeAnnonce}
                        secondary={
                          lot.dateAppel &&
                          `Contacté le: ${new Date(
                            lot.dateAppel
                          ).toLocaleString("fr")}`
                        }
                      />

                      <ListItemSecondaryAction>
                        <Tooltip
                          title="Supprimer ce lot de ce contact"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setCurrentLot({ index: index, id: lot });
                            setOpen(true);
                          }}
                        >
                          <IconButton edge="end" aria-label="delete">
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                      {detailsLot.map((field) => {
                        return (
                          <div
                            style={{ marginTop: 10, width: "100%" }}
                            key={field.id}
                          >
                            <ModifyField
                              label={field.name}
                              type={field.type}
                              value={lot[field.id]}
                              id={field.id}
                              localSelectContent={field.selectContent}
                              handleChange={(e) =>
                                handleLotsChange(e, field.id, index)
                              }
                              mandatory={field.mandatory}
                              size="small"
                            />
                          </div>
                        );
                      })}
                      <Divider style={{ width: "100%", marginTop: 15 }} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={handleClickFormProspect}
              className={classes.buttonSearch}
              // disabled={isLocked}
              startIcon={<SaveIcon />}
            >
              Enregistrer
            </Button>
          </Grid>
          {/* Right panel */}
          <Grid item xs={12} md={9} className={classes.rightPanel}>
            {detailsOffre && (
              <>
                <Typography variant="h4" align="center">
                  Détails de l'annonce{" "}
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    onClick={handleAddLotToProspect}
                    className={classes.buttonSearch}
                    startIcon={<AddCircleOutlineIcon />}
                  >
                    Ajouter ce lot à la fiche prospect
                  </Button>
                </Typography>
                <DetailsContent
                  category="offreCallCenter"
                  content={detailsOffre}
                  modify={false}
                  loading={false}
                />
              </>
            )}
            {!detailsOffre && (
              <>
                <Typography variant="h4" align="center">
                  {listAnnonces.length
                    ? "Résultats de la recherche"
                    : "Moteur de recherche"}
                </Typography>
                {listAnnonces.length ? (
                  <CallCenterTable
                    handleNumberSearch={handleNumberSearch}
                    listAnnonces={listAnnonces}
                  />
                ) : (
                  <>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="h5">
                          A - Par numéro d'annonce
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <br />
                        <TextField
                          type="text"
                          label="N° d'annonce"
                          onChange={(e) => setAnnonceId(e.target.value)}
                          value={annonceId}
                          variant="outlined"
                          color="primary"
                        />
                        <Button
                          variant="outlined"
                          color="primary"
                          size="large"
                          onClick={handleNumberSearch}
                          startIcon={<SearchIcon />}
                          style={{ marginLeft: 20, height: 54 }}
                        >
                          Afficher l'annonce
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.rightB}>
                      <Grid item xs={12}>
                        <Typography variant="h5">
                          B - Par critère de recherche
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <SearchEngine
                          listAnnonces={listAnnonces}
                          setListAnnonces={setListAnnonces}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CallCenter;
