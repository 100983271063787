import PropTypes from "prop-types";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Canvas,
  Font,
} from "@react-pdf/renderer";
import lotStructure from "./lotStructure";
import robotoRegular from "./Roboto/Roboto-Regular.ttf";
import robotoBold from "./Roboto/Roboto-Bold.ttf";
import { interpretContent } from "../DetailsContent/utils";

const styles = StyleSheet.create({
  date: {
    position: "absolute",
    top: "5px",
    right: "30px",
    fontSize: "10px",
  },
  title: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    textAlign: "center",
    backgroundColor: "#5db991",
    marginTop: 30,
    marginLeft: 10,
    marginRight: 10,
    height: 40,
    paddingTop: 10,
    borderRadius: 10,
  },
  sectionContainer: {
    flexDirection: "row",
  },
  section: { margin: 12, flex: 6 },
  sectionTitle: {
    fontFamily: "Roboto",
    textDecoration: "underline",
    fontSize: 13,
    fontWeight: "bold",
    marginBottom: 10,
  },
});

Font.register({
  family: "Roboto",
  fonts: [{ src: robotoRegular }, { src: robotoBold, fontWeight: "bold" }],
});

const PdfGenerator = ({ content }) => {
  const etatAppart = () => {
    if (content.relouable) return "Relouable en l'état";
    else if (content.nettoyage) return "Nettoyage";
    else if (content.travaux) return "Travaux";
    else return "";
  };

  if (!content) return <></>;
  return (
    <Document
      title={`Détail du lot ${content?.codeImmeuble} - ${content?.codeLot}`}
    >
      {lotStructure.pages.map((page) => {
        return (
          <Page size="A4" key={`${page.toString()}`}>
            <View>
              <Text style={styles.date}>
                le {new Date().toLocaleDateString("fr")}
              </Text>
            </View>
            <View>
              <Text style={styles.title}>
                Référence du lot: {content?.codeImmeuble} - {content?.codeLot}
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {page.map((section) => {
                if (section.title === "break") {
                  return (
                    <Canvas
                      key={section.id}
                      paint={(painterObject) =>
                        painterObject
                          .save()
                          .lineWidth(20)
                          .moveTo(50, 10)
                          .lineTo(550, 10)
                          .fill("#5db991")
                      }
                    />
                  );
                }

                if (section.type === "table") {
                  return (
                    <View key={section.title}>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          margin: 5,
                          width: section.full ? "100%" : "45%",
                          marginTop: section.title.length ? 10 : 25,
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            borderBottom: "1px solid black",
                          }}
                        >
                          {section.content.map((column) => (
                            <View
                              key={column.id}
                              style={{
                                padding: 7,
                                fontSize: 9,
                                fontFamily: "Roboto",
                                fontWeight: "bold",
                                width: 85,
                                textAlign: "center",
                              }}
                            >
                              <Text>{column.name}</Text>
                            </View>
                          ))}
                        </View>
                      </View>
                      {content.residence.escaliers.map((acces) => {
                        return (
                          <View
                            key={`${acces.codeBatiment}${acces.codeEscalier}`}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              margin: "0 5",
                              width: section.full ? "100%" : "45%",
                              marginTop: 0,
                            }}
                          >
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              {section.content.map((column) => (
                                <View
                                  key={column.id}
                                  style={{
                                    padding: 7,
                                    fontSize: 10,
                                    fontFamily: "Roboto",
                                    width: 85,
                                    textAlign: "center",
                                  }}
                                >
                                  <Text>
                                    {interpretContent(
                                      acces[column.id],
                                      column.name,
                                      "pdf",
                                      false,
                                      column.type,
                                      column.suffixe,
                                      column.id
                                    )}
                                  </Text>
                                </View>
                              ))}
                            </View>
                          </View>
                        );
                      })}
                    </View>
                  );
                }

                return (
                  <View
                    key={
                      section.title.length
                        ? section.title
                        : section.content[0].id
                    }
                    style={{
                      margin: 14,
                      width: section.full ? "100%" : "45%",
                      marginTop: section.title.length ? 10 : 25,
                    }}
                  >
                    <View>
                      <Text style={styles.sectionTitle}>{section.title}</Text>
                    </View>
                    {section.content.map((structure) => {
                      return (
                        <View key={structure.id}>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              marginBottom: 4,
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 10,
                                fontFamily: "Roboto",
                                fontWeight: "bold",
                                color: "#5db991",
                              }}
                            >
                              {structure.name}
                            </Text>
                            <Text
                              style={{
                                fontSize: 10,
                                // Handle long text for comments & bus station name
                                marginLeft:
                                  (structure.id === "commentaireInterneLot" ||
                                    structure.id === "nomBus") &&
                                  (structure.sub
                                    ? content[structure.sub]?.[structure.id] !==
                                      undefined
                                    : content?.[structure.id] !== undefined) &&
                                  (structure.sub
                                    ? content[structure.sub]?.[structure.id]
                                        .length > 42
                                    : content?.[structure.id].length > 42)
                                    ? 40
                                    : "inherit",
                              }}
                            >
                              {structure.id === "etat" ? (
                                <>{etatAppart()}</>
                              ) : (
                                <>
                                  {interpretContent(
                                    structure.sub
                                      ? content[structure.sub]?.[structure.id]
                                      : content?.[structure.visualId] ||
                                          content?.[structure.id],
                                    // content?.[structure.id],
                                    structure.name,
                                    "lots",
                                    false,
                                    structure.type,
                                    structure.suffixe,
                                    structure.id
                                  )}
                                </>
                              )}
                            </Text>
                          </View>
                        </View>
                      );
                    })}
                  </View>
                );
              })}
            </View>
          </Page>
        );
      })}
    </Document>
  );
};

PdfGenerator.propTypes = {
  content: PropTypes.object.isRequired,
};

export default PdfGenerator;
