import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import useStyles from "./style";
import PropTypes from "prop-types";

const CustomBreadcrumb = ({ breadcrumbPath }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className={classes.breadcrumbs}
        classes={{ separator: classes.separator }}
      >
        {breadcrumbPath.path.map((el) => (
          <Link
            key={el.name}
            href={el.href}
            // onClick={handleClick}
          >
            {el.name}
          </Link>
        ))}
        <Typography variant="subtitle2">
          {breadcrumbPath.currentElement}
        </Typography>
      </Breadcrumbs>
    </div>
  );
};

CustomBreadcrumb.propTypes = {
  breadcrumbPath: PropTypes.shape({
    currentElement: PropTypes.string.isRequired,
    path: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};

export default CustomBreadcrumb;
