import React, { useCallback, useContext, useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import { Button, Chip, Typography } from "@material-ui/core";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import CustomTable from "../../Components/CustomTable";
import Search from "@material-ui/icons/Search";
import Edit from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import AddElementDialog from "../../Components/AddElementDialog.js";
import AddIcon from "@material-ui/icons/Add";
import debounce from "lodash.debounce";
import { useOktaAuth } from "@okta/okta-react";
import PropTypes from "prop-types";
import { AppConfigContext } from "../../context";
import TabIcon from "@material-ui/icons/Tab";

const Lots = ({ match }) => {
  const appConfig = useContext(AppConfigContext);
  const { authState } = useOktaAuth();
  const history = useHistory();
  const isAvailable = match.params.available === "dispos";
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState(null);
  const [isDesc, setIsDesc] = useState(false);
  const [open, setOpen] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const breadcrumbPath = {
    path: [{ name: "Accueil", href: "/" }],
    currentElement: `Lots ${isAvailable ? "disponibles" : "loués"}`,
  };

  useEffect(() => {
    setSearchTerm("");
  }, [breadcrumbPath.currentElement, isAvailable]);

  const formatData = (result) => {
    if (!result.content) return [];
    return result.content.map((lot) => {
      let output = { ...lot };

      output.surfaceUtile = lot.surfaceUtile + "m²";
      output.loyerCc = lot.loyerCc + " €";
      output.dateReceptionConge = lot.dateReceptionConge
        ? new Date(lot.dateReceptionConge).toLocaleDateString("fr-FR")
        : "";
      output.dateDispo = lot.dateDispo
        ? new Date(lot.dateDispo).toLocaleDateString("fr-FR")
        : "";
      output.topOption = lot.topOption ? (
        <Chip label="Oui" variant="outlined" color="primary" />
      ) : (
        <Chip
          label="Non"
          variant="outlined"
          style={{ color: "red", borderColor: "red" }}
        />
      );
      output.topAgence = lot.topAgence ? (
        <Chip label="Oui" variant="outlined" color="primary" />
      ) : (
        <Chip
          label="Non"
          variant="outlined"
          style={{ color: "red", borderColor: "red" }}
        />
      );
      output.topLoue = lot.topLoue ? (
        <Chip label="Oui" variant="outlined" color="primary" />
      ) : (
        <Chip
          label="Non"
          variant="outlined"
          style={{ color: "red", borderColor: "red" }}
        />
      );
      output.publicationSite = lot.publicationSite ? (
        <Chip label="Oui" variant="outlined" color="primary" />
      ) : (
        <Chip
          label="Non"
          variant="outlined"
          style={{ color: "red", borderColor: "red" }}
        />
      );

      return output;
    });
  };

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        `${appConfig.BACKEND_API_URL}/v1/secured/api/lots/${
          isAvailable ? "dispo" : "loue"
        }`,
        {
          headers: {
            Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => res);
      let formattedData = formatData(response);

      setTotalElements(response.totalElements);

      setData(formattedData);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAvailable]);

  useEffect(() => {
    fetchWithParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, searchTerm, sortBy, isDesc]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchWithParams = useCallback(
    debounce(async () => {
      const response = await fetch(
        `${appConfig.BACKEND_API_URL}/v1/secured/api/lots/${
          isAvailable ? "dispo" : "loue"
        }?page=${page}&size=${size}${
          searchTerm.length ? "&t=" + searchTerm : ""
        }${isDesc === true ? "&isDesc=true" : "&isDesc=false"}${
          sortBy ? "&sortBy=" + sortBy : ""
        }`,
        {
          headers: {
            Authorization: `${authState.accessToken.tokenType} ${authState.accessToken.accessToken}`,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => res);
      let formattedData = formatData(response);

      setTotalElements(response.totalElements);
      setData(formattedData);
    }, 500),
    [searchTerm, isDesc, sortBy, size, page]
  );

  const columns = [
    {
      title: "Code immeuble",
      field: "codeImmeuble", // field is the "key" in the data
      width: "8%",
      filtering: false,
      customFilterAndSearch: (value, rowData) => {
        return true;
      },
    },
    {
      title: "Code lot",
      field: "codeLot",
      filtering: false,
      customFilterAndSearch: (value, rowData) => {
        return true;
      },
    },
    {
      title: "Ville",
      field: "ville",
      filtering: false,
      customFilterAndSearch: (value, rowData) => {
        return true;
      },
    },
    {
      title: "Typologie",
      field: "typeBien",
      filtering: false,
      customFilterAndSearch: (value, rowData) => {
        return true;
      },
    },
    {
      title: "Surface",
      field: "surfaceUtile",
      filtering: false,
      customFilterAndSearch: (value, rowData) => {
        return true;
      },
    },
    {
      title: "Date congé",
      field: "dateReceptionConge",
      filtering: false,
      customFilterAndSearch: (value, rowData) => {
        return true;
      },
    },
    {
      title: "Date dispo",
      field: "dateDispo",
      filtering: false,
      customFilterAndSearch: (value, rowData) => {
        return true;
      },
    },
    {
      title: "Nom ancien locataire",
      field: "nomAncienLocataire",
      filtering: false,
      customFilterAndSearch: (value, rowData) => {
        return true;
      },
    },
    {
      title: "Loyer C.C.",
      field: "loyerCc",
      filtering: false,
      align: "right",
      customFilterAndSearch: (value, rowData) => {
        return true;
      },
    },
    {
      title: "Publication site",
      field: "publicationSite",
      filtering: true,
      customFilterAndSearch: (value, rowData) => {
        return true;
      },
    },
    {
      title: "Option",
      field: "topOption",
      filtering: false,
      customFilterAndSearch: (value, rowData) => {
        return true;
      },
    },
    {
      title: "Agence",
      field: "topAgence",
      filtering: false,
      customFilterAndSearch: (value, rowData) => {
        return true;
      },
    },
    {
      title: "Négociateur",
      field: "negociateur",
      filtering: true,
      customFilterAndSearch: (value, rowData) => {
        return true;
      },
    },
    {
      title: "Attaché commercial",
      field: "attacheCommercial",
      filtering: false,
      customFilterAndSearch: (value, rowData) => {
        return true;
      },
    },
  ];

  const actions = [
    {
      icon: Edit,
      tooltip: "Modifier le lot",
      onClick: (_event, rowData) =>
        history.push(
          `/details/lots/${rowData.codeImmeuble}-${rowData.codeLot}/true`
        ),
    },
    {
      icon: Search,
      tooltip: "Voir le lot",
      onClick: (_event, rowData) =>
        history.push(
          `/details/lots/${rowData.codeImmeuble}-${rowData.codeLot}`
        ),
    },
    {
      icon: TabIcon,
      tooltip: "Ouvrir le lot dans un nouvel onglet",
      onClick: (_event, rowData) =>
        window.open(
          `/details/lots/${rowData.codeImmeuble}-${rowData.codeLot}`,
          "_blank"
        ),
    },
  ];

  return (
    <Grid container>
      <AddElementDialog open={open} setOpen={setOpen} category="lots" />
      <Grid item xs={12}>
        <Typography variant="h1">
          Lots {isAvailable ? "disponibles" : "loués"}
        </Typography>
        <Grid container justifyContent="space-between">
          <CustomBreadcrumb breadcrumbPath={breadcrumbPath} />
          <Button
            style={{ margin: 5 }}
            variant="outlined"
            size="small"
            endIcon={<AddIcon />}
            onClick={() => setOpen(true)}
            color="primary"
          >
            Ajouter un lot
          </Button>
        </Grid>
        <CustomTable
          title={`Liste des lots ${isAvailable ? "disponibles" : "loués"}`}
          columns={columns}
          data={data}
          actions={actions}
          size={size}
          setSize={setSize}
          page={page}
          setPage={setPage}
          totalElements={totalElements}
          // noSearch={true}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setSortBy={setSortBy}
          setIsDesc={setIsDesc}
          filter={false}
          autoFocus={true}
          actionLeft={true}
        />
      </Grid>
    </Grid>
  );
};

Lots.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      available: PropTypes.string.isRequired,
    }),
  }),
};

export default Lots;
